.drug_stock_supply_manage_wapper {
  .search_header_check_view {
    margin-left: 9px;

    .ant-checkbox-wrapper {
      >span:nth-of-type(2) {
        text-indent: 0;
      }
    }
  }

  .vs-base-table-container {
    height: calc(100% - 55px);


    .ant-select-selection-overflow {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 24px;

    >button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
}

.not_supply_or_limit_block {

  .warn_info {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    >span {
      font-size: 16px;
      font-weight: 400;
      color: #343B42;
      margin-left: 8px;
      transform: translateY(-1px);
    }
  }
}
@root-entry-name: default;