.confim-medicine-layout {
  width: 100%;
  display: flex;
  .ant-table-wrapper {
    .aspirin-table-row-background-box {
      color: rgba(0, 0, 0, 1);
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      text-align: center; // 将单元格内容水平居中
    }
  }
  .ant-table-cell::before {
    content: none !important;
  }
  .left {
    // flex: 1;
    width: 494px;
    flex: none;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 16px;
    padding: 24px;
    .ant-space-align-center {
      align-items: flex-start !important;
    }
    .list-cell {
      flex: 1;
      width: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px;
      margin-right: 10px;

      font-size: 16px;
      font-weight: 400;
      color: #343b42;
      // padding-left: 5px;
      // padding-right: 5px;
    }
    .left-query {
      // padding: 24px;
      display: flex;
      align-items: flex-start;
    }
    .select {
      background-color: #cbe4ff;
      transition: background-color 0.2s ease;
    }
    .un-select {
      transition: background-color 0.2s ease;
    }
    .ant-form-item-label {
      flex-grow: 0;
      width: 80px;
      text-align: left;
    }
  }
  .right {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    // align-items: center;
    .top {
      height: 136px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      .ant-col {
        font-size: 14px;
        // font-weight: 200;
        font-weight: 500;
        color: #343b42;
      }
      .font-line {
        display: inline;
        font-size: 14px !important;
        font-weight: 600;
        color: #343b42;
        margin-left: 10px;
      }
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
    }
    .bottom {
      flex: 1;
      background-color: rgba(255, 255, 255, 0.5);
      margin-top: 30px;
    }
    .center-dec {
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 100;
      padding-left: 24px;
    }
    .center {
      // margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      background-color: rgba(255, 255, 255, 0.5);
      padding-left: 24px;
      border-top: solid 1px #3276e8;
    }
  }
}
.ant-modal-dispense-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #3276e8 !important;
}
.modal-dispense-font {
  font-size: 16px;
  font-weight: 400;
  color: #343b42;
}
.modal-dispense-font-content {
  font-size: 16px;
  font-weight: 500;
  color: #343b42;
}
.total-table-prescription {
  height: 40px;
  text-align: center;
  padding: 12px;
}
.total-chager-chufang {
  font-size: 18px;
  font-weight: 500;
  color: #343b42;
}
.belt-list-lable-classname {
  flex: 1;
  font-weight: bolder;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #343b42;
  margin-left: 10px;
  margin-right: 10px;
}
.belt-list-lable-row-classname {
  height: 44px;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}
.row_font_size {
  .ant-table-cell {
    // font-size: 14px !important;
    height: 44px;
  }
  .ant-table-thead {
    font-size: 14px !important;
    height: 48px;
  }
}

@root-entry-name: default;