.patient_main_search_wapper {
  height: 100%;

  .merge_row {
    background-color: rgba(0, 0, 0, 0.04);
    color: #85898d;
  }

  .ant-pro-table-search {
    box-sizing: border-box;
    padding: 24px !important;
    margin-bottom: 16px !important;

    border-radius: 4px 4px 4px 4px;

    > form {
      > .ant-row-start {
        position: relative;
        display: flex;
        justify-content: flex-start;

        .ant-form-item-no-colon {
          width: 0 !important;
        }

        > .ant-col {
          margin-bottom: 16px;
        }

        > .ant-col:nth-of-type(1) {
          max-width: none !important;
        }
      }
    }
  }

  .ant-table-wrapper {
    padding-top: 24px;
  }

  .suffix-readCard {
    height: 20px;
    display: flex;
    align-items: center;
    color: #3276e8;
    cursor: pointer;
  }

  .suffix-cardText {
    margin-top: 2px;
    padding-left: 5px;
  }
}

.patient_search_modal_header {
  display: flex;
  align-items: center;

  > span:nth-of-type(1) {
    font-size: 20px;
    font-weight: bold;
    color: #3276e8;
  }

  > span:nth-of-type(2) {
    font-size: 20px;
    font-weight: bold;
    color: #343b42;
  }

  .people {
    color: #3276e8;
    margin-left: 4px;
  }
}

.patient_search_modal_header_footer {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  border-top: 1px solid #d6d8d9;
  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .normal {
    cursor: pointer;
    height: 32px;
    border-radius: 0px 0px 0px 0px;
    padding: 0 24px;
    font-size: 16px;
    color: #3276e8;
    margin-right: 16px;
    border: 1px solid #3276e8;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .primary {
    background: #3276e8;
    color: #fff;
  }
}
.patient_main_menu {
  width: 200px;
  border-right: 1px solid #d6d8d9;

  .info {
    width: 100%;
    height: 216px;
    box-sizing: border-box;
    padding: 24px 24px 0 24px;

    > div:nth-of-type(1) {
      width: 152px;
      height: 152px;
      background: #cddfff;
    }

    > div:nth-of-type(2) {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        font-size: 14px;
        font-weight: 400;
        color: #5d6268;
        flex: none;
      }

      > p {
        display: inline-block;
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: #343b42;
        margin: 0 !important;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .people {
        color: #3276e8;
      }
    }
  }

  .menu {
    width: 100%;

    .item {
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      padding: 0 24px;
      transition: all 0.2s linear;

      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
    }

    .item-normal {
      // background: #fff;
      color: #5d6268;
    }

    .item-active {
      background: #ebf2ff;
      color: #3276e8;
    }
  }
}
.patient_main_search_table {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  // padding: 24px;
  height: 100%;
}

@root-entry-name: default;