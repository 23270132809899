.aspirin-order-selected-box{
  display: flex;
  justify-content: space-between;
  .aspirin-order-selected-left-box{
    display: flex;
  }
}

.aspirin-table-operation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .anticon {
    + .anticon {
      margin-left: 4px;
    }
  }
}

.aspirin-order-module-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;

  .left-in {
    .auxiliary-type-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label {
        margin-right: 12px;
      }
    }
  }
}

.aspirin-order-module-footer {
  margin-top: 24px;
}

@root-entry-name: default;