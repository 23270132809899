.InpMedicalTechnologyCharge-container {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .vs-base-table-container {
      height: calc(100% - 82px) !important;
    }
    .UserInfo,
    .TableMenu {
      width: 100%;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.3);
    }
    .UserInfo {
      height: 14%;
      padding: 12px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .Descriptions {
        .ant-descriptions-item {
          font-size: 24px !important;
          .ant-descriptions-item-label {
            text-align: left;
            color: rgba(93, 98, 104, 1);
          }
        }
      }
    }
    .TableMenu {
      height: 84%;
      .ant-menu {
        background: rgba(255, 255, 255, 0.3);
      }
      .Components {
        width: 100%;
        height: calc(100% - 45px);
        padding: 12px 24px 0;
        .contianer-table {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title-container {
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            .btn {
              display: inline-block;
            }
          }
          .title {
            display: inline-block;
            margin: 0 !important;
            font-weight: 500;
            font-size: 16px;
            position: relative;
            left: 6px;
            padding-left: 6px;
            &::before {
              position: absolute;
              top: 50%;
              left: -6px;
              transform: translateY(-50%);
              content: '';
              width: 6px;
              height: 100%;
              background: rgba(50, 118, 232, 1);
              display: inline-block;
            }
          }
          .Message,
          .Project,
          .Mistake {
            width: 100%;
            overflow: hidden;
          }
          .Message {
            height: calc(46% - 8px);
          }
          .Project {
            height: 54%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left,
            .right {
              height: 100%;
            }
            .left {
              width: calc(30% - 8px);
              border-right: 1px #ccc solid;
              padding-right: 8px;
            }
            .right {
              width: 70%;
            }
          }
          .Mistake {
            height: 100%;
            display: flex;
            flex-direction: column;
            .MistakeTable {
              width: 100%;
              height: calc(100% - 46px);
              display: flex;
              flex-direction: row;
              overflow: hidden;
              .left,
              .right {
                height: 100%;
              }
              .left {
                width: 18%;
                padding-right: 8px;
              }
              .right {
                width: 82%;
                padding-left: 8px;
              }
            }

            .MistakeButton {
              width: 100%;
              border-top: 1px #aaa solid;
              padding: 7px 0;
            }
          }
        }
      }
    }
  }
}

@root-entry-name: default;