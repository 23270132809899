.firm_container {
  width: 100%;
  height: 100%;
  .table,
  .footer {
    width: 100%;
  }
  .table {
    height: 96%;
  }
}

@root-entry-name: default;