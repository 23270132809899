.header_info {
  padding: 0 24px;
  height: 70px;
  width: 100%;
  // border-bottom: 1px solid #3276e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  &_left {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    &_name {
      margin-right: 4px;
      font-size: 26px;
      font-weight: 600;
      color: #343b42;
    }
    &_sex {
      margin-top: 5px;
      margin-right: 40px;
    }
    &_info {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #343b42;
      &_item {
        display: flex;
        align-items: center;
        font-size: 16px;
        &_text {
          // border: 1px solid red;
          max-width: 150px;
          cursor: pointer;
        }
      }
      &_splice {
        margin: 2px 16px 0;
        width: 1px;
        height: 16px;
        background-color: #aeb0b3;
      }
    }
  }
  &_right {
    flex-shrink: 0;
    // margin-left: 16px;
    display: flex;
    align-items: center;
    &_danger {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #5d6268;
      font-size: 14px;
    }
    &_drg {
      margin-left: 24px;
      width: 54px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #3276e8;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      color: #fff;
    }
  }
}

@root-entry-name: default;