.permission_maintenance {
  display: flex;
  height: 100%;

  .label {
    .content {
      height: 100%;
      width: 300px;
      display: flex;
      flex-direction: column;

      .tree {
        flex: 1;
        overflow-y: scroll;
      }

      .tip {
        height: 48px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .icon {
    height: 100%;
    width: 140px;
    margin: 0 20px 0;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .permission {
    height: 100%;
    flex: 1;

    .ant-select-selector {
      padding: 5px 5px 0;

      .ant-select-selection-overflow-item {
        margin-bottom: 5px !important;
      }
    }
  }
}

@root-entry-name: default;