.custom_selectlist {
  width: 100%;
  display: flex;
  margin-top: -6px;
  flex-wrap: wrap;
  word-break: break-all;
  min-height: 44px;
  &_item {
    min-height: 44px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 22px;
    &_label {
      height: 100%;
      margin-right: 10px;
      width: 150px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

@root-entry-name: default;