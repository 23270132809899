.outpatient-fee-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  justify-content: space-between;

  .top {
    // background-color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    padding: 24px;
    padding-bottom: 0;
    display: flex;

    .top-first-form {
      flex: 1;
    }

    .top-first-info {
      padding-left: 24px;
      flex: 5;

      .top6 {
        margin-top: 6px;
        font-size: 18px;
      }

      .top30 {
        margin-top: 36px;
        font-size: 18px;
      }
    }
  }

  .bootom {
    flex: 1;
    display: flex;
    height: 100%;

    .left {
      // background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      margin-right: 16px;
      padding: 24px;
      width: 600px;

      .ant-col-8 {
        padding: 0 !important;
      }

      .left-order {
        display: flex;
        // justify-content: space-between;
      }

      .text-before-weight {
        width: 100px;
        font-size: 20px;
        font-weight: 500;
        color: #343b42;
        height: 30px;
        position: relative;

        &::before {
          position: absolute;
          width: 5px;
          height: 20px;
          background: #3276e8;
          content: '';
          transform: translate(-50%, -50%);
          top: 50%;
          left: -12px;
        }
      }

      .list-layout {
        flex: 1;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: #343b42;
      }

      .select-group-action {
        background-color: rgba(203, 228, 255, 1);
      }

      .ant-table-row:hover {
        background-color: rgba(203, 228, 255, 1);
      }

      .ant-table-cell-row-hover {
        background-color: rgba(203, 228, 255, 1);
      }
    }

    .right {
      background-color: rgba(255, 255, 255, 0.5);
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 24px;
      padding-bottom: 0;
      overflow: auto;

      .refunds-content-title {
        height: 152px;
        padding: 24px;

        .refunds-tags {
          margin-top: 24px;
        }
      }

      .refunds-content {
        flex: 1;
        padding: 24px;
      }

      .refunds-btn {
        height: 60px;
        // padding-right: 24px;
        // display: flex;
        // align-items: center;
        padding-bottom: 0;
        width: 100%;
      }
    }
  }

  .nurse-billing-title {
    display: flex;
    justify-content: start;

    .text-before-content {
      padding-left: 24px;
      width: 700px;
    }
  }
}

.modal-fee-revivw {
  font-size: 20px;
  font-weight: 500;
  color: #3276e8;
}

.modal-fee-revivw-memo {
  font-size: 14px;
  font-weight: 400;
  color: #85898d;
}

.modal-stop-charge-div {
  height: 282px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 0px 2px rgba(64, 158, 255, 0);
  border-radius: 2px;
  border: 1px solid #e7e7e7;
  margin-bottom: 24px;
  padding: 24px;
}

.ant-table-row-day-order-selected {
  td.ant-table-cell {
    border: 1px solid #d6d8d9;
    border-top: none;
    border-right: none;

    background-color: pink;
  }
}

.modal-fee-revivw-detail {
  color: #3276e8;
}

.row-text-color {
  color: #b8b5b5;
}

.aspirin-order-text1 {
  height: 100%;
  width: 100%;
  padding: 0 18px;
  // display: flex !important;
  // align-items: center;
}

@root-entry-name: default;