.self-payment-indicator{
    display: flex;
    margin-left: 100px;
    
    .sub{
        display: flex;
        margin-right: 10px;
        // margin-left: 40px;
        .title{
            margin-top: 5px;
            margin-left: 6px;
        }

    }
}
@root-entry-name: default;