@import '~@/style/vars.less';

.@{prefix}-cpoe-center-rightContainer {
  width: 452px;
  min-width: 452px;
  max-width: 452px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 16px;
  // margin-top: 79px;
  overflow-y: auto;
  box-sizing: border-box;
  // padding: @padding;
  padding: 40px 0px 0;
  // background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  .timeline {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    height: 100%;
    .aspirin-block-main {
      width: 100%;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    .aspirin-timeline {
      flex: 1;
      overflow-y: auto;
      padding-right: 12px;
      width: 100%;
    }

    .timeline-type {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin: 12px 0;
      margin-bottom: 12px;
      flex: 0;

      .item {
        padding: 0 24px;
        color: #5d6268;
        font-size: 14px;
        background-color: #fff;
        height: 26px;
        line-height: 26px;
        cursor: pointer;
        border: 1px solid transparent;

        + .item {
          margin-left: 14px;
        }
      }

      .active-item {
        background-color: #e6f7ff;
        color: #69a1ff;
        border-radius: 2px;
        border: 1px solid rgba(105, 161, 255, 0.5);
      }
    }

    .toggle {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label {
        margin-right: 6px;
      }
    }

    .timeline-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: @white;
      padding: @padding;
      border-radius: @border-radius;
      border: 2px solid transparent;
      margin-bottom: 12px;
      cursor: pointer;
      > div {
        width: 100%;
      }

      .first {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-weight: @font-weight-bold;
          color: @title-color;
          font-size: @font-size-18;

          .line {
            height: 1px;
            width: 24px;
            margin: 0 4px;
            background-color: @primary-color;
          }
        }

        .status {
          > span {
            margin-right: 0;
          }
        }
      }

      .second {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: @margin;
        font-size: 14px;
        .left {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          .icon-container {
            position: relative;
            width: 40px;
            height: 40px;
            border: 1px solid @primary-color;
            border-radius: @border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @primary-color;
            margin-right: @margin-3;
            margin-top: @margin-1;
            .danger {
              position: absolute;
              top: -12px;
              left: -12px;
              text-align: center;
              line-height: 22px;
              width: 22px;
              height: 22px;
              border-radius: 22px;
              background-color: red;
              color: #fff;
              font-size: 12px;
            }
          }

          .content {
            flex: 1;
            > div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              + div {
                margin-top: @margin-1;
              }
            }

            .label {
              color: @grey-1;
              font-size: @font-size-14;
              margin-right: @margin-3;
              flex-wrap: nowrap;
              white-space: nowrap;
              &::after {
                content: ':';
              }
            }
            .right {
              flex: 1;
              display: flex;
              flex-direction: column;
              text-align: left;
              // border: 1px solid red;

              .value {
                flex: 1;
                white-space: nowrap; //不支持换行
                overflow: hidden; //隐藏多出部分文字
                text-overflow: ellipsis; //用省略号代替多出部分文字
                color: @title-color;
                font-size: @font-size-14;
                font-weight: @font-weight-bold;
              }
              .show-value {
                color: @title-color;
                font-size: @font-size-14;
                font-weight: @font-weight-bold;
              }
            }

            .abnormal {
              font-weight: @font-weight-normal;
            }
          }
        }
        .center {
          flex-shrink: 0;
          margin-left: 5px;
          display: inline-block;
          align-self: self-start;
        }

        .right {
          margin-left: 10px;
          flex-shrink: 0;
          width: 70px;
          text-align: center;
          align-self: self-start;
          font-size: @font-size-14;
          font-weight: @font-weight-bold;
        }
      }
    }

    .active-item {
      .timeline-item {
        border-color: @primary-color;
      }
    }
  }

  .people-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    background-color: #062365;
    border-radius: 4px;
    .people-image {
      background-size: cover;
      width: 560px;
      height: 1030px;
    }
  }
}

@media screen and (max-width: 2083px)  {
  .@{prefix}-cpoe-center-rightContainer{
    width: 370px !important;
    min-width: 370px !important;
    max-width: 370px !important;
  }
}

.click-timeline-active-item {
  border: 2px solid #3276e8;
}
.timeline-item-popover {
  .ant-popover-title {
    font-size: 18px;
    color: #343b42;
    padding: 14px 0 14px 24px;
    background-color: #f6f9fb;
  }

  .ant-popover-inner-content {
    padding: 0;
    background-color: #f6f9fb;
    overflow: hidden;
    padding-bottom: 15px;
  }
}

.timeline-detail {
  width: 880px;
  height: 600px;
  border-radius: 8px;
  background: #f6f9fb;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .info {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    padding: 16px 24px;
    border-bottom: 1px solid #d6d8d9;
    min-height: 80px;
    width: 100%;
    .item {
      width: 33%;
      flex-shrink: 0;
      .label {
        font-size: 12px;
        color: #85898d;
        display: inline-block;
        width: 60px;
        text-align: left;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 12px;
        color: #5d6268;
      }
    }
  }
  .content {
    // height: 0;
    // flex: 1;
    // overflow-y: scroll;
    .flow_chart {
      // max-height: 300px;
      // border-bottom: 1px solid #d6d8d9;
      padding: 24px 36px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 25%;
        height: 105px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        // box-sizing: border-box;
        .img {
          width: 120px;
          height: 105px;
          background-image: url('~@/assets/flow_chart_img.png');
          background-size: cover;
          background-repeat: no-repeat;
          box-shadow: 4px 4px 11px 0px rgba(0, 87, 189, 0.19);
          text-align: center;
          .title {
            margin-top: 40px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3276e8;
          }
          .time,
          .name {
            font-size: 12px;
            color: #5d6268;
          }
          .name {
          }
        }
        .arrows {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          // border: 1px solid black;
        }
      }
    }
    .items {
      padding: 16px 24px;
    }
    .herbcontent {
      border-top: 1px solid #d6d8d9;
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #343b42;
        padding: 14px 24px 0;
        display: flex;
        align-items: center;
        .number {
          font-size: 14px;
          font-weight: 400;
          color: #85898d;
        }
      }
      .list {
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          width: 400px;
          height: 36px;
          margin-bottom: 12px;
          border: 1px solid #a47c4d;
          background-color: #fff;
          font-size: 14px;
          color: #343b42;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .active-item {
          background: #ffecd7;
        }
      }
    }
  }

  th.ant-table-cell {
    background-color: #e4eeff;
  }
  //西药/中成药
  // .westernAndChinese .item:last-child {
  //   width: 66%;
  // }
}

.timeline-tendency {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .first {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .label {
      margin-right: 20px;
    }

    .ant-btn {
      margin: 0 12px;
    }
  }

  .main {
    width: 646px;
    height: 288px;
    border-radius: 4px;
    background-color: #fff;
    margin-top: 24px;
  }

  .cpoe-check-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .item {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 14px;
      font-size: @font-size-14;
      color: @grey-1;
      border: 1px solid @grey-1;
      cursor: pointer;
      border-radius: @border-radius;
      background-color: @white;
      user-select: none;

      + .item {
        margin-left: 6px;
      }
    }

    .check-item {
      border-color: @primary-color;
      color: @primary-color;
    }
  }
}

.timeline-detail-modal {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #f6f9fb;
  }
}
.timeline-detail_checked_detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 24px 0;
  box-sizing: border-box;
  // overflow-y: scroll;
  &_title {
    font-size: 24px;
    font-weight: 600;
    color: #343b42;
  }
  &_doctor_name {
    font-size: 16px;
    font-weight: 400;
    color: #5d6268;
    margin: 24px 0 20px;
  }
  &_content {
    display: flex;
    margin-bottom: 24px;
    box-sizing: border-box;
    &_label {
      flex-shrink: 0;
      width: 140px;
      font-size: 16px;
      font-weight: 600;
      color: #343b42;
    }
    &_content {
      font-size: 14px;
      font-weight: 400;
      color: #5d6268;
    }
  }
  &_view_img {
    width: 80px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #3276e8;
    font-size: 14px;
    font-weight: 400;
    color: #3276e8;
    text-align: center;
    line-height: 18px;
    margin-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
  &_double_up_outlined {
    transform: rotate(-90deg);
  }
  &_double_down_outlined {
    transform: rotate(90deg);
  }
}

@root-entry-name: default;