.drawer-close-box .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-drawer-content {
  display: flex;

  .func {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
  }

  [class^='drawer-button'] {
    background: linear-gradient(90deg, #014d7e 0%, #0071bd 100%);
    border-radius: 0 16px 16px 0;
    padding: 8px 5px;
    right: -30px;
    box-sizing: content-box;
    display: flex;
    position: absolute;
    color: white;
    cursor: pointer;
    display: flex;
    text-align: center;
    z-index: 500;

    > span {
      font-weight: 500;
    }
  }

  .drawer-button-left {
    height: auto;
    top: 25%;
    width: 20px;
    right: -30px;
    font-size: 18px;
  }

  .drawer-button-right {
    height: auto;
    top: 25%;
    width: 30px;
    left: -30px;
    top: 20%;
  }

  .drawer-button-top {
    width: auto;
    height: 30px;
    left: 50%;
    bottom: -30px;
    align-items: center;
  }

  .drawer-button-bottom {
    width: auto;
    height: 20px;
    left: 50%;
    top: -30px;
    align-items: center;
  }

  .aspirin-sidebar-button-box {
    background-color: var(--primary-color-2);
  }
}

.drawer-mask-close-box {
  background: none;
  transition: all 0.2s;
}

.drawer-mask-open-box {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
  transition: all 0.2s;

  > div {
    z-index: 1001;
  }
}

@root-entry-name: default;