.special_charge_list_scheme {
  width: 100%;
  width: 100%;
  display: flex;
  flex: 1;

  border-radius: 4px;
  &_left {
    padding: 20px 24px 24px;
    width: 500px;
    margin-right: 16px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    flex-shrink: 0;
  }
  &_right {
    padding: 20px 24px 8px;
    width: 100%;
    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &_bottom {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    Button {
      margin-left: 16px;
    }
  }
}

@root-entry-name: default;