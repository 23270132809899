@import '~@/style/vars.less';

.sider-menu-more {
  .ant-popover-inner-content {
    padding: 5px 9px;
  }
  .show-more-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 328px;
    min-width: 328px;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    color: #343b42;
    font-size: @font-size-14;
    margin-top: 12px;
    cursor: pointer;
    // padding: 0 @padding-3;
    width: 68px;
    height: 64px;
    min-height: 64px;
    position: relative;
    margin: 7px;

    &:hover {
      background: #ebf2ff;

      .remove {
        display: block;
      }
    }

    > span {
      color: @primary-color;
      display: flex;
      justify-content: center;
      align-items: center;

      + span {
        white-space: nowrap;
        font-size: @font-size-18;
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }

    .label {
      display: inline-block;
      width: 100%;
      transition: all 0.2s ease-in-out;
      opacity: 1;
      color: @grey-1;
      font-size: 18px;
      margin-top: 4px;
      word-break: break-all;
      white-space: normal;
      // line-height: 14px;
      text-align: center;
    }

    .remove {
      position: absolute;
      top: -2px;
      right: 5px;
      cursor: pointer;
      display: none;
    }
  }

  .active-item {
    background: #ebf2ff;
  }
}

.sider-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
    height: 8px;
  }

  .show-list {
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    color: #343b42;
    font-size: @font-size-14;
    margin-top: 12px;
    cursor: pointer;
    // padding: 0 @padding-3;
    width: 100%;
    height: 5rem;
    min-height: 5rem;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      color: #fff;
      background: linear-gradient(270deg, #0e203a7f 0%, #19baff7f 100%);

      span {
        color: @white !important;
      }

      .remove {
        display: block;
      }
    }

    > span {
      color: #86898d;
      display: flex;
      justify-content: center;
      align-items: center;

      + span {
        margin-left: 9px;
        margin-top: 0;
        white-space: nowrap;
        font-size: @font-size-18;
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }

    .label {
      // display: inline-block;
      // height: 2.85rem;
      // width: 4rem;
      width: 100%;
      height: calc(100% - 1.55rem);
      transition: all 0.2s ease-in-out;
      opacity: 1;
      color: @grey-1;
      font-size: 18px;
      margin-top: 4px;
      word-break: break-all;
      white-space: normal;
      // line-height: 14px;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      // white-space: nowrap;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .remove {
      position: absolute;
      top: -2px;
      right: 5px;
      cursor: pointer;
      display: none;
    }
  }

  .active-item {
    color: #fff;
    background: linear-gradient(270deg, #0e203a7f 0%, #19baff7f 100%);
    // border-radius: @border-radius;

    .icon {
      color: @white;
    }

    .label {
      color: @white;
    }
  }
}

.sider-menu-collapsed {
  .item {
    flex-direction: column;
    justify-content: center;
    align-self: center;

    > span {
      display: flex;
      justify-content: center;
      align-items: center;

      + span {
        margin-left: 0;
      }
    }

    // .label {
    //   width: 0;
    //   opacity: 0;
    //   transition: width 0.2s ease-in-out;
    // }
  }
}

@root-entry-name: default;