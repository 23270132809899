.aspirin-table-search {
  position: relative;

  > div.ant-pro-table-search {
    padding: 24px;
    > form {
      > .ant-row-start {
        position: relative;
        display: flex;
        justify-content: flex-start;

        .ant-form-item-no-colon {
          width: 0 !important;
        }

        > .ant-col {
          &:last-child {
            position: relative;
            order: -1;
            min-width: 100%;
            margin: 0 !important;

            .ant-space,
            .ant-space-item {
              width: 100%;
            }

            .aspirin-table-search-item-box {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              width: 100%;
            }

            .aspirin-table-search-button-box {
              position: relative;
              right: 0;
              display: flex;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}

.aspirin-table-search-inline {
  > div.ant-pro-table-search {
    > form {
      > .ant-row-start {
        position: unset;

        > .ant-col {
          position: relative;
          z-index: 2;

          &:last-child {
            position: absolute;
            left: 0;
            min-width: 100%;
            margin: 0 !important;
            z-index: 1;
            padding: 0 24px !important;
          }
        }
      }
    }
  }
}

.aspirin-toolbar-padding-top-box {
  .ant-pro-table-list-toolbar-container {
    padding-top: 0 !important;
  }
}

@root-entry-name: default;