.cpoe-operation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }

  .cpoe-operation-select {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .main {
    flex: 1;
    padding: 12px;
  }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }
}

@root-entry-name: default;