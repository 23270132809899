.aspirin-table-footer-button-flex-end {
  justify-content: flex-end !important;
}
.display-flex {
  display: flex !important;
}
.flex-justify-content-space-between {
  justify-content: space-between !important;
}
.padding-top-12px {
  padding-top: 12px;
}

@root-entry-name: default;