.special_charge_scheme {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  padding: 20px 24px 24px;
  border-radius: 4px;
  .add_file {
    display: flex;
  }
}

@root-entry-name: default;