@import '~@/style/vars.less';

.@{prefix}-header-main {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .name{
    &:hover{
      cursor: pointer;
    }
  }
  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
    color: #fff;
    flex: 1;

    .logo {
      // width: 50px;
      height: 50px;
      float: left;
      flex: 0;
    }

    .title {
      margin-left: 10px;
      font-size: 24px;
      font-style: normal;
      white-space: nowrap;
      flex: 0;
      // font-weight: 700;
    }

    .detail {
      flex: 1;
      width: 0;
      font-size: 18px;
      margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right {
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 24px;
    color: #fff;
    font-size: @font-size;
    white-space: nowrap;

    .total-search {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-input {
        background-color: rgba(255, 255, 255, 0.2);
        border-width: 0 !important;
        color: #fff;
        padding-left: 0 !important;

        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        border-width: 0 !important;
      }
    }

    > div {
      + div {
        margin-left: 24px;
      }
    }

    .dropdown-container {
      .anticon {
        margin-left: 4px;
      }
    }

    .department {
      // font-weight: bold;
    }

    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;

      .ant-image,
      .ant-image-img {
        border-radius: 50%;
      }
    }

    .operation-icon {
      display: flex;
      padding: 0 24px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        background-color: #fff;
        width: 1px;
        height: 80%;
        position: absolute;
        top: 10%;
        left: 0;
      }

      .header-icon {
        + .header-icon {
          margin-left: 24px;
        }
      }
    }

    .header-icon {
      cursor: pointer;
    }

    .toggle-application-select {
      width: 200px;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      color: #fff;

      .ant-select-arrow {
        color: #fff;
      }
    }
  }
}

.collasped-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .item {
    height: 40px;
    width: 200px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    &:hover {
      content: '';
      background-color: #ebf2ff;
      color: @primary-color;
    }
  }
}

.collasped-application {
  .ant-popover-inner-content {
    padding: 8px 0;
  }

  .collasped-application-list {
    .item {
      height: 32px;
      width: 200px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      &:hover {
        content: '';
        background-color: #ebf2ff;
        color: @primary-color;
      }
    }
  }
}


@root-entry-name: default;