.certificate {
  flex: 1;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  &_title {
    display: flex;
    align-items: center;
    // justify-content: center;
    .ant-col {
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-form-item {
        margin: 0;
      }
    }
    // background-color: aqua;
  }
  #listPrintForm {
    .patientIdIs {
      padding-left: 10px;
      .suffix-cardImg-image {
        width: 20px;
        height: 20px;
      }
    }
  }
  #certificate {
    width: 75%;
  }
  .vs-base-table-container {
    margin: 10px 0;
  }
  .certificate_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      margin: 0 10px;
    }
  }
}

@root-entry-name: default;