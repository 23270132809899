.dept_drug_apply_wrapper {
  .vs-form-loading-container {
    box-sizing: border-box;
    padding: 24px;
    padding-bottom: 0;
    border-bottom: 1px solid #D6D8D9;
  }

  .vs-base-table-container {
    height: calc(100% - 140px);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;

    >button {
      margin-right: 16px;
    }
  }
}

.dept_drug_apply_wrapper_drawer {
  .ant-drawer-body {
    .ant-table-header {
      .ant-table-thead {
        tr {
          >th {
            background-color: #F5F7FA;
          }
        }
      }
    }
  }
}
@root-entry-name: default;