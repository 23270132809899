.patient_transfer {
  width: 100%;
  display: flex;
  // flex-direction: column;
  position: relative;

  &_left {
    padding: 24px;
    width: 494px;
    height: 100%;
    margin-right: 16px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    opacity: 1;
    &_form_title {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
      &_tag {
        margin-top: 2px;
        margin-right: 12px;
        width: 4px;
        height: 20px;
        background-color: #3276e8;
      }
    }
  }
  &_right {
    padding: 24px;
    flex: 1;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
  }
  .table_content_row_bgc {
    background-color: #fff;
  }
  &_search {
    margin-bottom: 16px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 68px;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    border-radius: 4px;
    &_input {
      display: flex;
      justify-content: space-between;
      &_item {
        margin-right: 40px;
        display: flex;
        align-items: center;
        &_addonAfter {
          display: flex;
          align-items: center;
          // border-radius: 0px 2px 2px 0px;
          // opacity: 1;
          &_text {
            margin-left: 8px;
          }
        }
        .ant-input-group > .ant-input:last-child,
        .ant-input-group-addon:last-child {
          color: #3276e8;
          border: 1px solid #3276e8;
          background: #ebf2ff;
          cursor: pointer;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          line-height: 36px;
        }
        .ant-input-group > .ant-input:first-child,
        .ant-input-group-addon:first-child {
          height: 36px;
        }
        // 下拉选择高度
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 36px;
        }
        &_label {
          width: 90px;
        }
      }
      align-items: center;
    }
  }
  .table_title {
    height: 47px;
    width: 100%;
    border: 1px solid #d6d8d9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    &_item {
      margin-left: 15px;
      .label {
        margin-right: 5px;
      }
    }
  }
  &_form {
    width: 100%;
    // padding: 24px;
    // padding-left: 0;
    height: 0;
    flex: 1;
    border-radius: 4px;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    display: flex;
    flex-direction: column;
    &_content {
      flex: 1;
      height: 0;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    &_bottom {
      padding: 0 24px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &_btn {
        margin-left: 16px;
        height: 32px;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
        line-height: 32px;
        cursor: pointer;
      }
      &_btn1 {
        width: 80px;
        color: #3276e8;
        border: 1px solid #3276e8;
      }
      &_btn2 {
        width: 96px;
        color: #ffffff;
        background: #3276e8;
      }
    }
  }
  &_table {
    // .ant-table-thead .ant-table-cell {
    //   background-color: #f5f7fa;
    //   &::before {
    //     background-color: transparent !important;
    //   }
    // }
    .ant-table-tbody {
      .aspirin-table-row-background-color.ant-table-row {
        background-color: #ffcbcb !important;
        .ant-table-cell {
          background-color: #ffcbcb !important;
        }
      }
    }
  }
  &_float_btn {
    position: absolute;
    top: 75px;
    right: 0;
    width: 22px;
    height: 90px;
    background: linear-gradient(90deg, #014d7e 0%, #0071bd 100%);
    border-radius: 16px 0px 0px 16px;
    opacity: 1;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &_icon {
      margin-bottom: 1px;
      text-align: center;
    }
    &_text {
      font-size: 12px;
      writing-mode: vertical-lr; /*设置文字显示为竖排（从左往右）*/
      text-orientation: upright; /*设置文字竖排时的方向*/
      text-align: center; /*设置文字水平显示时的对齐方式*/
      line-height: 22px; /*设置行高*/
      letter-spacing: 3px;
    }
  }
  &_drawer {
    display: flex;
    &_text {
      margin-right: 40px;
      font-size: 20px;
      color: #3276e8;
    }
  }
  &_modal {
    // padding: 0 0 0 9px;
    &_total {
      height: 58px;
      display: flex;
      align-items: center;
      &_item {
        min-width: 140px;
        margin-right: 24px;
      }
    }
    &_content {
      display: flex;
      flex-wrap: wrap;
      &_detail_item {
        margin: 0 0px 18px 0;
        // padding-right: 18px;
        width: 310px;
        height: 110px;
        flex-shrink: 0;
        cursor: pointer;
        // border: 1px solid red;
        &_content {
          height: 100%;
          width: 292px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 4px;
          box-sizing: border-box;
          &_topbgc {
            width: 100%;
            height: 4px;
            &_teji {
              background-color: #e34a4e;
            }
            &_yiji {
              background-color: #ff7d45;
            }
            &_erji {
              background-color: #ffd900;
            }
            &_sanji {
              background-color: #aeb0b3;
            }
            &_other {
              background-color: #fff;
            }
          }
          &_body {
            // border: 1px solid red;
            flex: 1;
            height: 0;
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            &_item1 {
              height: 106px;
              flex: 1;
              &_tag {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                &_text {
                  display: flex;
                  align-items: center;
                  font-size: 12px;
                  color: #85898d;
                  &_num {
                    margin-right: 4px;
                    font-size: 20px;
                    font-weight: 500;
                  }
                  // 待转科
                  &_num1 {
                    color: #3276e8;
                  }
                  // 当天新入科
                  &_num2 {
                    color: #30a313;
                  }
                  // 明日出院
                  &_num3 {
                    color: #cd8aed;
                  }
                  // 今日出院
                  &_num4 {
                    color: #e34a4e;
                  }
                  &_num5 {
                    color: #343b42;
                  }
                }
                &_icon {
                }
              }
              &_info {
                margin: 2px 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 28px;
                &_name {
                  margin-right: 5px;
                  font-size: 18px;
                  font-weight: bolder;
                  color: #343b42;
                }
                &_age {
                  font-size: 14px;
                  font-weight: 400;
                  color: #343b42;
                }
              }
              &_type {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 400;
                color: #85898d;
                &_text {
                  // height: 10px;
                  padding: 2px 4px;
                  background: #f5f7fa;
                  border-radius: 2px 2px 2px 2px;
                  color: #343b42;
                  font-size: 12px;
                }
              }
            }
            &_item2 {
              margin: 14px 0 0;
              padding: 0 0 14px;
              min-height: 66px;
              border-top: 1px dashed #aeb0b3;
              &_content {
                padding: 5px 9px 6px;
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                border-radius: 4px 4px 4px 4px;
                background: #f5f5f5;
                &_item {
                  font-size: 12px;
                  font-weight: 500;
                  color: #5d6268;
                  width: 50%;
                  cursor: pointer;
                  padding-right: 5px;
                  &_value {
                    color: #343b42;
                  }
                }
                &_type {
                  margin-top: 4px;
                  display: flex;
                  &_item {
                    margin-right: 8px;
                    width: 28px;
                    height: 20px;
                    background: #ffffff;
                    border-radius: 2px 2px 2px 2px;
                    opacity: 1;
                    border: 1px solid #e7e7e7;
                    font-size: 12px;
                    font-weight: 400;
                    color: #85898d;
                    text-align: center;
                    line-height: 17px;
                    cursor: pointer;
                  }
                  &_ya {
                    border: 1px solid #ffa57e;
                    color: #ffa57e;
                  }
                  &_die {
                    border: 1px solid #c395fe;
                    color: #c395fe;
                  }
                  &_teng {
                    border: 1px solid #ff7b7b;
                    color: #ff7b7b;
                  }
                  &_hua {
                    color: #739fea;
                    border: 1px solid #739fea;
                  }
                  &_vte {
                    width: 39px;
                    border: 1px solid #9bd78c;
                    color: #9bd78c;
                  }
                }
              }
            }
          }
          &_detail_card_empty {
            padding: 0 17px;
            &_bed {
              position: absolute;
              width: 292px;
              height: 110px;
              // background-color: rgba(0, 0, 0, 0.5);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              &_number {
                height: 45px;
                line-height: 54px;
                font-size: 20px;
                font-weight: 500;
                color: #343b42;
              }
              &_icon {
                text-align: center;
              }
              &__name {
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #5d6268;
              }
            }
          }
        }
        &_content1 {
          width: 292px;
          min-height: 110px;
          padding-right: 0;
          border-radius: 4px;
          margin: 10px 0 0 10px;
        }
      }
    }
  }
}
.patient_transfer_tag {
  margin: 0 auto;
  height: 24px;
  width: 58px;
  line-height: 24px;
  background-color: #d4e6f9;
  color: #3276e8;
  text-align: center;
}
.patient_transfer_status {
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &_true {
    background-color: #00a300;
  }
  &_false {
    background-color: red;
  }
}

@root-entry-name: default;