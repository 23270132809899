.homePageInfomation {
  margin-top: -14px;
  height: 100%;
  overflow: hidden;
  .ant-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
  .ant-tabs-nav-wrap {
    padding: 0 16px;
    // color: #3276e8;
    font-weight: bolder;
    font-size: 16px;
    border-bottom: 1px solid #d6d8d9;
  }
  .ant-tabs-content .ant-tabs-tabpane {
    padding: 0 40px;
    padding-top: 6px;
    height: 100%;
  }
  &_line {
    border-top: 1px solid #d6d8d9;
    margin-bottom: 24px;
  }
}

@root-entry-name: default;