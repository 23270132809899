.aspirin-check-order-container {
  width: 100%;
  display: flex;
  .aspirin-check-order-main {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
      flex: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
      border-radius: 4px;
      background-color: rgba(255,255,255,0.5);
      margin-bottom: 16px;

      > div {
        padding: 0 16px 0 24px;
        position: relative;

        &:not(:last-of-type) {
          &::after {
            position: absolute;
            content: "";
            height: 24px;
            width: 1px;
            background-color: #AEB0B3;
            right: 0;
          }
        }
      }
    }

    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 16px 24px;
      border-radius: 4px;
      background-color: rgba(255,255,255,0.5);

      .vs-base-table-container, .order-check-loading {
        flex: 1;
        width: 100%;
      }

      .aspirin-table > .ant-table-wrapper {
        background-color: transparent;
      }

      .aspirin-check-order-footer {
        flex: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 40px;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > div {
            + div {
              margin-left: 24px;
            }
          }

          .label {
            color: #343B42;
            font-size: 16px;
            font-weight: 500;
          }

          .value {
            color: #E34A4E;
            font-size: 16px;
            font-weight: 500;
            margin-left: 12px;
          }
        }

        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .footer-btn {
            + .footer-btn {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

.aspirin-table-order .ant-table-tbody {
  > .aspirin-order-table-patient-render-row {
    > td {
      background-color: #fff !important;
    }
  }

  > .aspirin-order-table-checked-render-row {
    > td {
      background-color: #E0E0E0 !important;
    }
  }
}



.aspirin-order-table-patient-render {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  > span {
    margin-left: 30px;
    + span {
      margin-left: 60px;
    }
  }
}

.aspirin-check-order-patient-drawer {
  .ant-drawer-body {
    padding: 0;
  }
}

.aspirin-check-order-patient-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }

  .filter{
    background-color: #F4F5F7;
    border-bottom: 1px solid #D6D8D9;
    padding: 20px 24px;
  }

  .first {
    border-bottom: 1px solid #D6D8D9;
    padding-bottom: 12px;

    .ant-radio-group {
      display: flex;
      flex-direction: column;

      .ant-radio-wrapper {
        +.ant-radio-wrapper {
          margin-top: 6px;
        }
      }
    }
  }

  .second {
    padding: 12px 0;

    .group {
      margin-top: 12px;
      display: flex;
      align-items: center;

      > span {
        margin-right: 12px;
      }

      .ant-select {
        width: 100%;
        flex: 1;
      }
    }
  }

  .content {
    padding: 16px 24px;
  }
}

.aspirin-check-order-send-modal {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 640px;

    > div {
      width: 100%;
      flex: 1;
    }
  }
}

.aspirin-check-order-rollback-modal {
  .ant-modal-body {
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 640px;

    > div {
      width: 100%;
      flex: 1;
    }

    .first {
      padding: 24px;
    }

    .second {
      flex: 0;
      max-height: 135px;
      min-height: 135px;
      background-color: #F4F5F7;
      padding: 16px 24px;

      > div {
        &:first-of-type {
          color: #3276E8;
          font-size: 16px;
          margin-bottom: 12px;
        }

        &:nth-of-type(2) {
          color: #5D6268;
          font-size: 14px;
          margin-bottom: 16px;
        }

        &:last-of-type {
          color: #343B42;
          font-size: 14px;

          > span {
            margin-right: 14px;
          }

          .ant-select {
            min-width: 240px;
          }
        }
      }
    }
  }
}

@root-entry-name: default;