.tree-menu-context-container {
  // background-color: red;
  user-select: none;
}

.ant-tree {
  background: transparent !important;
}
.context-list {
  padding: 4px;
  list-style-type: none;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.context-children-list {
  padding: 4px;
  list-style-type: none;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  position: absolute;
  left: calc(~'100% + 6px');
  top: -4px;
  transform: translate(-2000px);
}

.context-children-list-show {
  transform: translate(0);
}

.context-item {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
  position: relative;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.vsf-treemenu-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  overflow: hidden;

  > div {
    width: 100%;
  }
}

.vsf-treemenu-main {
  flex: 1;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 0;

  .vsf-treemenu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    flex: 0;

    .title {
      font-size: 20px;
      font-weight: normal;
      display: flex;
      align-items: center;

      .title-sign {
        width: 22px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 12px;

        > div {
          width: 100%;
          height: 3px;
          background-color: #3276e8;
        }
      }
    }

    .extra {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .ant-btn {
        font-size: 12px;
        color: #fff;
        background-color: #69a1ff;
        border-radius: 4px;
        border-width: 0;
        padding: 0 10px;

        + .ant-btn {
          margin-left: 12px;
        }
      }
    }
  }

  .vsf-treemenu-search {
    margin-bottom: 30px;
    width: 100%;
    flex: 0;
    border-width: 0;
    //input更换成白底
    // background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    flex: 0;

    input {
      background-color: transparent;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover,
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: transparent;
    box-shadow: none;
  }

  .vsf-treemenu-content {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 0;

    .aspirin-treemenu-title-render {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .extra {
        display: none;
      }

      .extra-default {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .aspirin-treemenu-title-render-active {
      color: #3276e8;
    }
    .label {
      font-size: 18px !important;
    }
  }

  .vsf-treemenu-content-center {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin {
      width: 100%;
    }
  }

  .vsf-treemenu-tree {
    .ant-tree-switcher {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-tree-treenode {
      height: 46px;
      align-items: center;
      padding: 0 !important;
    }

    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: transparent !important;
      width: 100%;
      position: relative;

      .ant-tree-title {
        width: 100%;
        position: relative;
      }
    }

    .ant-tree-switcher_close .ant-tree-switcher-icon svg {
      transform: rotate(0) !important;
    }
    .ant-tree-switcher_open .ant-tree-switcher-icon svg {
      transform: rotate(0) !important;
    }

    .ant-tree-node-content-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-tree-title {
        width: 100%;
        position: relative;
      }

      &:hover {
        background-color: transparent !important;

        .aspirin-treemenu-title-render {
          .extra-hover {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }

    .ant-tree-treenode-selected {
      background-color: #ebf2ff !important;
      padding-bottom: 0 !important;
      .operationField {
        visibility: visible;
      }
      .ant-tree-node-content-wrapper {
        color: #3276e8;
      }

      .aspirin-treemenu-title-render {
        .extra-select {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    .ant-tree-treenode {
      &:hover {
        background-color: #ebf2ff;
        .operationField {
          visibility: visible;
        }
      }
    }
  }
}

.vsf-treemenu-footer {
  .add-btn {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px dashed #3276e8;
    cursor: pointer;
    color: #3276e8;
  }

  .adding {
    border-color: transparent;
    color: #fff;
    background-color: #3276e8;
  }
}

.aspirin-tree-hide-switcher-box {
  .ant-tree-switcher {
    display: none !important;
  }
}

@root-entry-name: default;