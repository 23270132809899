.MedicalTechnologyChargeTemplate-Container {
  width: 100%;
  height: 100%;
  .Menu {
    width: 100%;
    .ant-menu {
      background: var(--background);
      height: 100%;
    }
    margin-bottom: 2px;
  }
  .container {
    width: 100%;
    height: 94%;
    .TreeMenu {
      width: 100%;
      height: 100%;
      .vsf-treemenu-container {
        padding-top: 0 !important;
      }
    }
    .Table {
      width: 100%;
      height: 100%;
      position: relative;
      .container {
        height: calc(100% - 36px);
        position: relative;
      }
      .button {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        z-index: 999;
        margin-bottom: 6px;
        &::before {
          content: attr(data-title);
          font-size: 18px;
          color: black;
          position: absolute;
          top: 0;
          left: 12px;
        }
        &::after {
          content: ' ';
          width: 6px;
          height: 25px;
          background: rgba(50, 118, 232, 1);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.ant-modal-root {
  .ModalContainer {
    .Form {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .container {
        width: 60%;
      }
    }
  }
}

#medicalTechnologyDepartmentChargeTemplateList,
#medicalTechnologySaveChargeTemplate,
#staffChargeTemplateList {
  .aspirin-vs-table-row-box td:last-child > div {
    display: inline-block !important;
  }
  background: rgba(255, 255, 255, 0.3);
  .ant-table-thead {
    tr > th {
      text-align: center;
      background: rgba(229, 242, 255, 1);
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    tr {
      text-align: center;
      td {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

@root-entry-name: default;