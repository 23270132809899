.nurses_stationbed_card {
  width: 100%;
  display: flex;
  flex-direction: column;

  &_search {
    margin-bottom: 16px;
    padding: 0 24px;
    height: 68px;
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    &_input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &_left {
        display: flex;
        &_item {
          margin-right: 40px;
          display: flex;
          align-items: center;
          font-size: 18px;
          &_select {
            margin-left: 20px;
          }
          &_total {
            display: flex;
            &_item {
              margin-right: 58px;
            }
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
        &_item {
          min-width: 40px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #5d6268;
          cursor: pointer;
          .ant-popover-inner {
            border-radius: 8px !important;
          }

          &_explain {
            width: 240px;
            height: 256px;
            color: #fff;
            &_title {
              font-size: 14px;
              font-weight: 600;
              color: #ffffff;
              margin-bottom: 10px;
            }
            &_leave {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 12px;
              font-size: 14px;
              &_item {
                margin-bottom: 2px;
                width: 50%;
                display: flex;
                align-items: center;
                &_div {
                  width: 16px;
                  height: 6px;
                  margin-right: 5px;
                }
              }
            }
            &_icon {
              display: flex;
              flex-wrap: wrap;
              font-size: 14px;
              &_item {
                display: flex;
                align-items: center;
                margin-bottom: 2px;
                &_icon {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  &_content {
    flex: 1;
    height: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    display: flex;
    &_total {
      padding: 24px;
      width: 240px;
      border-right: 1px solid #3276e8;
      &_empty {
        width: 200px;
        height: 180px;
      }
      &_table {
        height: 100%;
        display: flex;
        flex-direction: column;
        &_header {
          height: 52px;
          display: flex;
          line-height: 52px;
          border: 1px solid #d6d8d9;
          text-align: center;
          background-color: #e1f1ff;
          font-size: 16px;
          font-weight: bolder;
          color: #343b42;
          &_title {
            flex-shrink: 0;
            padding: 0 5px;
            height: 100%;
            width: 120px;
            // border-right: 1px solid #d6d8d9;
          }
          &_amount {
            flex-shrink: 0;
            padding: 0 5px;
            height: 100%;
            width: 72px;
          }
        }
        &_content {
          flex: 1;
          height: 0;
          overflow-y: scroll;
          //   border: 1px solid red;
          &_item {
            height: 52px;
            display: flex;
            line-height: 52px;
            border: 1px solid #d6d8d9;
            border-top: none;
            text-align: center;
            font-size: 14px;
            color: #343b42;
            cursor: pointer;
            &_title {
              flex-shrink: 0;
              padding: 0 5px;
              height: 100%;
              width: 120px;
              border-right: 1px solid #d6d8d9;
              border-top: none;
            }
            &_amount {
              flex-shrink: 0;
              padding: 0 5px;
              height: 100%;
              width: 72px;
            }
          }
          &_active_item {
            background-color: #cbe4ff;
          }
        }
      }
    }
    &_list {
      width: 0;
      flex: 1;
      padding: 12px 6px 22px 14px;
    }
  }
  &_simple_content {
    flex: 1;
    height: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    display: flex;
  }
  &_list_content {
    flex: 1;
    height: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    &_table {
      height: 0;
      flex: 1;
      background-color: transparent;
      &_VSPromiseTable {
        &_icon {
          margin-right: 5px;
          cursor: pointer;
        }
        td.ant-table-cell {
          background-color: transparent;
        }

        .ant-pro-card {
          background-color: transparent;
        }

        .ant-table {
          background-color: transparent !important;
        }

        .ant-table-thead .ant-table-cell {
          border: none;
          border-bottom: 1px solid #d6d8d9;
          background-color: #e5f2ff;
          font-size: 14px;
          font-weight: 600;
          &::before {
            background-color: transparent !important;
          }
        }
        td.ant-table-cell {
          border: 1px solid #d6d8d9;
          border-top: none;
          border-right: none;
          background-color: transparent;
        }
        .ant-table-placeholder {
          td.ant-table-cell:last-child {
            border-right: none !important;
          }
        }
        .ant-table-placeholder:hover {
          background-color: transparent !important;
        }
        .ant-table-row {
          td.ant-table-cell:last-child {
            border-right: 1px solid #d6d8d9 !important;
          }
        }
        .ant-table-placeholder {
          .ant-table-cell {
            border: none !important;
          }
        }
      }
    }
    &_bottom {
      padding: 0 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 32px;
      font-size: 16px;
      &_item1 {
        margin-left: 32px;
        width: 80px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #3276e8;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        color: #3276e8;
        cursor: pointer;
      }
      &_item2 {
        margin-left: 32px;
        width: 96px;
        height: 32px;
        background: #3276e8;
        border-radius: 2px;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  &_modal {
    padding: 32px 36px;
    width: 656px;
    display: flex;
    background-color: #f2f3f7;
    &_left,
    &_right {
      flex-direction: column;
      &_select {
        margin-bottom: 16px;
      }
      &_content {
        width: 240px;
        height: 178px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        padding: 0 16px;
        &_header {
          height: 45px;
          display: flex;
          align-items: center;
          &_bedNum {
            font-size: 20px;
            font-weight: 500;
            color: #30a313;
            margin-right: 3px;
          }
          &_text {
            font-size: 12px;
            font-weight: 400;
            color: #85898d;
          }
        }
        &_item1 {
          height: 55px;
          border-bottom: 1px dashed #aeb0b3;
          &_info {
            display: flex;
            justify-content: space-between;
            &_name {
              font-size: 18px;
              font-weight: 600;
              color: #343b42;
            }
            &_age {
              font-size: 14px;
              font-weight: 400;
              color: #343b42;
            }
            &_num {
              font-size: 14px;
              font-weight: 400;
              color: #85898d;
            }
            &_type {
              font-size: 12px;
              font-weight: 400;
              color: #343b42;
              height: 16px;
              line-height: 16px;
              padding: 0 4px;
              background: #f5f7fa;
              border-radius: 2px 2px 2px 2px;
              opacity: 1;
            }
          }
        }
        &_item2 {
          margin-top: 6px;
          padding: 6px 9px;
          box-sizing: border-box;
          // height: 46px;
          width: 100%;
          background-color: #f6f6f6;
          display: flex;
          flex-wrap: wrap;
          &_item {
            width: 50%;
            &_label {
              font-size: 12px;
              font-weight: 500;
              color: #5d6268;
            }
            &_value {
              font-size: 12px;
              font-weight: 500;
              color: #343b42;
            }
          }
        }
        &_detail_card_empty {
          padding: 0 17px;
          &_bed {
            position: absolute;
            top: 0;
            left: 0;
            width: 240px;
            height: 178px;
            // background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &_number {
              height: 45px;
              line-height: 45px;
              font-size: 20px;
              font-weight: 500;
              color: #343b42;
            }
            &_icon {
              text-align: center;
            }
            &__name {
              text-align: center;
              font-size: 14px;
              font-weight: 600;
              color: #5d6268;
            }
          }
        }
      }
    }
    &_center {
      text-align: center;
      margin: auto;
      width: 100px;
    }
  }
}
// .ant-popover-inner {
//   border-radius: 8px !important;
// }

@root-entry-name: default;