.aspirin-settings-modal {
  .ant-modal-body {
    padding: 0 18px 18px 18px !important;
    max-height: 700px;
    position: relative;
    display: flex;
  }

  .ant-modal-header {
    padding: 16px 18px !important;
  }
}

.aspirin-settings-main {
  width: 100%;
  position: relative;
  flex: 1;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    overflow-y: auto;
    padding-right: 6px;
  }
}

.aspirin-settings-main-shortcuts {
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 12px;
  }

  .list {
    .shortcuts-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      + .shortcuts-item {
        margin-top: 12px;
      }

      .label,
      .value {
        width: 50%;
      }
    }
  }
}

@root-entry-name: default;