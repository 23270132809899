.inpSettleMasterList {
  //   background: rgba(255, 255, 255, 0.5);
  //   opacity: 1;
  //   padding: 20px 24px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  &_search {
    margin-bottom: 24px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    padding: 20px 24px 24px;
    display: flex;
    flex-direction: column;
  }
  &_body {
    width: 100%;

    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    padding: 20px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_table {
      width: 100%;
      flex: 1;
      overflow: hidden;
    }
    &_bottom {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@root-entry-name: default;