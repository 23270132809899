.doctor_stationbed_card {
  // width: 100%;
  // padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  background-color: #f6f9fb;
  // border: 1px solid red;
  &_search {
    box-sizing: border-box;
    // margin-bottom: 16px;
    width: 1270px;
    padding: 0 24px;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    align-items: center;
    // width: 100%;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    border-bottom: 1px solid #d6d8d9;
    // border-radius: 4px;
    &_input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &_left {
        display: flex;
        align-items: center;
        &_item {
          margin-right: 40px;
          display: flex;
          align-items: center;
          .ant-input {
            background-color: #eceff1;
          }
          &_select {
            margin-left: 20px;
          }
          &_total {
            display: flex;
            &_item {
              margin-right: 58px;
            }
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
        &_item {
          min-width: 40px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          font-size: 12px;
          font-weight: 400;
          color: #5d6268;
          cursor: pointer;
          .ant-popover-inner {
            border-radius: 8px !important;
          }

          &_explain {
            width: 240px;
            height: 256px;
            color: #fff;
            &_title {
              font-size: 14px;
              font-weight: 600;
              color: #ffffff;
              margin-bottom: 10px;
            }
            &_leave {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 12px;
              font-size: 14px;
              &_item {
                margin-bottom: 2px;
                width: 50%;
                display: flex;
                align-items: center;
                &_div {
                  width: 16px;
                  height: 6px;
                  margin-right: 5px;
                }
              }
            }
            &_icon {
              display: flex;
              flex-wrap: wrap;
              font-size: 14px;
              &_item {
                display: flex;
                align-items: center;
                margin-bottom: 2px;
                &_icon {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  &_search1 {
    height: 61px;
    width: 1270px;
    padding: 0 24px;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    align-items: center;
  }
  &_content {
    // flex: 1;
    // height: 0;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    // width: 1270px;
    width: 1222px;
    // min-height: 600px;
    height: calc(~'100vh - 385px');
    border-radius: 4px;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 24px 0;
    box-sizing: border-box;
    // overflow: hidden;
    &_list {
      // border: 1px solid rgb(19, 17, 17);
      overflow-y: scroll;
      width: 0;
      flex: 1;
      margin: 18px 3px 22px 21px;
      &_title {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
        color: #343b42;
      }
      &_desc {
        &_label {
          margin-right: 20px;
          font-size: 16px;
          font-weight: 400;
          color: #343b42;
        }
        &_number {
          margin: 0 10px;
        }
      }
      &_extra {
        padding-right: 24px;
        display: flex;
        align-items: center;
        &_btn {
          margin-left: 10px;
          width: 64px;
          height: 24px;
          background: #3276e8;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  &_list_content {
    // flex: 1;
    // height: 0;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    // border: 1px solid red;
    width: 1270px;
    // min-height: 600px;
    height: calc(~'100vh - 385px');
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0 21px 0;
    &_table {
      height: 0;
      flex: 1;
      background-color: transparent;
      width: 1222px;
      &_VSPromiseTable {
        // margin-top: 10px;
        // overflow: hidden;
        // &_icon {
        //   margin-right: 5px;
        //   cursor: pointer;
        // }
        // .ant-pro-card {
        //   background-color: transparent;
        // }

        .ant-table {
          background-color: #f6f9fb !important;
        }
        td.ant-table-cell {
          background-color: #f6f9fb;
        }
        .ant-table-thead .ant-table-cell {
          border: none;
          border-bottom: 1px solid #d6d8d9;
          background-color: #e5f2ff;
          font-size: 14px;
          font-weight: 600;
          &::before {
            background-color: #f6f9fb !important;
          }
        }
        td.ant-table-cell {
          border: 1px solid #d6d8d9;
          border-top: none;
          border-right: none;
          background-color: #f6f9fb;
        }
        td.ant-table-cell:last-child {
          border-right: 1px solid #d6d8d9 !important;
        }
        .ant-table-placeholder {
          .ant-table-cell {
            border: none !important;
          }
        }
      }
    }
    &_bottom {
      // padding: 0 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 36px;
      font-size: 16px;
      &_item1 {
        margin-left: 32px;
        width: 80px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #3276e8;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        color: #3276e8;
        cursor: pointer;
      }
      &_item2 {
        margin-left: 32px;
        width: 96px;
        height: 32px;
        background: #3276e8;
        border-radius: 2px;
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  &_bottom {
    padding: 0 24px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #5d6268;
    &_left {
      cursor: pointer;
    }
    &_right {
      display: flex;
      align-items: center;
      &_item {
        margin-left: 15px;
        display: flex;
        align-items: center;
        &_btn {
          cursor: pointer;
          margin-left: 8px;
          height: 28px;
          padding: 0 20px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          // line-height: 14px;
          // background-color: #3276e8;
        }
        .ant-btn {
          height: 28px;
          line-height: 15px;
          // width: 216px;
        }
      }
    }
  }
}
// .ant-popover-inner {
//   border-radius: 8px !important;
// }

@root-entry-name: default;