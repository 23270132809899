@import '~@/style/vars.less';

.@{prefix}-index-form-title {
  border-radius: @border-radius;
  background-color: #fff;
  padding: @padding-5 @padding;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  // border: 1px solid red;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d5d7;
    .left-tag {
      margin-top: 2px;
      margin-right: 12px;
      width: 4px;
      height: 20px;
      background-color: #3276e8;
    }
    .left-block {
      display: flex;
      align-items: center;
      font-size: @font-size-20;
      font-weight: @font-weight-bold;
      color: @title-color;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #343b42;
    }
  }

  .content {
    flex: 1;
    width: 100%;
    padding: 20px 16px 0;
  }
}

@root-entry-name: default;