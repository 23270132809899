.money-font {
  font-size: 20px;
  font-weight: 500;
  color: #3276e8;
}

.text-before-weight {
  font-size: 20px;
  font-weight: 500;
  color: #343b42;
  height: 30px;
  position: relative;

  &::before {
    position: absolute;
    width: 5px;
    height: 20px;
    background: #3276e8;
    content: '';
    transform: translate(-50%, -50%);
    top: 50%;
    left: -12px;
  }
}

.outpatient-refunds-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  // background: rgba(255, 255, 255, 0.5) !important;
  .top {
    // height: 112px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    padding: 24px;

    padding-bottom: 0;

    #ClinicOutpVisitListForm {
      .patientIdIs {
        width: 600px;
        padding-left: 10px;
        .patientIdIs-select {
          width: 200px;
        }
      }

      .suffix-cardImg-image {
        width: 20px;
        height: 20px;
      }
    }
  }

  .bootom {
    flex: 1;
    display: flex;

    .left {
      background-color: rgba(255, 255, 255, 0.5);
      width: 290px;
      margin-right: 16px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      flex: none;

      .text-before-weight {
        font-size: 20px;
        font-weight: 500;
        color: #343b42;
        height: 30px;
        position: relative;

        &::before {
          position: absolute;
          width: 5px;
          height: 20px;
          background: #3276e8;
          content: '';
          transform: translate(-50%, -50%);
          top: 50%;
          left: -12px;
        }
      }

      .list-layout {
        flex: 1;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: #343b42;
      }
    }

    .right {
      background-color: rgba(255, 255, 255, 0.5);
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;

      .refunds-content-title {
        height: 152px;
        padding: 24px;
        overflow: auto;

        .refunds-tags {
          margin-top: 24px;
          // overflow: auto;

          .refunds-tags-item {
            height: 32px;
            font-size: 16px;
            line-height: 32px;
            margin-left: 24px;
            margin-top: 12px;
            color: #343b42;
            border: 1px solid #3276e8;
            border-radius: 4px;
          }
        }
      }

      .refunds-content {
        flex: 1;
        padding: 24px;
        padding-bottom: 0px;
        width: 100%;

        .ant-table-thead {
          .ant-table-cell {
            // text-align: center; // 将单元格内容水平居中
          }
        }

        .ant-table-tbody {
          .ant-table-cell {
            // text-align: center; // 将单元格内容水平居中
          }
        }
      }

      .refunds-btn {
        margin-top: -50px;
        height: 50px;
        padding-right: 24px;
        text-align: right;
        line-height: 50px;
        z-index: 100;
      }
    }
  }
}

.modal-div-font-style {
  font-size: 16px;
  font-weight: 400;
  color: #343b42;
}

.aspirin-child-double-table {
  &:only-child {
    .ant-table {
      padding-left: 66px;
    }
  }
}

@root-entry-name: default;