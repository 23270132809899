.aspirin-dictionary {
  margin: 0 20px;
  width: 100%;
}

.aspirin-dictionary-dropdown-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(226, 141, 141, 0.05);
  width: 1000px;

  // max-height: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px 24px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .first {
      font-size: 12px;
      color: #aeb0b3;
    }

    .second {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0 16px 0;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;

        > div,
        > input {
          margin-right: 10px;
        }
      }
    }
  }
}

.dictionary-table {
  padding: 0;
}

.cdss-doctor-order {
  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-confirm-content {
    margin-top: 0 !important;
  }

  .ant-modal-confirm-btns {
    padding: 0 24px 24px 0 !important;
  }

  .cdss-doctor-order-content {
    padding: 20px 24px 0 24px;
    position: relative;
    overflow: hidden;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #d2e7ff 0%, #ffffff 100%);
      width: 100%;
      height: 71px;
      z-index: 1;

      .first {
        // background: url('../../assets/imgs/leftradio.png');
        background: url('../../../../assets/imgs/leftradio.png');
        background-size: cover;
        width: 100px;
        height: 100px;
        left: -50px;
        top: -50px;
        position: absolute;
      }

      .second {
        background: url('../../../../assets/imgs/cdssheader.png');
        background-size: cover;
        width: 122px;
        height: 67px;
        top: 20px;
        left: 400px;
        position: absolute;
      }

      .third {
        background: url('../../../../assets/imgs/robot.png');
        background-size: cover;
        width: 48px;
        height: 48px;
        top: -10px;
        left: 480px;
        position: absolute;
      }
    }

    .header {
      color: #3276e8;
      font-size: 20px;
      z-index: 2;
      position: relative;
    }

    .sub-header {
      color: #3276e8;
      font-size: 16px;
      margin: 24px 0 32px 0;
      z-index: 2;
      position: relative;
      font-weight: 500;
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      + .item {
        margin-top: 12px;
      }

      .label {
        color: #343b42;
        font-weight: 500;
        font-size: 16px;
        white-space: nowrap;
      }

      .value {
        font-size: 16px;
        color: #5d6268;
        margin-left: 12px;
      }
    }
  }
}

.cdss-doctor-order-error {
  .ant-btn-primary {
    border-color: #e34a4e;
    background: #e34a4e;
  }

  .cdss-doctor-order-content {
    .sub-header {
      color: #e34a4e;
    }
  }
}

@root-entry-name: default;