.batch_triage {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &_search {
    width: 100%;
    padding: 24px 24px 14px;
    margin-bottom: 16px;
    min-height: 64px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &_body {
    width: 100%;
    overflow: hidden;
    padding: 16px 24px;
    flex: 1;
    height: 0;
    overflow: hidden;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    display: flex;
    flex-direction: column;
    &_VSTable {
      flex: 1;
      overflow: hidden;
      flex-shrink: 0;
    }
    &_total {
      flex-shrink: 0;
      margin-bottom: 16px;
      display: flex;

      height: 40px;
      .left,
      .right {
        font-size: 16px;
        border-radius: 2px 2px 2px 2px;
      }
      .left {
        margin-right: 10px;
        // padding: 0 0 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 89px;
        border: 1px solid #3276e8;
        color: #3276e8;
        cursor: pointer;
      }
      .right {
        display: flex;
        align-items: center;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        .item {
          //   margin: 0 14px;
          //   padding: 0 14px;
          min-width: 87px;
          color: #85898d;
          //   border-right: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .content {
            margin: 0 14px;
          }
        }
        .item:last-child {
          border-right: 1px solid transparent;
        }
      }
    }
  }
  &_bottom {
    flex-shrink: 0;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    Button {
      margin-left: 12px;
    }
  }
}

@root-entry-name: default;