.BillingAudit_container {
  width: 100%;
  height: 100%;
  background-color: inherit;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .vs-base-table-container {
      height: calc(100% - 52px) !important;
      overflow: hidden;
    }
    .UserInfo,
    .TableMenu {
      width: 100%;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.3);
    }
    .UserInfo {
      height: 14%;
      padding: 12px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .Descriptions {
        .ant-descriptions-item {
          font-size: 24px !important;
          .ant-descriptions-item-label {
            text-align: left;
            color: rgba(93, 98, 104, 1);
          }
        }
      }
    }
    .TableMenu {
      height: 84%;
      .Components {
        width: 100%;
        height: calc(100% - 45px);
        padding: 12px 24px 0;
        .MailComponent,
        .AppComponent,
        .OtherComponent,
        .DetailComponent {
          width: 100%;
          height: calc(100% - 52px);
          overflow: hidden;
        }
        .Expense,
        .MailMsg {
          width: 100%;
          height: 50%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
      .ant-menu {
        background: rgba(255, 255, 255, 0.3);
      }
      .title-container {
        // height: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn {
          display: inline-block;
        }
        .title_btn {
          .ant-row {
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-form-item {
              margin: 0;
            }
          }
        }
      }
      .title {
        display: inline-block;
        margin: 0 !important;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        left: 6px;
        padding-left: 6px;
        // line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          position: absolute;
          top: 50%;
          left: -6px;
          transform: translateY(-50%);
          content: '';
          width: 6px;
          height: 100%;
          background: rgba(50, 118, 232, 1);
          display: inline-block;
        }
      }
    }
  }
}

.aspirin-tip-box {
  font-size: 18px;
}
.ant-modal-confirm-title {
  > span {
    font-size: 24px;
  }
}
#billingAuditOrderInfoList {
  height: 100% !important;
}

@root-entry-name: default;