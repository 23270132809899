.mine_context_menu {
  position: relative;
  width: 140px;
  border-radius: 4px !important;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgb(184, 181, 181);
  z-index: 999999 !important;
  &_menuitem {
    padding: 0 18px;
    height: 32px !important;
    line-height: 32px;
    position: relative;
    z-index: 999999 !important;
    &_normal {
      color: #5d6268;
    }
    &_disabled {
      color: #aeb0b3;
    }
    &_border_bottom {
      border-bottom: 1px solid #e7e7e7;
    }
    &_button:hover {
      background-color: #ebf2ff !important;
      color: #3276e8;
    }
  }
  //   &_menuitem:hover {
  //     background-color: #ebf2ff;
  //     color: #3276e8;
  //   }
}

@root-entry-name: default;