.charge-layout {
  // background-color: rgba(255, 255, 255, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // overflow: auto;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    .main {
      flex: 1;
      // background-color: pink;
      // height: 700px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .table-tittle {
        .ant-table-tbody {
          visibility: collapse;
        }

        .ant-table-thead > tr > th {
          background-color: #e5f2ff;
          /* 替换为你想要的颜色值 */
        }
      }

      .table-content-scroll {
        flex: 1;
        overflow: auto;
      }
    }

    .btn-bottm {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .top {
    width: 100%;
    height: 112px !important;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);

    .row_class {
      padding: 24px;
      padding-top: 12px;
    }

    #outpatientChargesForm {
      .displayId {
        width: 300px;
      }

      .patientIdIs {
        width: 600px;
        padding-left: 10px;

        .patientIdIs-select {
          width: 200px;
        }

        .suffix-readCard-image {
          width: 20px;
          height: 20px;
        }
      }
    }

    .info_view {
      display: flex;
      justify-content: space-between;
      margin-right: 24px;

      &__content {
        padding-left: 24px;
        display: flex;
        padding-bottom: 24px;
        flex: 1;
        font-size: 18px;
        .left20 {
          margin-left: 20px;
        }

        .left40 {
          margin-left: 40px;
        }

        .left10 {
          margin-left: 10px;
        }

        .left100 {
          margin-left: 100px;
        }
      }
      &__right {
        .ant-row {
          .ant-col {
            font-size: 18px !important;
          }
        }
      }
    }
  }

  .bottom {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 16px;
    display: flex;
    overflow: auto;

    .left {
      width: 516px;
      border-right: 1px solid #3276e8;

      #unSettledRecordList {
        .columnWidth100 {
          width: 100px;
        }

        .columnWidth150 {
          width: 150px;
        }
      }
    }

    .right {
      flex: 1;
      width: 0;
      padding: 24px;
      padding-bottom: 0;
    }
  }

  .table-title {
    background: #fff !important;
  }

  .ele_hidden {
    display: none;
  }

  .haeder_title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}

.table-miaoshu {
  background-color: rgba(255, 255, 255, 0.5);
  height: 52px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // border-bottom: 1px solid pink;
}

.table-content-miaoshu {
  // height: 800px;
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  overflow: auto;

  // padding-bottom: 200px;
  // margin-top: 20px;
  .ant-table-thead {
    visibility: collapse;
  }

  .ant-table-tbody {
    background-color: #f0f8ff !important;
  }
}

.ant-table-tbody {
  background-color: #f0f8ff !important;
}

.custom_row_class {
  padding: 12px;

  .custom_row_class-image {
    width: 24px;
    height: 24px;
  }
}

.table-content-miaoshu-layout {
  height: 650px;
  overflow: auto;
  // flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pay-confim-modal-wrap {
  .ant-modal {
    width: 1000px;
  }
}

@root-entry-name: default;