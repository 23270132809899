@import '~@/style/vars.less';

.@{layout}-index {
  height: 100%;
  display: flex;
  flex-direction: column;

  .@{layout}-index-sider {
    background: linear-gradient(180deg, #010e33 0%, #005184 100%);
    box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.05);
    // z-index: 0;
  }

  .@{layout}-index-sider-trigger {
    width: 44px;
    height: 44px;
    background-color: @primary-color;
    border-radius: @border-radius;
    margin-left: calc(~'50% - 22px');
    color: #fff;
  }

  .@{layout}-index-header {
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #d9d9d9;
    box-shadow: 1px 1px 5px #d9d9d9;
    padding: 0 24px 0 44px;
    position: relative;

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #3276e8 linear-gradient(90deg, #010e33 0%, #005184 100%);
      opacity: 0.9;
    }

    .@{layout}-index-header-menu {
      overflow-y: hidden;
      overflow-x: auto;
      flex: 1;
      margin-right: 64px;
      user-select: none;
    }

    .ant-menu-horizontal {
      background-color: transparent;
      border-bottom: 0;

      .ant-menu-item,
      .ant-menu-submenu {
        padding: 0 !important;
        margin: 0 24px;
        color: @white;
        font-size: @font-size-18;

        &::after {
          left: 0;
          right: 0;
        }

        &:hover {
          color: @white;

          &::after {
            border-width: 4px;
            border-color: @white;
          }
        }
      }

      .ant-menu-submenu-title {
        &:hover {
          color: @white;
        }
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-selected {
        &::after {
          border-width: 4px;
          border-color: @white;
        }
      }
    }

    .search {
      width: 346px;
      max-width: 346px;
      min-width: 346px;
      flex: 0;

      .ant-input-affix-wrapper {
        background-color: rgba(255, 255, 255, 0.15);
        border: 0;
        border-radius: @border-radius;

        .ant-input {
          background-color: transparent;
          color: @white;

          &::placeholder {
            color: @white;
          }
        }
      }

      .anticon {
        color: @white;
      }
    }
  }

  .@{layout}-index-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .@{layout}-index-main {
    flex: 1;
    padding: @padding;
    overflow: hidden;
    background: linear-gradient(180deg, #add6f77f 0%, #dce1e47f 100%);
    display: flex;
    position: relative;

    .filter-1 {
      width: 402px;
      height: 402px;
      background: #44c8fa;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.2;
      filter: blur(150px);
      position: absolute;
      left: -68px;
      top: 126px;
      z-index: 1;
    }

    .filter-2 {
      width: 402px;
      height: 402px;
      background: #a2c2fb;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.2;
      filter: blur(150px);
      position: absolute;
      top: -68px;
      left: 1000px;
      z-index: 1;
    }

    .@{layout}-index-main-container {
      position: relative;
      z-index: 2;
      overflow: hidden;
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
}

.ellipsis {
  /* 强制文本在一行内显示 */
  white-space: nowrap;
  /* 隐藏溢出的文本 */
  overflow: hidden;
  /* 在文本溢出时显示省略号 */
  text-overflow: ellipsis;
  /* （可选）设置一个宽度，使文本在到达该宽度时开始溢出 */
  /*width: 200px;  你可以根据需要调整这个值 */
}
.common_table {
  .ant-table-header {
    width: calc(10px + 100%) !important;
  }
  .ant-table {
    background: rgba(255, 255, 255, 0.6) !important;
  }
  .ant-table-thead {
    tr > th {
      text-align: center;
      background: rgba(229, 242, 255, 1);
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    tr {
      text-align: center;
      td {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .number_input {
    .ant-input-number-affix-wrapper {
      padding: 0 !important;
    }
    .ant-input-number-input {
      text-align: center !important;
    }
  }
}

#CommonTable {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .title-container {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn {
      display: inline-block;
    }
  }
  .title {
    display: inline-block;
    margin: 0 !important;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    left: 6px;
    padding-left: 6px;
    line-height: 32px;
    &::before {
      position: absolute;
      top: 50%;
      left: -6px;
      transform: translateY(-50%);
      content: '';
      width: 6px;
      height: 100%;
      background: rgba(50, 118, 232, 1);
      display: inline-block;
    }
  }
}
.common_lfc_style {
  // label间距
  .iJXiwC {
    flex-basis: 6.6rem !important;
  }
  @media screen and (max-width: 1440px) {
    .aspirin-table-search {
      .common_width5rem() {
        .ant-form-item-label {
          width: 5rem !important;
        }
        .ant-form-item-control {
          width: calc(100% - 5rem) !important;
        }
      }
      .common_width10rem() {
        .ant-form-item-label {
          width: 10rem !important;
        }
        .ant-form-item-control {
          width: calc(100% - 10rem) !important;
        }
      }
      .common_ant-col-last-child() {
        &:last-child {
          .ant-form-item-label {
            display: none;
          }
        }
      }
      .common_ant-col(@width:25%) {
        display: block !important;
        flex: 0 0 @width !important;
        max-width: @width !important;
        .ant-form-item-row {
          flex-direction: row !important;
          align-items: center !important;
          .common_width5rem();
        }
      }
      // 药品规格管理
      .drugInventoryManage-specification {
        .ant-col-12 {
          .common_ant-col();
          .common_ant-col-last-child();
          &:nth-child(2) {
            .common_width10rem();
          }
        }
      }
      // 供应标识维护
      .vSPromiseTable739611,
      .vSDrugStorageProfile {
        .ant-col-8 {
          .common_ant-col();
          .common_ant-col-last-child();
        }
      }
      // 药品库存定义
      // .vSDrugStorageProfile {
      //   .ant-col-8 {
      //     .common_ant-col();
      //     .common_ant-col-last-child();
      //   }
      // }
    }
  }
}

@root-entry-name: default;