@import '~@/style/vars.less';

.aspirin-history-tab {
  height: 100%;
  .ant-tabs-nav-wrap {
    padding-left: 24px;
    background-color: #fff;
    border-bottom: 1px solid #3276e8;
  }

  .ant-tabs-content .ant-tabs-tabpane {
    height: 100%;
    padding-top: 24px !important;
  }
  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
}
.detail-block {
  height: 100%;
  overflow-y: auto;
}

.overview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  th.ant-table-cell {
    background-color: #dcedff;
  }
  > div {
    width: 100%;
    flex: 1;
    height: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // border-bottom: 1px solid #d6d8d9;
    height: 328px;
    min-height: 328px;
    max-height: 328px;

    + div {
      margin-top: 24px;
    }
  }

  .test-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 8px;
    // border: 1px solid red;
    .item {
      border: 1px solid #ff7e7e;
      padding: 10px 12px;
      border-radius: 4px;
      // flex-basis: 30%;
      flex-basis: calc(~'33.33% - 12px');
      // margin: 0 6px;
      margin-right: 12px;
      margin-bottom: 12px;
      overflow: hidden; // 溢出的内容隐藏
      // text-overflow: ellipsis; // 溢出的部分用省略号...显示
      // -webkit-line-clamp: 2; // 行数
      // display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
      // -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
    }
    .item_reportItemName {
      overflow: hidden; // 溢出的内容隐藏
      text-overflow: ellipsis; // 溢出的部分用省略号...显示
      -webkit-line-clamp: 1; // 行数
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
      -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
    }
  }

  .exam-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .item {
      // flex-basis: calc(~'33.33% - 12px');
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      margin: 0 6px;
      margin-bottom: 12px;
      width: 31%;
      flex-shrink: 0;
      .type {
        padding: 0 5px;
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        background-color: #f1f6ff;
        border-radius: @border-radius;
        color: @primary-color;
        border: 1px solid @primary-color;
        white-space: nowrap; //不支持换行
        overflow: hidden; //隐藏多出部分文字
        text-overflow: ellipsis; //用省略号代替多出部分文字
      }

      .detail {
        // max-height: 118px;
        font-size: @font-size-12;
        color: @main-color;
        padding: 0 14px;
        // flex: 1;
        // height: 0;
        // overflow-y: scroll;
        > div {
          margin-top: 4px;
        }
      }
    }
  }

  .exam-block-alone {
    .item {
      width: 100%;
      flex-basis: 100%;
      margin: 0 6px;
      margin-bottom: 12px;

      .type {
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        background-color: #f1f6ff;
        border-radius: @border-radius;
        color: @primary-color;
        border: 1px solid @primary-color;
      }

      .detail {
        font-size: @font-size-12;
        color: @main-color;
        padding: 0 14px;

        > div {
          margin-top: 4px;
        }
      }
    }
  }

  .warning-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 12px;
    .item {
      // flex: 1;
      color: #ff7e7e;
      // margin: 0 6px;
      display: flex;
      > div {
        flex: 1;
        flex-shrink: 0;
      }
    }
  }

  .other-block {
    overflow-y: auto;
    height: 100%;
    margin-right: 4px;
    overflow: hidden;
    &_text {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    > div {
      height: auto !important;
    }
  }
}
.aspirin_history_tabel_bg_style {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // flex: 1;
  // width: 100%;#D6D8D9
  .ant-table-body {
    //   &::-webkit-scrollbar {
    //     width: 0 !important;
    //     height: 0 !important;
    //   }
  }
  &_promise_table {
    .ant-table-thead .ant-table-cell {
      border: none;
      border-bottom: 1px solid #d6d8d9;
      background-color: #e5f2ff;
      font-size: 14px;
      font-weight: 600;
      &::before {
        background-color: transparent !important;
      }
    }

    td.ant-table-cell {
      border: 1px solid #d6d8d9;
      border-top: none;
      border-right: none;
      background-color: transparent;
    }
    td.ant-table-cell:last-child {
      border-right: 1px solid #d6d8d9;
      // border: 1px solid red;
      // border: none;
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none !important;
      }
    }

    .ant-pro-card {
      background-color: transparent;
    }

    .ant-table {
      background-color: transparent !important;
    }
  }
}

@root-entry-name: default;