.nurse_station_out_hospital {
  display: flex;
  height: 100%;
  width: 100%;
  &_table {
    padding: 20px 24px 24px;
    margin-right: 16px;
    width: 494px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    &_option {
      // text-align: center;
      margin: 16px 0;
    }
  }
  &_detail {
    padding-top: 24px;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    &_search {
      margin-bottom: 16px;
      padding: 0 24px;
      width: 100%;
      height: 60px;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      &_item {
        margin-right: 56px;
        display: flex;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        &_label {
          width: 80px;
          font-size: 16px;
          font-weight: 400;
          color: #343b42;
        }
        &_input {
          flex-wrap: nowrap;
        }
      }
    }
    &_content {
      padding: 0 24px 24px;
      height: 0;
      flex: 1;
      // background: rgba(255, 255, 255, 0.5);
      // opacity: 1;
      // border-radius: 4px;
      overflow-y: scroll;
      &_form {
        &_outhosipital_check {
          .ant-form-item-control-input {
            width: 90px !important;
          }
        }

        &_title {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 5px;
          &_tag {
            margin-top: 2px;
            margin-right: 12px;
            width: 4px;
            height: 20px;
            background-color: #3276e8;
          }
        }
      }
    }
    &_bottom {
      padding: 0 24px;
      display: flex;
      justify-content: flex-end;
      height: 74px;
      align-items: center;
      // background: rgba(255, 255, 255, 0.5);
      // opacity: 1;
    }
  }
  &_modal_title {
    font-size: 20px;
    font-weight: 500;
    color: #3276e8;
  }
}

@root-entry-name: default;