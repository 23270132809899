.checked{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    &_main{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        overflow: hidden;
    }
}
@root-entry-name: default;