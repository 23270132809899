.hospitalization-settlement-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .patient-information {
    background: rgba(255, 255, 255, 0.5);
  }

  .tag-subject {
    // flex: 1;
    height: 78%;

    .tab-icon-container {
      .anticon {
        font-size: 30px;
      }
    }
  }

  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon,
          .ant-table-row-selected
        ) {
        background: transparent !important;
      }
    }
  }

  .ant-table-cell-fix-right-first {
    background: transparent !important;
  }

  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }

  .antd-table-row-selected {
    background: #5494ff !important;
  }
}

.inp-expense-form {
  padding: 24px;
  display: flex;

  .inp-expense-form-left {
    flex: 3;
    .ant-row {
      .ant-col {
        font-size: 18px;
      }
    }
    #inpExpenseForm {
      .patientIdIs {
        padding-left: 10px;

        .patientIdIs-select {
          width: 150px;
        }
      }

      .suffix-cardImg-image {
        width: 20px;
        height: 20px;
      }
    }
  }

  .inp-expense-form-right {
    padding-left: 24px;
    flex: 1;
    display: flex;
    align-items: center;
    .ant-row {
      .ant-col {
        font-size: 18px;
      }
    }
  }
}

//费用
.hospitalization-charge {
  width: 100%;
  height: 100%;
  display: flex;

  .charge-left {
    // flex: 1;
    padding-right: 24px;
    border-right: 1px solid #3276e8;
    height: 100%;
    width: 20%;
  }

  .charge-right {
    width: 80%;
    // flex: 4;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    #inpSettleBillingDetailListTable {
      height: calc(100% - 40px);
      width: 100%;
    }
  }
}

//病员首页
.hos-settlement-home {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 210px;
  padding-right: 210px;

  & > div {
    flex: 1;
  }

  // .ant-col-6{
  //   height: 30px !important;
  // }
  label {
    // color: #3276e8 !important;
  }
}

@root-entry-name: default;