.inputField {
  border: none;
  border-bottom: 1px solid;
  background: none;
  line-height: normal;
  min-width: 50px;
  outline: none;
  &:focus {
    outline: none;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  .operationField {
    width: 60px;
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
  }
  &:hover {
    .operationField {
      width: 60px;
      visibility: visible;
      display: flex;
      justify-content: flex-end;
    }
  }
}

:global {
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: unset;
  }
}
.maintenance_complaint {
  &_tree {
    // padding: 24px;
  }
  &_content {
    // padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
      margin-bottom: 8px;
      display: flex;
      font-size: 16px;
      font-weight: bolder;
      align-items: center;
      &_left,
      &_right {
        height: 76px;
        flex: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        .custom_btn {
          margin-left: 30px;
          height: 32px;
          line-height: 12px;
        }
      }
    }
    .bottom {
      // padding: 0 24px 24px;
      display: flex;
      height: 100%;
      .left,
      .right {
        padding: 0 24px 24px;
        flex: 1;
        flex-shrink: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        opacity: 1;
        // .hide {
        //   display: none;
        // }
      }
      .left {
        width: 0;
      }
      .right {
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        .table {
          flex: 1;
          height: 0;
        }
        .import {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

@root-entry-name: default;