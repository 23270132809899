.aspirin-department-signature {
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .signature-operation {
    // border: 1px solid red;
    border-top: 1px solid #d6d8d9;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 24px;
    // background: rgba(255, 255, 255, 0.5);
    // opacity: 1;
    > button {
      margin-left: 16px;
    }
  }
}

@root-entry-name: default;