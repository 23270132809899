.components_user_info {
  width: 100%;
  height: 100%;
  .oper-in,
  .oper {
    display: none !important;
  }
  .hospital-info {
    > .info-item {
      margin: 0 !important;
      &:nth-child(6) {
        display: none !important;
      }
    }
  }
  .aspirin-header-user-info {
    margin: 0 !important;
  }
}

@root-entry-name: default;