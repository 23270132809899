.aspirin-business-application-parameter {
  .group-title {
    font-size: 20px;
    padding: 12px 0;
  }

  .ant-form-item-control-input-content {
    max-width: 400px;
  }
}

.aspirin-business-application-parameter-block {
  padding: 0 !important;
}

@root-entry-name: default;