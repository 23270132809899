.pain_level_modal {
  &_slider {
    .ant-slider-step {
      .ant-slider-dot {
        // border: 2px solid #005fff !important;
      }
      .ant-slider-dot.ant-slider-dot-active {
        border: 2px solid #005fff !important;
      }
    }
    .ant-slider-handle {
      border: 2px solid #005fff !important;
    }
  }
}
.fall_risk {
  &_form_label {
    font-size: 14px;
    font-weight: bolder;
  }
}
// 主诉及判定依据
.action_cheif {
  display: flex;
  height: 100%;
  overflow: hidden;
  &_cascader {
    flex-shrink: 0;
    width: 773px;
    height: 100%;
    border-right: 1px solid #d6d8d9;
    display: flex;
    flex-direction: column;
    &_content {
      flex: 1;
      height: 100%;
      display: flex;
      font-size: 14px;
      overflow: hidden;
      color: #343b42;
      .level {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-shrink: 0;
        overflow-y: scroll !important;
      }
      .level1 {
        width: 200px;
        background-color: #fff;
      }
      .level2 {
        width: 260px;
        border-right: 1px solid #d6d8d9;
        background-color: #f9fafc;
      }
      .level3 {
        width: 312px;
        background-color: #f9fafc;
        &_right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 100%;
          width: 40px;
        }
        .checkbox_group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 48px;
          color: #343b42;
        }
      }
      // .empty {
      //   text-align: center;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
      .empty {
        width: 200px;
        height: 200px;
      }
      .item {
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        height: 48px;
        color: #343b42;
        cursor: pointer;
        .text {
          margin-right: 5px;
          display: flex;
          width: 0px;
          flex: 1;
        }
        .icon {
          // padding: 0 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 20px;
          border-radius: 10px;
        }
        .icon1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }
      }
      .active_item {
        background-color: #ebf4ff;
        color: #3276e8;
      }
    }
  }
  &_list {
    flex-shrink: 0;
    width: 428px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &_content {
      flex: 1;
      display: flex;
      flex-direction: column;

      overflow: hidden;
      padding: 16px 42px;
      .item {
        padding: 0 12px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        color: #3276e8;
        font-weight: 500;
        background: #e6f7ff;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(105, 161, 255, 0.5);
        &_left {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .text {
            flex: 1;
            width: 0;
            flex-shrink: 0;
          }
          .level {
            margin-left: 10px;
            width: 40px;
            flex-shrink: 0;
            text-align: right;
          }
        }
        &_right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 25px;
        }
      }
    }
  }
}
.second_triage_modal {
  display: flex;
  flex-direction: column;
  .ant-empty.ant-empty-normal {
    height: 150px;
  }
}

@root-entry-name: default;