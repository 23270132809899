.aspirin-block {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .left {
      display: flex;
      align-items: center;

      .aspirin-block-desc {
        margin-left: 12px;
      }
    }
  }

  .aspirin-block-title {
    font-weight: 500;
    font-size: 20px;
    color: #343b42;
    white-space: nowrap;
    position: relative;
    text-indent: 30px;
    left: -10px;

    &::before {
      content: '';
      width: 4px;
      height: 70%;
      background-color: #3276e8;
      position: absolute;
      left: 12px;
    }
  }


  .aspirin-block-main {
    flex: 1;
    height: 0;
  }

  .aspirin-block-footer {
    flex: 0;
  }
}

@root-entry-name: default;