.doctor_station_out_hospital {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  // background: rgba(255, 255, 255, 0.5);
  // opacity: 1;
  // &_margin {
  //   margin-right: 12px;
  //   cursor: pointer;
  // }
  // &_content {
  //   width: 100%;
  //   height: 0;
  //   flex: 1;
  //   display: flex;
  //   &_form {
  //     width: 480px;
  //     border-right: 1px solid #3276e8;
  //     padding: 16px;
  //     &_option {
  //       text-align: center;
  //       margin-bottom: 16px;
  //     }
  //   }
  //   &_table {
  //     width: 0;
  //     flex: 1;
  //     padding: 16px 24px 16px 16px;
  //     display: flex;
  //     flex-direction: column;
  //     &_bottom {
  //       opacity: 1;
  //       font-size: 16px;
  //       font-weight: 400;
  //       text-align: center;
  //       display: flex;
  //       justify-content: flex-end;
  //       &_delete {
  //         width: 80px;
  //         height: 32px;
  //         line-height: 32px;
  //         border-radius: 2px 2px 2px 2px;
  //         opacity: 1;
  //         border: 1px solid #3276e8;
  //         cursor: pointer;
  //         color: #3276e8;
  //       }
  //       &_save {
  //         cursor: pointer;
  //         margin-left: 16px;
  //         width: 80px;
  //         height: 32px;
  //         line-height: 32px;
  //         background: #3276e8;
  //         border-radius: 2px 2px 2px 2px;
  //         color: #ffffff;
  //       }
  //     }
  //   }
  // }
  &_content {
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    // height: 0;
    // flex: 1;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    &_title {
      margin-bottom: 5px;
      display: flex;
      color: #343b42;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      > span {
        align-self: start;
        flex-shrink: 0;
      }
      &_steps {
        width: 516px;
        flex-shrink: 0;
      }
      &_tag {
        align-self: start;
        flex-shrink: 0;
        margin-top: 6px;
        margin-right: 12px;
        width: 4px;
        height: 20px;
        background-color: #3276e8;
      }
    }
    &_form {
      flex: 1;
      width: 1240px;
      margin: 0 auto;
      // overflow-y: scroll;
      // overflow-x: hidden;

      &_textarea {
        position: relative;
        &_icon {
          text-align: center;
          line-height: 35px;
          width: 35px;
          height: 35px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 100;
          cursor: pointer;
        }
      }
      &_vsform {
        // border: 1px solid black;
        .ant-form-item-row {
          .ant-form-item-label {
            width: 140px !important;
          }
          // }
        }
      }
    }
  }
  &_bottom {
    border-radius: 4px;
    padding: 0 16px;
    height: 64px;
    line-height: 64px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    // display: flex;
    // justify-content: flex-end;
    height: 0;
    flex: 1;
    &_delete {
      margin: auto 0;
      width: 80px;
      height: 32px;
      // line-height: 32px;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #3276e8;
      cursor: pointer;
      color: #3276e8;
    }
    &_save {
      cursor: pointer;
      margin: auto 0;
      margin-left: 16px;
      width: 80px;
      // height: 32px;
      // line-height: 32px;
      background: #3276e8;
      border-radius: 2px 2px 2px 2px;
      color: #ffffff;
    }
  }
  &_modal_item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    &_label {
      width: 120px;
      font-size: 16px;
      color: #343b42;
    }
    &_content {
      display: flex;
      align-items: center;
    }
  }
  &_stopOrderModal {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    font-size: 15px;
    color: #343b42;
    &_item1 {
      width: 40px;
      font-weight: 400;
      color: #e34a4e;
    }
    &_item2 {
      padding-left: 18px;
      flex: 1;
    }
    &_item3 {
      padding-left: 18px;
      width: 80px;
    }
    &_item4 {
      padding-left: 18px;
      width: 100px;
    }
    &_item5 {
      padding-left: 18px;
      width: 80px;
    }
    &_time {
      display: flex;
      align-items: center;
      &_label {
        width: 100px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@root-entry-name: default;