@import '~@/style/vars.less';

.@{layout} {
  // height: 100vh;
  // height: 60.9166666666666rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  // .@{layout}-sider {
  //   background: #fff;
  //   box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.05);
  // }

  .@{layout}-header {
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    background: #3276e8 linear-gradient(90deg, #010e33 0%, #005184 100%);
    flex: 0;
  }

  .@{layout}-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .@{layout}-main {
    flex: 1;
    // padding: @padding;
    overflow: hidden;
    // background: linear-gradient(180deg, #add6f7 0%, #dce1e4 100%);
  }
}

@root-entry-name: default;