.aspirin-drawer-stop-order {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.aspirin-drawer-stop-order1700 {
  .ant-drawer-content-wrapper {
    width: 1700px !important;
    // z-index: 10001 !important;
  }
}
.aspirin-drawer-stop-order-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .aspirin-drawer-stop-order-main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .header {
      flex: 0;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 24px;

      > .label {
        margin-right: 28px;
      }
    }

    .main {
      flex: 1;
      padding: 24px;
      background-color: #f4f5f7;
    }
  }

  .aspirin-drawer-stop-order-footer {
    width: 100%;
    flex: 0;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d6d8d9;
    padding: 0 24px;

    .footer-btn {
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      + .footer-btn {
        margin-left: 16px;
      }
    }
  }
}

@root-entry-name: default;