.drag-drop-box {
  display: grid;
  gap: 15px;
  width: auto;

  .drag-drop-grid {
    display: flex;
    height: 30px;
    align-items: center;
    background-color: #e9f0fe;
    border: 1px solid #e7ebef;
    border-radius: 2px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 5px;
    transition: all .2s ease-in-out;
    > div{
      width: 100%;
    }
    &:hover {
      border: 1px solid #9ab5e4;
    }
  }
}
@root-entry-name: default;