.patient_identification_wapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  >div {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-bottom: 16px;


    .ant-select-selector {
      width: 120px !important;
      border: none !important;
    }

    .identification_number {
      .ant-input {
        width: 240px;
        height: 36px;
        background: #FFFFFF;
        box-shadow: 0px 0px 0px 2px rgba(64, 158, 255, 0);
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #E7E7E7;
      }
    }

    >img {
      width: 22px;
      height: 22px;
      margin-left: 16px;

      cursor: pointer;
    }
  }
}
@root-entry-name: default;