.aspirin-slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > .ant-slider {
    min-width: 100px;
  }
}

@root-entry-name: default;