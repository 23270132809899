.register_information {
  width: 100%;
  display: flex;
  flex-direction: column;
  &_search {
    margin-bottom: 16px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 68px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    &_input {
      display: flex;
      &_item {
        margin-right: 56px;
        display: flex;
        align-items: center;
        &_addonAfter {
          display: flex;
          align-items: center;
          // border-radius: 0px 2px 2px 0px;
          // opacity: 1;
          &_text {
            margin-left: 8px;
          }
        }
        .ant-input-group > .ant-input:last-child,
        .ant-input-group-addon:last-child {
          color: #3276e8;
          border: 1px solid #3276e8;
          background: #ebf2ff;
          cursor: pointer;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          line-height: 36px;
        }
        .ant-input-group > .ant-input:first-child,
        .ant-input-group-addon:first-child {
          height: 36px;
        }
        // 下拉选择高度
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 36px;
        }
        &_label {
          width: 120px;
        }
      }
      align-items: center;
    }
  }
  &_form {
    width: 100%;
    // padding: 24px;
    // padding-left: 0;
    height: 0;
    flex: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    display: flex;
    flex-direction: column;
    &_content {
      flex: 1;
      height: 0;
      &_divider_style {
        margin-bottom: 25px;
        height: 1px;
        border-top: 1px solid #d6d8d9;
      }
    }
    &_bottom {
      padding: 0 24px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &_btn {
        margin-left: 16px;
        height: 32px;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
        line-height: 32px;
        cursor: pointer;
      }
      &_btn1 {
        width: 80px;
        color: #3276e8;
        border: 1px solid #3276e8;
      }
      &_btn2 {
        width: 96px;
        color: #ffffff;
        background: #3276e8;
      }
    }
  }
  &_table {
    .ant-table-thead .ant-table-cell {
      background-color: #f5f7fa;
      &::before {
        background-color: transparent !important;
      }
    }
  }
}
.register_information_my_modal {
  font-size: 20px;
  font-weight: 500;
  color: #3276e8;
}

@root-entry-name: default;