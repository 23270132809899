.my_loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #343b42;
  &_line {
    width: 200px;
    height: 0px;
    opacity: 1;
    border: 1px solid #3276e8;
    margin-bottom: 12px;
  }
}

@root-entry-name: default;