.cpoe-department-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  background-color: #fff;

  .cpoe-department-price_main {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    height: 0;
    overflow: hidden;
    .ant-table-thead .ant-table-cell {
      background-color: #f5f7fb;
      &::before {
        background-color: transparent !important;
      }
    }
    // padding: 0 24px;
    .allPrice-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &_table {
        // flex-shrink: 0;
        height: 100%;
        padding: 24px;
        width: 100%;
        flex: 1;
      }
    }
    .orderPrice-main {
      width: 100%;
      height: 100%;
      display: flex;
      background-color: #fff;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
      &_list {
        flex-shrink: 0;
        padding: 24px;
        // margin-bottom: 20px;
        width: 100%;
        flex: 1;
        height: 100%;
      }
      &_detail {
        flex-shrink: 0;
        padding: 24px;
        width: 100%;
        flex: 1;
        height: 100%;
        &_add_price {
          // width: 88px;
          margin-right: 5px;
          padding-right: 7px;
          height: 24px;
          background: #3276e8;
          border-radius: 2px 2px 2px 2px;
          text-align: center;
          line-height: 24px;
          color: #fff;
          cursor: pointer;
          > span {
            margin-left: 4px;
          }
        }
        &_tabletoolbar {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          &_div {
            width: 5px;
            height: 20px;
            background-color: #3276e8;
            margin-right: 16px;
          }
          &_addBtn {
            background-color: transparent;
            color: #3276e8;
          }
        }
        // background-color: #fafafa;
      }
    }
  }
  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;
    .cancel {
      border: 1px solid #3276e8;
      color: #3276e8;
    }
    > Button {
      margin-left: 10px;
    }
  }
}
// ant-menu ant-menu-sub ant-menu-inline
.department_price_menu {
  .ant-menu-inline {
    background-color: #fff !important;
    .ant-menu-item {
      padding-left: 20px !important;
      background-color: #fff !important;
      .ant-menu-title-content {
        padding-left: 20px;
        background-color: #fff !important;
      }
    }
  }
  .ant-menu-inline {
    // background-color: #ebf2ff !important;
    // .ant-menu-item-selected {
    //   background-color: #ebf2ff !important;
    // }
    .ant-menu-item.ant-menu-item-selected {
      // background-color: #ebf2ff !important;
      padding-left: 20px !important;
      .ant-menu-title-content {
        padding-left: 20px;
        background-color: #ebf2ff !important;
      }
    }
  }
}
.charge_template_table {
}

.aspirin-department-price1700 {
  .ant-drawer-content-wrapper {
    width: 1700px !important;
    // z-index: 10001 !important;
  }
}

@root-entry-name: default;