.collect_classify {
  width: 466px;
  height: 258px;
  // border: 1px solid red;
  background-color: #fff;
  box-shadow: 0px 0px 18px 5px #e6e5e5;
  display: flex;
  flex-direction: column;
  &_header {
    padding: 0 24px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    &_title {
      font-size: 18px;
      font-weight: 600;
      color: #343b42;
    }
  }
  &_main {
    padding: 10px 24px;
    height: 0;
    flex: 1;
    overflow-y: scroll;
    &_list {
      width: 100%;
      height: 46px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_active {
        background-color: #e6eefe;
      }
    }
  }
  &_footer {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    &_space {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid #e7e7e7;
    }
    &_setting {
      justify-content: space-between;
    }
    &_text {
      justify-content: space-between;
      font-size: 16px;
      font-weight: 400;
      color: #3276e8;
      // cursor: pointer;
    }
    &_add {
      justify-content: space-between;
      &_btn {
        display: flex;
      }
      &_cancel {
        margin: 0 16px;
      }
    }
  }
}

@root-entry-name: default;