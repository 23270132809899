.patient_update_log_table {
  .ant-table-wrapper {
    padding: 0;
  }
}
.vSPromiseTablePatientOperateLog {
  padding: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  // padding: 24px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@root-entry-name: default;