.dictionary_list {
  padding: 0 24px 24px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@root-entry-name: default;