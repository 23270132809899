@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1701151359269') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  margin-top: 0px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-you:before {
  content: "\e62e";
}

