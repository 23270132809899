@import '~@/style/vars.less';

@font-face {
  font-family: 'DINPro';
  src: url('../assets/fonts/DINPro.ttf') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url('../assets/fonts/DINPro-Bold.ttf') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url('../assets/fonts/DINPro-Medium.woff') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

body {
  color: @main-color;
}

.dinpro {
  font-family: 'DINPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.dinpro-bold {
  font-family: 'DINPro-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.dinpro-medium {
  font-family: 'DINPro-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.noscroll,
.hideScroll {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

.vs-page {
  padding: 0 !important;
}

.aspirin-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > button,
  > div {
    margin-left: 24px;
  }
}

.aspirin-btn {
  color: @main-color !important;
  padding: 6px 24px !important;
  height: 36px !important;
}

.aspirin-btn-primary {
  color: @white !important;
  background-color: @primary-color !important;
  padding: 6px 24px !important;
  height: 36px !important;
}

.vs-base-table-container {
  height: 100%;
  position: relative;

  > .ant-pro-table {
    width: 100%;
  }
}

.aspirin-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }

  > .ant-pro-table-search {
    padding: 16px 0 !important;

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  > .ant-pro-table-list-toolbar {
    // background-color: #fff;
    // 原始就不加内边距勿动
    padding: 0;
  }

  > .ant-table-wrapper {
    flex: 1;
    height: 0;
    // background-color: #fff;
    padding: 0 24px 24px 24px;
    border-radius: 4px;

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
    }

    .ant-table {
      width: 100%;
    }

    .ant-pagination {
      width: 100%;
      margin: 16px 0 0 0 !important;
    }
  }

  .ant-pro-table-search {
    margin-bottom: 0 !important;
    flex: 0;
    height: auto;

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .ant-pro-table-list-toolbar.ant-pro-table-list-toolbar-padding {
    padding: 0 24px;
  }

  .ant-pro-table-list-toolbar {
    .ant-pro-table-list-toolbar-title {
      position: relative;
      text-indent: 12px;
      font-weight: @font-weight-bold;
      font-size: @font-size-20;

      &::before {
        position: absolute;
        content: '';
        background-color: @primary-color;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .vs-table-addtion {
      font-size: @font-size-14;
    }
  }
}

.aspirin-table-autoheight {
  height: auto !important;
}

.aspirin-table-nopadding {
  > .ant-table-wrapper {
    padding: 0 !important;
  }

  > .ant-pro-table-list-toolbar {
    padding: 0 !important;
  }
}

.aspirin-table-transparent {
  background-color: transparent;

  > .ant-pro-card,
  .ant-table,
  .ant-table-cell,
  .ant-table-cell-row-hover {
    background-color: transparent !important;
  }
  .ant-table-placeholder {
    .ant-table-cell {
      // border: 1px solid #aeb0b3;
      // border-top: none;
    }
  }
  .ant-table-cell {
    border-color: #aeb0b3;
  }
}

.aspirin-child-table {
  &:only-child {
    .ant-table {
      padding-left: 36px;
      tr:last-child {
        .ant-table-cell {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
  }
}

.aspirin-child-table-small-size {
  &:only-child {
    .ant-table {
      padding-left: 48px;
      tr:last-child {
        .ant-table-cell {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
  }
}

#departmentTableRowExpandable {
  border-bottom: none;
}
.aspirin-linkpage {
  .ant-modal-title {
    color: @primary-color;
  }

  .ant-modal-footer {
    .ant-btn-text {
      background-color: #f0f0f0;
    }
  }
}

.aspirin-linkpage-static-height {
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
    }

    .ant-modal-body {
      flex: 1;
      height: 0;
    }
  }
}

.aspirin-tab-privilege {
  .ant-tabs-nav {
    padding: 0 24px !important;
  }
}

.aspirin-tab {
  height: 100%;

  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
}

.aspirin-transfer {
  height: calc(~'100vh - 430px') !important;

  .ant-transfer-list-body {
    overflow-y: auto;
  }
  .ant-transfer-list-header {
    display: none !important;
  }
}
// .aspirin-transfer {
//   .aspirin-transfer-height {
//     height: calc(~'100vh - 480px') !important;
//   }
// }
.aspirin-block-title {
  font-size: @font-size-20;
  font-weight: @font-weight-bold;
  text-indent: 12px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: var(--primary-color);
    width: 4px;
    height: 70%;
    top: 13%;
    left: 0;
  }
}

.aspirin-block-title-no {
  .aspirin-block-title();

  &::before {
    position: absolute;
    content: '';
    background-color: var(--primary-color);
    width: 4px;
    height: 70%;
    top: 13%;
    left: 0;
  }
}
// .aspirin-table-search {
//   position: relative;

//   > div.ant-pro-table-search {
//     padding: 30px 24px;

//     > form {
//       > .ant-row-start {
//         position: relative;
//         bottom: -50px;

//         > .ant-col {
//           .ant-form-item-label {
//             display: flex;

//             > label {
//               &::after {
//                 content: '';
//               }
//             }
//           }

//           &:last-child {
//             position: relative;
//             right: 0;
//             top: -132px;
//             display: flex;
//             max-width: 100%;
//             margin: 0;
//             flex: 1;
//             // padding: 0 !important;
//             padding-left: 0 !important;

//  > div {
//       width: 100%;
//             }

//             .ant-space,
//             .ant-space-item {
//               width: 100%;
//             }

//             .ant-pro-table-search-item-box {
//               display: flex;
//               justify-content: space-between;
//               align-items: center;
//               width: 100%;
//             }

//             .ant-pro-table-search-button-box {
//               position: relative;
//               right: 0;
//               display: flex;
//               gap: 10px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.aspirin-list {
  width: 100%;

  .aspirin-list-item {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    color: @main-color;
    font-size: @font-size-18;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .aspirin-list-item-active {
    background-color: #f0f0f0;

    //table表头样式
    .aspirin-new-table {
      thead th {
        font-size: @font-size;
        font-weight: @font-weight-bold !important;
      }
    }
  }
}

.cdss-image-robot {
  width: 30px !important;
  cursor: pointer;
}

.aspirin-reproduction-form {
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }
}

//新增标准工作站的标题
.aspirin-standard-application {
}

// .aspirin-standard-application {}

.aspirin-tree-button {
  display: flex;
  gap: 10px;
}

.aspirin-table-footer-button {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.aspirin-new-role-editable-table {
  .aspirin-new-role-editable-table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.aspirin-tip-box {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 25px;
  height: 100%;
  .aspirin-tip-blue {
    color: @primary-color-1;
  }
}

.aspirin-tip-blue {
  color: @primary-color-1;
}

.aspirin-select-tag-box {
  .ant-tag {
    cursor: pointer;
  }
}

.aspirin-table-box {
  padding-top: 24px;
}

.aspirin-table-form-box {
  .ant-pro-table-search {
    padding: 24px !important;
  }
}

.aspirin-table-form-box-no {
  .ant-pro-table-search {
    padding: 0 !important;
  }
}

.aspirin-table-box-no {
  .ant-pro-card-body {
    padding: 0 !important;
  }

  .ant-pro-table-list-toolbar-container {
    padding-top: 0 !important;
  }

  .ant-pro-table-list-toolbar {
    .ant-pro-table-list-toolbar-title {
      position: relative;
      text-indent: 12px;
      font-weight: @font-weight-bold;
      font-size: @font-size-20;

      &::before {
        position: absolute;
        content: '';
        background-color: @primary-color;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .vs-table-addtion {
      font-size: @font-size-14;
    }
  }
}

.aspirin-transfer-title-box {
  display: flex;
  justify-content: center;
  font-size: @font-size;
  font-weight: bold;
}

.aspirin-transfer-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .ant-transfer-customize-list .ant-transfer-list {
    width: 0 !important;
  }

  .aspirin-transfer-button-box {
    display: flex;
    justify-content: flex-end;
  }

  .aspirin-transfer .ant-transfer-list-body {
    overflow: hidden;
  }
}

.aspirin-price-table-table {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: 10px;
}

.aspirin-drawer {
  .ant-drawer-header {
    padding: 0 14px;
    min-height: 47px;
  }

  .ant-drawer-title {
    width: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }
}
.aspirin-drawer-border-bottom {
  .ant-drawer-header {
    border: none;
    padding: 0 24px;
    margin-top: 16px;
  }
  .ant-drawer-close {
    display: none !important;
  }
  .ant-drawer-title {
    width: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }
  .ant-tabs > .ant-tabs-nav {
    margin: 0 !important;
  }
  // .ant-tabs-tab-active{
  //   background: var(--primary-color) !important;
  //   > div{
  //     color: #fff !important;
  //   }
  //   border-radius: 2px !important;
  // }
}
.aspirin-select-customize-item-box {
  display: flex;
  gap: 10px;
  // position: relative;

  .ant-form-item {
    flex: 1 50%;
  }

  .aspirin-select-customize-icon-box {
    display: flex;
    gap: 10px;
    height: 32px;
    align-items: center;

    > div {
      width: 18px;
      height: 18px;
      background: @primary-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: white;
      }
    }
  }
}

.aspirin-toolbar-title-box {
  font-size: @margin-3;

  .aspirin-toolbar-title-text-box {
    color: @primary-color;
    font-size: initial;
  }
}

.aspirin-table-search-padding-no {
  .aspirin-table-search > div.ant-pro-table-search {
    padding: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 20px;
    background: transparent;
  }

  .ant-table-wrapper {
    padding: 0;
  }

  .all-save {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-right {
    height: 100%;
    display: flex;

    justify-content: flex-end;
  }
}

.aspirin-table-search-margin-no {
  .aspirin-table-search > div.ant-pro-table-search {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.aspirin-table-list-toolbar-container {
  // background: transparent !important;

  .ant-pro-table-list-toolbar-container {
    padding: 24px;
  }
}

.aspirin-block-no-padding {
  padding: 0 !important;
}

.aspirin-table-search-inline-no {
  .aspirin-table-search-inline {
    > div.ant-pro-table-search {
      > form {
        > .ant-row-start {
          > .ant-col {
            &:last-child {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}

@keyframes DataTime {
  to {
    transform: scaleY(1) rotate(30deg);
  }
  from {
    transform: scaleY(1);
  }
}

.aspirin-date-box {
  &:hover {
    animation: DataTime 0.5s infinite;
  }
}

.aspirin-row-select-hover {
  tbody tr:hover {
    cursor: pointer !important;
  }
}

.aspirin-row-select-box:hover > td {
  .aspirin-row-selected-box ();
}

.aspirin-row-selected-box:hover > td {
  .aspirin-row-selected-box ();
}

// 文字禁止换行省略号针对vs-table
.aspirin-vs-table-row-box {
  overflow: hidden;
  width: 100%;
  // td:not(.ant-table-cell-fix-right) * {
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  // }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  td:last-child {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

// 文字禁止换行省略号针对table
.aspirin-table-row-box {
  overflow: hidden;
  width: 100%;

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.aspirin-row-selected-box {
  background-color: var(--primary-color-2) !important;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out;
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  border: 1px solid var(--ant-border-color) !important;
}

.ant-input-affix-wrapper-status-error {
  border: 1px solid #ff4d4f !important;
  &:hover {
    .active();
  }
}

.active {
  border-color: #5d9af5 !important;
  border-right-width: 1px;
  z-index: 1;
}

.ant-input-affix-wrapper-textarea-with-clear-btn:not(
    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper-status-error
  ) {
  .ant-input-affix-wrapper:hover {
    .active();
  }
}

.ant-input-affix-wrapper-focused {
  .active();
}

.aspirin-table-title-box-no-bg > .ant-pro-table-list-toolbar {
  background: transparent !important;
}

.icon-color-dui {
  svg {
    fill: var(--dui);
  }
}

.icon-color-cuo {
  svg {
    fill: var(--cuo);
  }
}

.aspirin-table-td-blue-box {
  color: var(--blue);
}

.aspirin-table-row-red-box {
  td:last-child {
    background: var(--red-background) !important;
    border: 1px solid var(--red);
  }
}

.aspirin-table-row-red-box-all {
  td {
    background: var(--red-background) !important;
    // border: 1px solid var(--red);
    color: var(--red) !important;
  }
}

.aspirin-layout-index-sider {
  z-index: 1001;
}

.ant-modal-wrap {
  z-index: 1002 !important;
}

.aspirin-row-disabled-box {
  td {
    cursor: not-allowed !important;
  }
}

.aspirin-search-custom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aspirin-select-disabled-box {
  .ant-select-selector {
    cursor: auto !important;
  }
}

.aspirin-title-popover-box {
  color: var(--primary-color);
  font-weight: bold;
}

.aspirin-list-herb-popover-item-box {
  .ant-list-items {
    display: grid;
    grid-template-columns: 2fr auto;
    gap: 16px;
  }
}

.aspirin-herb-box {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .aspirin-title-herb-popover-box {
    height: 56px;
    background: rgb(249, 250, 252);
    position: relative;
    box-sizing: border-box;
    padding: 0 24px;
    min-width: 700px;

    .aspirin-title-herb-header-box {
      width: 400px;
      height: 60%;
      display: flex;
      align-items: center;

      .aspirin-title-herb-header-left-box {
        display: flex;
        gap: 10px;
      }
      .aspirin-title-herb-header-right-box {
        position: absolute;
        right: 24px;
        bottom: 0;

        > svg {
          font-size: inherit;
        }
      }
    }
    .aspirin-title-message-box {
      display: flex;
      gap: 20px;
      .aspirin-title-message-memo-box {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-spin-container {
    padding: 12px 24px;
  }
  .aspirin-title-herb-popover-item-box {
    width: 100%;
    min-width: 300px;
    display: flex;
    span {
      white-space: nowrap;
      padding: 0 10px;
      &:not(:last-child) {
        border-right: 1px solid darkorange;
      }
      &:nth-child(2) {
        white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
    }
  }
}

.aspirin-footer-button-box {
  display: flex;
  gap: 10px;
}
.aspirin-footer-len-box {
  > span {
    color: var(--blue);
  }
}
.aspirin-table-form-search-hide-box {
  display: flex;
  gap: 10px;
  flex-direction: column;
  > * {
    background: transparent !important;
  }
  .pro-form-query-filter-actions {
    display: none !important;
  }
  .ant-form-item-row {
    flex-direction: row !important;
    gap: 10px;
    align-items: center;
    .ant-form-item-control {
      flex: 1;
      width: 0 !important;
    }
    .ant-form-item-label {
      padding: 0;
    }
  }
}
.aspirin-transfer-box {
  .ant-transfer-list-header {
    background: transparent !important;
  }
}
.aspirin-table-search-no-background-box {
  .ant-pro-table-search {
    background: transparent !important;
  }
}
.aspirin-table-no-background-box {
  .ant-pro-table-search {
    background: transparent !important;
  }
  tbody .ant-table-cell-fix-right-first {
    background: var(--tint-background) !important;
  }
  thead .ant-table-cell-fix-right-first {
    background: var(--primary-color-4) !important;
  }
  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon,
          .ant-picker,
          .ant-input-number,
          .ant-pagination *,
          .ant-switch,
          .ant-switch-checked,
          .cart-content *
        ) {
        background: transparent;
      }
    }
  }
  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }
  .ant-table-wrapper thead {
    background: var(--primary-color-4) !important;
    tr {
      border-bottom: 1px solid !important;
      th {
        border: none !important;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        border-left: 1px solid;
      }
      td:last-child {
        border-right: 1px solid;
      }
    }
  }
}

.aspirin-table-footer-button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  gap: 10px;
}

.aspirin-menu-box {
  .ant-dropdown-menu-item:hover {
    background-color: var(--primary-color-3) !important;
    color: var(--primary-color);
  }
}

.aspirin-order-text-group-first {
  position: absolute;
  inset: 0;
  &::before {
    content: '';
    width: 9px;
    height: calc(~'50% + 12px');
    background-color: transparent;
    border: 2px solid #3276e8;
    border-right-width: 0;
    border-bottom-width: 0;
    position: absolute;
    top: 50%;
    left: 7px;
    z-index: 10;
  }
}

.aspirin-order-text-group-line {
  // position: relative;
  position: absolute;
  inset: 0;
  &::after {
    content: '';
    width: 2px;
    height: calc(~'100% + 24px');
    background-color: #3276e8;
    position: absolute;
    top: -12px;
    left: 7px;
    z-index: 10;
  }
}

.aspirin-order-text-group-last {
  position: absolute;
  inset: 0;

  &::before {
    content: '';
    width: 9px;
    height: calc(~'50% + 12px');
    background-color: transparent;
    border: 2px solid #3276e8;
    border-right-width: 0;
    border-top-width: 0;
    position: absolute;
    z-index: 10;
    top: -12px;
    left: 7px;
  }
}

.ant-empty {
  width: 100%;
}

.aspirin-review-of-inpatient-medication-orders-box {
  .top-box {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color-5);
    padding: 0 28px;
    .left-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      div {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 500;
        }
        &:nth-child(2) {
          color: #e34a4e;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .right-box {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.aspirin-review-reject-main-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    > div {
      display: flex;
      gap: 10px;
    }
  }
}
.aspirin-table-center-columns {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aspirin-alert-warning-box {
  border: 1px solid #ff7d45 !important;
  background: #fff2ed !important;
  border-radius: 6px !important;
  .ant-alert-content {
    display: flex;
    justify-content: center;
    .ant-alert-message {
      color: #ff7d45 !important;
      font-size: 14px;
    }
  }
}

.ant-form-item-feedback-icon {
  display: none;
}

.ant-input-number-handler-wrap {
  // display: none;
}

.aspirin-tabs-style-box {
  .ant-tabs-nav {
    &::before {
      content: '';
      border-bottom: 1px solid #d6d8d9 !important;
    }
  }
}
.aspirin-divider-style {
  border-color: #3276e8 !important;
  margin-bottom: 19px !important;
  margin-top: -5px !important;
}

.aspirin-table-title-background-box {
  background: #fff !important;
  color: #343b42;
  font-weight: bold;
  font-size: 14px;
  // td{
  //   border: none !important;
  // }
}

.aspirin-pagination-box {
  .ant-pagination-total-text {
    display: flex;
    align-self: flex-end;
  }
}

.aspirin-table-row-order-red {
  > td {
    color: #e34a4e;
  }
}

.ant-table-cell-scrollbar {
  display: none !important;
}
.aspirin-table-row-active {
  > td {
    background-color: #cce5ff !important;
    color: unset;
  }
}

.aspirin-tabs-box {
  .ant-tabs-nav::before {
    border-bottom: 1px #d6d8d9 solid !important;
  }
}
@keyframes group {
  to {
    opacity: 0;
    transform: scale(0);
  }
  from {
    opacity: 1;
    transform: scale(1);
  }
}

.aspirin-group-text-box {
  .aspirin-order-text-group-first {
    &::before {
      animation: group 0.3s ease-in-out;
      content: '';
    }
  }
  .aspirin-order-text-group-last {
    &::before {
      animation: group 0.3s ease-in-out;
      content: '';
    }
  }

  .aspirin-order-text-group-line {
    &::after {
      animation: group 0.3s ease-in-out;
      content: '';
    }
  }
  .aspirin-group-icon-box {
    animation: group 0.3s ease-in-out;
  }
}

@root-entry-name: default;