.cdss_modal.ant-modal {
  .ant-modal-header {
    // background-color: #5780f8 !important;
    background-color: #fff !important;
    color: #a29e9e;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-weight: bolder;
      font-size: 14px;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    border-top: none;
    padding-bottom: 20px;
  }
}
.cdss_modal {
  &_content {
    display: flex;
    flex-direction: column;
    &_width1 {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_width2 {
      flex: 1;
      border-left: 1px solid #e2e2e2;
      border-right: 1px solid #e2e2e2;
    }
    &_width3 {
      width: 110px;
    }

    &_header {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #e2e2e2;
      height: 42px;
      font-weight: bold;
      font-size: 14px;
      background-color: #f6f6f6;
      div {
        text-align: center;
        line-height: 42px;
      }
    }
    &_body {
      display: flex;
      width: 100%;
      border: 1px solid #e2e2e2;
      border-top: none;
      border-bottom: none;
      &_item {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 42px;
        font-size: 12px;
        div {
          text-align: center;
        }
        &_description {
          border-bottom: none;
          .item {
            padding: 0 8px;
            border-bottom: 1px solid #e2e2e2;
            width: 100%;
            height: 42px;
            // line-height: 42px;
          }
        }
      }
    }
  }
}

.warnList.ant-table-cell {
  padding: 0 !important;
}

@root-entry-name: default;