.bed_card_detail_modal {
  //   padding: 20px 0;
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  //   .ant-form-item.ant-form-item-has-success {
  //     margin-bottom: 24px !important;
  //   }
  &_form_title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    &_tag {
      margin-top: 2px;
      margin-right: 12px;
      width: 4px;
      height: 20px;
      background-color: #3276e8;
    }
  }
}

@root-entry-name: default;