.BgColor {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}
.DisFlex {
  display: flex;
}
.onBroder {
  border: none !important;
}

.Above {
  width: 100%;
  .container {
    padding: 24px;
    height: 100%;
    width: 100%;
    table {
      margin-right: 24px;
      tr {
        td {
          // padding-right: 16px;
          font-size: 16px;
          width: 78px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-child(2) {
        tr {
          td {
            padding-right: 32px !important;
          }
        }
      }
    }
  }
}

.Content {
  width: 100%;
  height: 84%;
  .PageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    .PageContent {
      width: 100%;
      height: 85%;

      .DoctorsAdvice {
        height: 100%;
        .doctor {
          width: 100%;
          height: 56%;
          .msg {
            height: 20%;
            padding: 12px 6px 12px 28px;
            background-color: rgba(219, 237, 255, 1);
            border-radius: 8px;
            justify-content: space-between;
            table {
              display: flex;
              tr,
              th {
                margin-right: 24px;
              }
            }
          }
          #detailsTable {
            width: 100%;
            height: 98%;
          }
        }
        #abvice {
          width: 100%;
          height: 44%;
          margin-left: 12px;
          .ant-divider-horizontal {
            margin: 12px auto !important;
          }
          .vs-section-title {
            position: relative;
            margin-left: 12px;
            width: 100% !important;
            &::before {
              position: absolute;
              top: 0;
              left: -12px;
              transform: translate(-50%, 0);
              content: ' ';
              width: 6px;
              height: 100%;
              display: inline-block;
              background-color: rgba(50, 118, 232, 1);
            }
          }
        }
      }
      .NurseEntry {
        width: 100%;
        height: 100%;
        display: flex;
        .left,
        .right {
          padding: 12px;
          .top {
            tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .title {
                font-size: 24px;
                position: relative;
                padding-left: 20px;
                &::before {
                  position: absolute;
                  top: 0;
                  left: -6px;
                  transform: translate(-50%, 0);
                  content: ' ';
                  width: 6px;
                  height: 100%;
                  display: inline-block;
                  background-color: rgba(50, 118, 232, 1);
                }
              }
            }
          }
        }
        .left {
          width: 30%;
          border-right: 1px solid rgba(204, 204, 204, 1);
          padding: 12px;
          .top {
            height: 8%;
            tr {
              td {
                &:nth-child(2) {
                  width: 46%;
                }
              }
            }
          }
          .table {
            margin-top: 8px;
            height: 92%;
          }
        }
        .right {
          position: relative;
          width: 70%;
          .top {
            height: 8%;
          }
          .table {
            margin-top: 8px;
            height: 86%;
          }
          .footer {
            position: relative;
            bottom: 0;
            right: 0;
            // height: 6%;
            tr {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
    .PageFooter {
      border-top: 1px solid rgba(204, 204, 204, 1);
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 9999;
    }
  }
}
.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .chargingTableCreate-table-search {
    .ant-pro-table-search {
      background: none !important;
      margin: 0;
      .ant-form-item-label {
        display: none !important;
      }
    }
  }
  #expenseDetailsTableCreate,
  #chargingTableCreate,
  #orderBillingDetailOrderList,
  #orderBillingDetailGroupList {
    .aspirin-vs-table-row-box td:last-child > div {
      display: inline-block !important;
    }
    background: rgba(255, 255, 255, 0.3);
    .ant-table-thead {
      tr > th {
        text-align: center;
        background: rgba(229, 242, 255, 1);
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      tr {
        text-align: center;
        td {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#orderBillingDetailBillingList {
  background: rgba(255, 255, 255, 0.3);
  .ant-table-thead {
    tr > th {
      text-align: center;
      background: rgba(229, 242, 255, 1);
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    tr {
      text-align: center;
      td {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

@root-entry-name: default;