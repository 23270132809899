//表格卡片样式
.register-table-inner-cart {
  width: 360px;
  cursor: pointer;
  height: 144px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  // background: #ffffff;
  padding: 12px 16px 24px 16px;

  .register-table-inner-cart-title {
    display: flex;
    height: 16px;
    font-size: 16px;
    justify-content: space-between;

    .register-table-inner-cart-title-left {
      width: 40px;
      height: 20px;
      background: rgba(50, 118, 232, 0.1);
      border-radius: 2px;
      font-weight: 500;
      color: #3276e8;
      text-align: center;
    }

    .register-table-inner-cart-title-center {
      margin-left: 6px;
      height: 16px;
      width: 133px;
      font-weight: 400;
      color: #343b42;
    }

    .register-table-inner-cart-title-right {
      // margin-left: 71px;
      justify-self: end;
    }
  }

  .register-table-inner-cart-content {
    font-weight: 500;
    color: #3276e8;
    height: 20px;
    font-size: 20px;
  }

  .register-table-inner-cart-footer {
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #343b42;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 12px 0;
    margin-bottom: 24px;
  }
}

.register-table-inner-select-cart {
  // width: 224px;
  background: #ebf2ff;
  border-radius: 6px;
  border: 1px solid #3276e8;
  padding: 8px;

  .register-table-inner-cart-select-title {
    display: flex;
    align-items: center;

    .register-table-inner-cart-title-select-left {
      width: 32px;
      height: 16px;
      font-size: 12px;
      background: rgba(50, 118, 232, 0.1);
      border-radius: 2px;
      font-weight: 500;
      color: #3276e8;
    }

    .register-table-inner-cart-select-content {
      width: 102px;
      font-size: 16px;
      font-weight: 500;
      color: #3276e8;
      height: 16px;
      margin-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .register-table-inner-cart-select-footer {
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #85898d;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;

    .cart-footer-type {
      margin-right: 6px;
    }
  }
}

// 气泡卡片样式
.doctor-detaill-container {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #85898d;

  .doctor-detaill {
    font-size: 20px;
    font-weight: 500;
    color: #343b42;
    position: relative;
    width: 556px;
    margin-left: 15px;
    // margin-top: 19px;
    margin-bottom: 23px;

    &::before {
      position: absolute;
      width: 4px;
      height: 20px;
      background: #3276e8;
      content: '';
      transform: translate(-50%, -50%);
      top: 50%;
      left: -12px;
    }
  }

  .doctor-main-detaill {
    display: flex;
    flex-direction: column;

    .doctor-detaill-avatar {
      display: flex;

      .doctor-detaill-Img {
        margin-left: 64px;
      }
    }

    .doctor-detaill-all-detail {
      display: flex;
      flex-direction: column;

      .doctor-detaill-all-detail-sub {
        display: flex;
        justify-content: space-between;

        .doctor-detaill-title-left {
          display: flex;
          margin-top: 23px;
          width: 50%;

          word-wrap:break-word &> :last-child {
            margin-right: 24px;
            font-weight: 500;
            color: #343b42;
            font-size: 16px;
          }

          .doctor-detaill-name {
            margin-left: 60px;
            font-weight: 500;
            color: #343b42;
            font-size: 16px;
          }
        }

        .doctor-detaill-title-right {
          display: flex;
          margin-right: 24px;
          margin-top: 23px;
          overflow: hidden;
          width: 50%;

          .name {
            margin-left: 60px;
            font-size: 16px;
            font-weight: 500;
            color: #343b42;
          }
        }
      }

      .doctor-detaill-park {
        margin-top: 23px;
        width: 100%;
        word-wrap: break-word;

        &> :first-child {
          display: flex;

          &> :last-child {
            margin-left: 30px;
          }
        }
      }
    }
  }
}

.doctor-detaill-name {
  margin-left: 60px;
  font-weight: 500;
  color: #343b42;
  font-size: 16px;
}

//支付Modal
.pay-layout-container {
  .ant-form-horizontal .ant-form-item-label {
    flex-grow: 0;
    text-align: left;
  }

  .ant-modal-body {
    padding: 0px !important;
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  .pay-person-detaill {
    width: 100%;
    height: 54px;
    font-weight: 400;
    font-size: 16px;
    color: #343b42;

    .person-detaill {
      font-weight: 500;
      color: #343b42;
      font-size: 18px;
    }
  }

  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .pay-person-content {
    display: flex;

    .pay-person-content-left {
      padding: 24px 0;
      width: 550px;
      height: 552px;
      border-right: 1px solid pink;

      .total-cost {
        font-weight: 400;
        color: #343b42;
        font-size: 16px;
      }

      .total-number {
        color: #343b42;
        line-height: 18px;
        font-size: 18px;
      }
    }

    .pay-person-content-right {
      padding-left: 50px;
      padding-top: 30px;
      padding-right: 50px;
      width: 450px;
      height: 560px;
      overflow-x: hidden;
      overflow-y: scroll;

      .ant-form-item-label>label::after {
        content: ':';
        position: relative;
        top: 3.5px !important;
        margin: 0 8px 0 2px;
      }

      .receivable-accounts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        color: #343b42;
        font-size: 16px;
        padding-right: 12px;

        .receivable-number {
          font-weight: 500;
          color: #3276e8;
          font-size: 24px;
        }
      }

      .pay-person-content-right-inp {
        height: 48px;
      }
    }
  }
}

.eceivable-form-height {
  height: 48px;
}

.receivable-form-title {
  font-weight: 400;
  color: #343b42;
  font-size: 16px;
  margin-top: 10px;

}

.suffix-readCard {
  height: 20px;
  display: flex;
  align-items: center;
  color: #3276e8;
  cursor: pointer;
}

.suffix-cardText {
  margin-top: 2px;
  padding-left: 5px;
}

.patientfull-bydisplay-form {
  .ant-input-group-addon {
    background-color: #fff !important;
  }

  .ant-col-6 {
    margin-top: 12px !important;
  }

  .search_icon {
    width: 15px;
    height: 15px;
    cursor: pointer;

  }

  .patientProfileDto_currentAddress_code {
    width: 210px;
  }

  .patientProfileDto_currentAddress_detail {
    width: 485px;
    margin-left: -85px;
  }

  .patientProfileDto_cellphone {
    width: 210px;
  }


}
@root-entry-name: default;