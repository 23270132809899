.aspirin-order-selected-box{
  display: flex;
  justify-content: space-between;
  .aspirin-order-selected-left-box{
    display: flex;
  }
}

.aspirin-table-operation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .anticon {
    + .anticon {
      margin-left: 4px;
    }
  }
}

@root-entry-name: default;