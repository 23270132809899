// /* 当屏幕宽度小于或等于1600px时，设置字体大小为16px */
// @media screen and (max-width: 1600px) {
//   html {
//     * {
//       // font-size: 16px !important;
//     }
//   }
// }

body {
  width: 100% !important;
  height: 100% !important;
  #root {
    width: 100% !important;
    height: 100% !important;
  }
}

.heightAll100 {
  height: 100%;
  .ant-tabs-content-top {
    height: 100%;
    .ant-tabs-tabpane-active {
      height: 100%;
      .aspirin-transfer-box {
        height: 100%;
        .aspirin-transfer {
          flex: 1;
        }
      }
    }
  }
}

.PercentageHeight100 {
  height: 100%;
}
.height60 {
  height: 60px !important;
}

.height102 {
  height: 140px !important;
}
.height650 {
  height: 650 !important;
}

.widthAutoFill {
  width: 100% !important;
}

.width20 {
  width: 20px !important;
}
.width40 {
  width: 40px !important;
}
.width60 {
  width: 60px !important;
}
.width70 {
  width: 70px !important;
}

.width80 {
  width: 80px !important;
}

.width96 {
  width: 96px !important;
}

.width100 {
  width: 100px !important;
}

.width120 {
  width: 120px !important;
}

.width130 {
  width: 130px !important;
}

.width140 {
  width: 140px !important;
}
.width160 {
  width: 160px !important;
}

.width164 {
  width: 164px !important;
}

.width180 {
  width: 180px !important;
}
.width190 {
  width: 190px !important;
}
.width200 {
  width: 200px !important;
}

.width220 {
  width: 220px !important;
}

.width240 {
  width: 240px !important;
}

.width266 {
  width: 266px !important;
}

.width280 {
  width: 280px !important;
}

.width300 {
  width: 300px !important;
}

.width320 {
  width: 320px !important;
}

.width340 {
  width: 320px !important;
}

.width360 {
  width: 360px !important;
}

.width374 {
  width: 374px !important;
}

.width400 {
  width: 400px !important;
}

.width410 {
  width: 410px !important;
}

.width470 {
  width: 470px !important;
}

.width550 {
  width: 554px !important;
}

.width560 {
  width: 560px !important;
}

.width571 {
  width: 571px !important;
}

.width644 {
  width: 644px !important;
}

.width700 {
  width: 700px !important;
}

.width918 {
  width: 918px !important;
}
.width934 {
  width: 934px !important;
}

.width1100 {
  width: 1100px !important;
}

.width1360 {
  width: 2360px !important;
}

.width1376 {
  width: 1376px !important;
}

.columnWidth80 {
  width: 80px !important;
}

.columnWidth100 {
  width: 100px !important;
}

.columnWidth120 {
  width: 120px !important;
}

.columnWidth300 {
  width: 300px !important;
}

.icon10 {
  font-size: 10px !important;
}

.icon12 {
  font-size: 12px !important;
}

.icon14 {
  font-size: 14px !important;
}

.icon15 {
  font-size: 15px !important;
}

.icon16 {
  font-size: 16px !important;
}

.icon18 {
  font-size: 18px !important;
}

.icon20 {
  font-size: 20px !important;
}

.icon22 {
  font-size: 22px !important;
}

.icon24 {
  font-size: 24px !important;
}

.icon26 {
  font-size: 26px !important;
}

.icon28 {
  font-size: 28px !important;
}

.icon30 {
  font-size: 30px !important;
}

.icon32 {
  font-size: 32px !important;
}

.icon34 {
  font-size: 34px !important;
}

.icon38 {
  font-size: 38px !important;
}

.icon44 {
  font-size: 44px !important;
}

.icon48 {
  font-size: 48px !important;
}

.marginLeft10 {
  margin-left: 10px !important;
}

.marginLeft16 {
  margin-left: 16px !important;
}

.marginLeft20 {
  margin-left: 20px !important;
}

.marginLeft24 {
  margin-left: 24px !important;
}

.marginLeft30 {
  margin-left: 30px !important;
}

.marginLeft150 {
  margin-left: 150px !important;
}

.marginLefts44 {
  margin-left: -44px !important;
}

.marginBottom16 {
  margin-bottom: 16px !important;
}
.marginBottom14 {
  margin-bottom: 14px !important;
}

.marginTop5 {
  margin-top: 5px !important;
}
.marginTop16 {
  margin-top: 16px !important;
}
.marginTop150 {
  margin-top: 150px !important;
}
.marginTops30 {
  margin-top: -50px !important;
}

.marginRight16 {
  margin-right: 16px !important;
}

.padding24_0 {
  padding: 24px 0;
}

.paddingTop24 {
  padding-top: 24px;
}
.padding24 {
  padding: 24px;
}
// table滚动高度

.table_overflow_210 {
  .ant-table-body {
    max-height: calc(100vh - 210px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_240 {
  .ant-table-body {
    max-height: calc(100vh - 240px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_278 {
  .ant-table-body {
    max-height: calc(100vh - 278px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_280 {
  .ant-table-body {
    max-height: calc(100vh - 280px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_294 {
  .ant-table-body {
    max-height: calc(100vh - 294px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_300 {
  .ant-table-body {
    max-height: calc(100vh - 300px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_310 {
  .ant-table-body {
    max-height: calc(100vh - 310px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_328 {
  .ant-table-body {
    max-height: calc(100vh - 328px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_340 {
  .ant-table-body {
    max-height: calc(100vh - 340px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_358 {
  .ant-table-body {
    max-height: calc(100vh - 358px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_378 {
  .ant-table-body {
    max-height: calc(100vh - 378px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_384 {
  .ant-table-body {
    max-height: calc(100vh - 384px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_400 {
  .ant-table-body {
    max-height: calc(100vh - 400px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_420 {
  .ant-table-body {
    max-height: calc(100vh - 420px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_435 {
  .ant-table-body {
    max-height: calc(100vh - 435px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_450 {
  .ant-table-body {
    max-height: calc(100vh - 450px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_480 {
  .ant-table-body {
    max-height: calc(100vh - 480px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_495 {
  .ant-table-body {
    max-height: calc(100vh - 495px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_500 {
  .ant-table-body {
    max-height: calc(100vh - 500px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_510 {
  .ant-table-body {
    max-height: calc(100vh - 510px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_520 {
  .ant-table-body {
    max-height: calc(100vh - 520px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_530 {
  .ant-table-body {
    max-height: calc(100vh - 522px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_560 {
  .ant-table-body {
    max-height: calc(100vh - 560px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_569 {
  .ant-table-body {
    max-height: calc(100vh - 569px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_576 {
  .ant-table-body {
    max-height: calc(100vh - 576px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_600 {
  .ant-table-body {
    max-height: calc(100vh - 600px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_650 {
  .ant-table-body {
    max-height: calc(100vh - 650px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_660 {
  .ant-table-body {
    max-height: calc(100vh - 660px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_700 {
  .ant-table-body {
    max-height: calc(100vh - 700px) !important;
    overflow-y: scroll;
  }
}
.table_overflow_730 {
  .ant-table-body {
    max-height: calc(100vh - 730px) !important;
    overflow-y: scroll;
  }
}

.table_overflow_844 {
  .ant-table-body {
    max-height: calc(100vh - 844px) !important;
    // max-height: 160px !important;
    overflow-y: scroll;
  }
}
.table_overflow_884 {
  .ant-table-body {
    max-height: calc(100vh - 884px) !important;
    // max-height: 160px !important;
    overflow-y: scroll;
  }
}

// modal高度
.modalHeight752 {
  .ant-modal-body {
    height: 752px !important;
  }
}

.modalHeight720 {
  .ant-modal-body {
    height: 720px !important;
  }
}

.modalHeight680 {
  .ant-modal-body {
    height: 680px !important;
  }
}

.modalHeight640 {
  .ant-modal-body {
    height: 640px !important;
  }
}

// table字体大小
// 行
.ant-table-cell {
  font-size: 18px !important;
}
// 表头
.ant-table-thead .ant-table-cell {
  font-size: 20px;
  font-weight: bolder !important;
}

// table搜素区域label
.aspirin-table-search {
  .ant-form-item-label {
    label {
      font-size: 18px !important;
    }
  }
}
// form
.ant-form {
  // label字体大小
  .ant-form-item-label {
    overflow: revert !important;
    label {
      font-size: 18px !important;
    }
  }
  // 输入框文字
  span,
  input {
    font-size: 18px;
  }
  .ant-input-affix-wrapper {
    padding-bottom: 0;
    padding-top: 0;
    line-height: 1.875rem;
  }
}

// modal title
.ant-modal-title {
  font-size: 20px;
}
// Tabs
.ant-tabs {
  .ant-tabs-tab-btn {
    font-size: 18px;
  }
}
.common_fontSize18() {
  font-size: 18px !important;
}
.aspirin-container,
.enter-font-open-style,
.ant-select-tree,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-radio-button-wrapper,
.ant-popover,
.ant-list,
.ant-alert {
  .common_fontSize18();
}
table {
  .ant-table-tbody,
  .ant-table-body {
    .ant-input,
    .ant-form-item-control-input-content,
    .sc-dhKdcB,
    .ant-select-selection-item,
    .ant-input-number-input,
    .ant-select-selector,
    .ant-drawer-content,
    .ha-dTLc {
      .common_fontSize18();
    }
  }
}

// 按钮样式统一
.ant-btn {
  .common_fontSize18();
  height: 2.5rem !important;
  // padding: 0.4rem 0.9375rem !important;
  line-height: 0 !important;
}

.Descriptions,
.ant-descriptions-item-label,
.ant-descriptions-item-content,
.MailMsg,
.aspirin-order-text,
.ant-popover-placement-top,
.ant-menu-title-content,
.title,
.sc-kAyceB,
.ant-form-item-label,
.ant-select-selector,
.ant-input {
  font-size: 18px !important;
}

.ant-select-dropdown {
  .ant-select-item,
  .ant-select-tree-treenode {
    padding: 0.5125rem 0.75rem !important;
  }
}
.ant-picker-input {
  input {
    font-size: 18px !important;
  }
}
.ant-picker-panel-container {
  font-size: 18px;
}
.ant-drawer {
  // z-index: 10001 !important;
}
.ant-slider-mark {
  font-size: 20px !important;
}
.mySteps_content {
  .mySteps_content_unfinished {
    font-size: 18px !important;
  }
}
.ant-popover {
  line-height: 1 !important;
}

.ant-menu-vertical.ant-menu-sub {
  max-height: calc(100vh - 17rem) !important;

  // height: 100px !important;
}

// // 最大分辨率
// @media (min-width: 1922px) {
//   html {
//     overflow: auto !important;
//     font-size: 18px !important;
//     height: 70.5999rem !important;
//     // height: 157.5rem !important;
//     width: 142.199rem !important;
//     body {
//       width: 100% !important;
//       height: 100% !important;
//       #root {
//         width: 100% !important;
//         height: 100% !important;
//       }
//     }
//   }
// }

// // 中等分辨率
// @media (min-width: 1441px) and (max-width: 1920px) {
//   html {
//     // W 1440
//     width: 137.142857142857rem !important;
//     // H 1024
//     height: 102.857142857142rem !important;
//     overflow: auto !important;
//     font-size: 14px !important;
//     body {
//       width: 100% !important;
//       height: 100% !important;
//       #root {
//         width: 100% !important;
//         height: 100% !important;
//       }
//     }
//   }
// }
// 最小分辨率 1440 以下
// 1280*600
@media (max-width: 1440px) {
  html {
    // // W 1440
    width: 120rem !important;
    // // H 900
    height: 75rem !important;
    font-size: 12.45px !important;
    overflow: auto !important;
    body {
      width: 100% !important;
      height: 100% !important;
      #root {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

@root-entry-name: default;