.list-item-main-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  .list-item-option-box {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .list-item-option-form-box {
      display: flex;
      // width: 95%;
      flex: 1;
      > *{
        flex: 1;
      }
      gap: 10px;
    }

    .list-item-option-edit-box {
      display: flex;
      gap: 10px;

      @keyframes Edit {
        to {
          transform: scale(.8);
        }

        from {
          transform: scale(1.2);
        }
      }

      >span {
        cursor: pointer;

        &:active {
          animation: Edit ease-out .5s;
        }
      }
    }
  }
}
@root-entry-name: default;