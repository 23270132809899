.register-container-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: relative;

  .ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
    width: 100%;
  }
  .ant-popover-inner-content {
    width: max-content;
    max-width: 100%;
    padding: 24px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .register-cart-top {
    // flex: 3;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    display: flex;
    overflow: auto;
    height: 71%;
    .register-cart-top-left {
      width: 276px;
      // border-right: 1px solid #3276E8;
    }
    .register-cart-top-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 0;
      margin-left: 24px;

      .register-cart-serach {
        margin-top: 14px;
        margin-bottom: 6px;
        height: 72px;
        // border: 1px solid pink;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: space-between;
        .serach-input {
          display: flex;
          align-items: center;
          flex: 1;
        }
        .select-cart-show {
          display: flex;
          align-items: center;
          margin-left: 24px;
          flex: 1;
          .select-cart-show-title {
            font-size: 16px;
            font-weight: 400;
            color: #343b42;
          }
          .select-cart-show-content {
            display: flex;
            margin-right: 6px;
          }
        }
        .bill-number {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: flex-end;
          .select-cart-number {
            margin-left: 74px;
          }
          .select-cart-farewell {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            width: 140px;
            height: 32px;
            background: rgba(50, 118, 232, 0.1);
            border-radius: 2px;
          }
        }
      }
      .register-cart-main {
        flex: 1;
        display: flex;
        // height: 100%;
        overflow: auto;
        // border: 1px solid pink;
        .register-cart-main-container {
          border-radius: 4px;
          flex: 1;
          position: relative;
          .register-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .register-loadding {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .register-cart-main-title {
            display: flex;
            align-items: center;
            height: 40px;
            background: #dbedff;
            font-size: 20px;
            margin-right: 24px;
            .register-cart-main-title-before {
              position: relative;
              margin-left: 30px;
              font-weight: 500;
              color: #343b42;
              &::before {
                position: absolute;
                width: 5px;
                height: 20px;
                background: #3276e8;
                content: '';
                transform: translate(-50%, -50%);
                top: 50%;
                left: -12px;
              }
            }
          }
          .register-cart-main-content {
            width: 100px;
            margin-top: 16px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-flow: row;
            grid-row-gap: 24px;
            grid-column-gap: 24px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
  .register-serach-bottom {
    //  height: 20%;
    // border: 1px solid pink;
    background: rgba(255, 255, 255, 0.5);
    overflow: auto;
    padding: 24px;
    border-radius: 4px;
  }
}

// .demo1 {
//   .ant-modal-body {
//     padding: 24px;
//     font-size: 14px;
//     line-height: 1.5715;
//     word-wrap: break-word;
//     background: #f4f5f7;
//   }
// }
.pay-confim-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.medical-steps {
  min-width: 275px;
  margin-top: 20px;
  height: calc(100% - 60px);
  overflow-y: auto;
  border-right: 1px solid #3276e8;
  .ul {
    text-align: center;
  }
  .selected {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    border-left: 1px solid #d6d8d9;
    font-weight: 500;

    a {
      display: inline-block;
      color: #5d6268;
      padding-left: 10px;
    }
    ::before {
      content: '';
      /* 伪元素的内容为空 */
      width: 10px;
      /* 圆的直径为 10px */
      height: 10px;
      /* 圆的直径为 10px */
      background-color: #d6d8d9;

      /* 圆的背景颜色 */
      border-radius: 50%;
      /* 将伪元素变成圆形 */
      position: absolute;
      /* 绝对定位，相对于父盒子定位 */
      left: -5px;
      /* 左侧定位为 0，即紧贴在父盒子的前面 */
      top: 50%;
      /* 垂直居中，可以根据需要调整 */
      transform: translateY(-50%);
      /* 垂直居中，基于父盒子的高度 */
    }
  }
  .unselect {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    border-left: 1px solid #d6d8d9;
    font-weight: 500;
    a {
      display: inline-block;
      color: #3276e8 !important;
      padding-left: 10px;
    }
    ::before {
      content: '';
      /* 伪元素的内容为空 */
      width: 10px;
      /* 圆的直径为 10px */
      height: 10px;
      /* 圆的直径为 10px */
      background-color: #3276e8;
      /* 圆的背景颜色 */
      border-radius: 50%;
      /* 将伪元素变成圆形 */
      position: absolute;
      /* 绝对定位，相对于父盒子定位 */
      left: -5px;
      /* 左侧定位为 0，即紧贴在父盒子的前面 */
      top: 50%;
      /* 垂直居中，可以根据需要调整 */
      transform: translateY(-50%);
      /* 垂直居中，基于父盒子的高度 */
    }
  }
}

@root-entry-name: default;