.aspirin-common-medicines-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--background);
  > nav {
    height: 60px;
    border-bottom: var(--primary-color) 1px solid;
    padding: 0 24px;
    display: flex;
    align-items: center;
  }
  > main {
    flex: 1;
    display: flex;
    > div.aspirin-common-medicines-main-menu-box {
      width: 380px;
      height: 100%;
      padding: 24px;
      display: flex;
      flex-direction: column;
      border-right: var(--primary-color) 1px solid;
      // gap: 10px;
      > div.aspirin-common-medicines-main-menu-item-box {
        // background: var(--background);
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        gap: 10px;
        border-radius: 4px;
        svg {
          transition: all 0.5s;
        }
        @keyframes transformSVG {
          to {
            transform: scaleY(1);
          }
          from {
            transform: scaleY(1.3);
          }
        }
        &.active {
          background: var(--primary-color-2);
          color: var(--primary-color);
          span {
            animation: transformSVG 0.5s;
          }
          svg {
            color: var(--primary-color);
            animation: transformSVG 0.5s;
          }
        }
      }
    }
    > div.aspirin-common-medicines-main-content-box {
      flex: 1;
      padding: 24px;
      > nav {
        display: flex;
        gap: 10px;
      }
      .aspirin-common-main-body-box {
        padding: 24px 0;
        display: grid;
        grid-template-columns: repeat(5, calc((100% / 5) - 10px));
        gap: 10px;
        .aspirin-common-item-box {
          background: #fff;
          height: 50px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          justify-content: space-between;

          > div {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

@root-entry-name: default;