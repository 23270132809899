.aspirin-table-search-form-box{
  background: var(--background);
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}


.aspirin-table-check-box{
  width: 120px;
  height: 36px;
  border: 1.5px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  position: relative;
  transition: all .3s;
}
.aspirin-table-check-active-box{
  background: var(--primary-color-4);
  border: 1.5px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: bold;
  .aspirin-table-check-icon-box{
    position: absolute;
    right: 0;
    top: 0;
  }
}
.aspirin-table-row-background-box{
  background: #fff !important;
  color: var(--primary-color);
  font-weight: bold;
  td{
    border: none !important;
  }
}
.aspirin-table-inter-box{
  background: var(--background);
  margin-top: 20px;
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .aspirin-table-tabs-box{
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    .ant-tabs-nav{
      margin-bottom: 0 !important;
    }
  }
  .ant-tabs-tab-active{
    background: var(--primary-color) !important;
    > div{
      color: #fff !important;
    }
    border-radius: 2px !important;
  }
}
.aspirin-table-inter-no-box{
  background: var(--background);
  margin-top: 10px;
  padding: 0 15px;
  height: 100%;
  .aspirin-table-tabs-box{
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    .ant-tabs-nav{
      margin-bottom: 0 !important;
    }
  }
}
.aspirin-table-search-form-order-box{
  display: flex;
  // justify-content: space-between;
  gap: 56px;
  height: 68px;
  padding: 0 24px;
  align-items: center;
  // background: rgba(255, 255, 255, .5);
  background: var(--background);
  border-radius: 4px;
  .aspirin-table-search-form-order-item-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    > span{
      &:first-child{
        color: #5D6268;
        font-size: 16px;
      }
      &:last-child{
        color: #343B42;
        font-weight: bold;
      }
    }
  }
  .aspirin-table-search-form-order-left-box{
    width: 70%;
  }
  .aspirin-table-search-form-order-right-box {
    width: 30%;
    display: flex;
    gap: 56px;
    .aspirin-table-search-form-order-item-box{
      width: 108px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span{
        &:first-child{
          color: #5D6268;
          font-size: 16px;
        }
        &:last-child{
          color: #343B42;
          font-weight: bold;
        }
      }
    }
  }
}

.aspirin-table-check-item-box{
  display: flex;
  justify-content: flex-end;
}
@root-entry-name: default;