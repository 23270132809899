.mySteps {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &_step {
    width: 100%;
    display: flex;
    align-items: center;
    &_line {
      // width: 128px;
      height: 0px;
      opacity: 1;
      border-top: 1px solid;
      &_left {
        border-image: linear-gradient(
            90deg,
            rgba(174, 176, 179, 0),
            rgba(174, 176, 179, 1)
          )
          1 1;
      }
      &_right {
        border-image: linear-gradient(
            90deg,
            rgba(174, 176, 179, 1),
            rgba(174, 176, 179, 0)
          )
          1 1;
      }
      &_icon {
        margin-bottom: 4px;
        &_dot {
          width: 16px;
          height: 16px;
          background: #d6d8d9;
          border-radius: 16px;
          opacity: 1;
        }
      }
    }
    &_centerline {
      width: 240px;
      border-top: 1px solid #aeb0b3;
    }
  }
  &_content {
    // padding: 0 8px;
    width: 100%;
    display: flex;
    // justify-content: space-around;
    &_finished {
      font-size: 14px;
      font-weight: 400;
      color: #3276e8;
      text-align: center;
    }
    &_unfinished {
      font-size: 14px;
      font-weight: 400;
      color: #85898d;
      text-align: center;
    }
  }
}

@root-entry-name: default;