.examine_detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 0;
  &_header {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &_item {
      margin-bottom: 20px;
      box-sizing: border-box;
      width: 33%;
      display: flex;
      font-size: 14px;
      color: #5d6268;
      &_label {
        width: 80px;
        margin-right: 12px;
        box-sizing: border-box;
        color: #85898d;
        flex-shrink: 0;
      }
    }
  }
  &_tabel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    &_promise_table {
      .ant-table-thead .ant-table-cell {
        background-color: #e5f2ff;
        font-size: 16px;
        font-weight: 600;
        &::before {
          background-color: transparent !important;
        }
      }
      td.ant-table-cell {
        background-color: transparent;
      }

      .ant-pro-card {
        background-color: transparent;
      }

      .ant-table {
        background-color: transparent !important;
      }
    }
  }
  .checked_reoprt_detail_right {
    display: flex;
    flex-direction: column;
    flex: 1;
    &_double_up_outlined {
      transform: rotate(-90deg);
    }
    &_double_down_outlined {
      transform: rotate(90deg);
    }
  }
  .examine_detail_text {
    // display: flex;
    width: 100%;
    margin-top: 10px;
    .item {
      width: 50%;
      font-size: 14px;
      font-weight: bold;
      color: #85898d;
      .value {
        font-weight: normal;
        font-size: 14px;
        color: #85898d;
      }
    }
  }
}
.examine_detail_microorganism {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 0;
  overflow-y: scroll;
  &_title {
    font-size: 24px;
    font-weight: 600;
    color: #343b42;
    margin-bottom: 16px;
  }
  .aspirin-table-order {
    .ant-table-container > .ant-table-header > table > thead > tr > th {
      border-right: 1px solid transparent !important;
    }
    .ant-table-container > .ant-table-body > table > tbody > tr > td {
      border-right: 1px solid #d6d8d9 !important;
    }
    .ant-table-tbody > tr > td {
      background-color: #f1f6fa !important;
      border-bottom: 1px solid #d6d8d9 !important;
      height: 100%;
    }
  }
  .checked_reoprt_detail_right {
    display: flex;
    flex-direction: column;
    flex: 1;
    &_double_up_outlined {
      transform: rotate(-90deg);
    }
    &_double_down_outlined {
      transform: rotate(90deg);
    }
  }
  .examine_detail_text {
    display: flex;
    width: 100%;
    margin-top: 10px;
    .item {
      width: 50%;
      font-size: 14px;
      // font-weight: bold;
      color: #85898d;
      .value {
        font-weight: normal;
        font-size: 14px;
        color: #85898d;
      }
    }
  }
}

@root-entry-name: default;