.refund_drug_apply_layout {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 116px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 24px;

    >div {
      flex: 1;
      height: 100%;
    }

    >div:nth-of-type(1) {
      .vs-form-loading-container {
        height: 100%;

        >form {
          height: 100%;

          .ant-form-item-label {
            width: 108px;
            text-align: left;
          }
        }
      }
    }

    #RefundDrugApplySearchForm {
      .patientIdIs {
        width: 326;

        .suffix-cardImg-image {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .center {
    flex: 1;
    height: 0;
    margin-top: 16px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 4px 4px;

    .content {
      height: calc(100% - 128px);
      flex: 1;
      display: flex;
      align-items: flex-start;

      .left_table {
        flex: 1;
        width: 0;

        .ant-table-row-expand-icon-cell {
          background-color: #fff;

          >button {
            visibility: hidden;
          }
        }

        .table_header {
          width: 100%;
          padding-right: 24px;
          padding-left: 74px;
          height: 48px;

          .content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background-color: #e5f2ff;

            >div {
              font-size: 18px;
              font-weight: bold;
              color: #343b42;
              box-sizing: border-box;
            }

            >div:nth-of-type(1) {
              width: 60.5%;
              padding-left: 18px;
            }

            >div:nth-of-type(2) {
              width: 16%;
              // padding-left: 6px;
            }

            >div:nth-of-type(3),
            >div:nth-of-type(4) {
              width: 16%;
              text-align: right;
              padding-right: 18px;
            }
          }
        }

        thead {
          display: none;
        }

        .custom_table_row_class {
          background-color: #fff;

          .ant-table-cell {
            font-size: 15px;
            font-weight: 600;
            color: #343b42;
          }
        }

        .aspirin-child-table:only-child .ant-table {
          padding: 0;
        }

        .ant-table-expanded-row {
          table {
            tbody {
              .ant-table-cell {
                box-sizing: border-box;
                border: 1px solid rgba(214, 216, 217, 0.5) !important;
              }
            }
          }
        }
      }

      .right_text {
        width: 312px;
        height: 100%;
        margin-right: 24px;

        .label_header {
          height: 30px;
          display: flex;
          align-items: center;

          .title {
            font-size: 16px;
            font-weight: 400;

            .icon_label {
              color: #e34a4e;
            }
          }

          .label {
            font-size: 14px;
            font-weight: 400;
            color: #aeb0b3;
          }
        }

        .textarea_comp {
          width: 100%;
          height: calc(100% - 104px);
          margin-top: 10px;

          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #d6d8d9;
          outline: none;
          padding: 16px;
        }
      }
    }

    .filter_view {
      width: 100%;
      height: 68px;
      box-sizing: border-box;
      padding: 20px 24px 16px 24px;
      display: flex;
      align-items: center;

      >.ant-input-group-wrapper {
        margin-left: 32px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 2px 2px 2px 2px;
        display: flex;
        align-items: center;

        input {
          width: 100% !important;
          height: 32px;
          width: 98px;
          font-weight: 400;
          color: #85898d;
          background-color: transparent;
          border: none;
          padding-top: 0;
          padding-left: 4px;
        }

        .ant-input-group-addon {
          border: none;
          background-color: transparent;
          color: #85898d;
          padding-right: 0;
        }
      }
    }

    .footer {
      height: 60px;
      box-sizing: border-box;
      padding-right: 24px;
      padding-bottom: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .ant-table,
    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-table-expanded-row>td {
      background-color: transparent;
    }
  }
}
@root-entry-name: default;