@import '~@/style/vars.less';
.dispenses-medicine-layout {
  width: 100%;
  display: flex;
  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon
        ) {
        background: transparent !important;
      }
    }
  }
  .ant-table-cell-fix-right-first {
    background: transparent !important;
  }
  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }

  .ant-table-cell::before {
    content: none !important;
  }
  .left {
    // flex: 1;
    width: 494px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-space-align-center {
      align-items: flex-start !important;
    }
    .list-cell {
      flex: 1;
      width: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 5px;
      margin-right: 8px;

      font-size: 16px;
      font-weight: 400;
      color: #343b42;
      // padding-left: 5px;
      // padding-right: 5px;
    }
    .left-query {
      // padding: 24px;
      display: flex;
      align-items: flex-start;
    }
    .select {
      background-color: #cbe4ff;
    }
    .unselect-text-fayao {
      cursor: pointer;
    }
    .ant-form-item-label {
      flex-grow: 0;
      width: 80px;
      text-align: left;
    }
    
    .ant-space-item {
      width: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 60%;
    .top {
      min-height: 136px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      // padding: 24px;
      display: flex;
      flex-direction: column;
      .aspirin-cpoe-index-center-info {
        height: 100% !important;
        // .oper {
        //   display: none !important;
        // }
        .aspirin-header-user-info {
          margin: 0 12px;
        }
      }
      .ant-col {
        font-size: 16px;
        font-weight: 200;
        color: #343b42;
      }
      .font-line {
        display: inline;
        font-size: 18px;
        font-weight: 500;
        color: #343b42;
        margin-left: 10px;
      }
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
    }
    .bottom {
      flex: 1;
      background-color: rgba(255, 255, 255, 0.5);
      margin-top: 16px;
      .vs-base-table-container {
        height: calc(100% - 48px);
      }
    }
    .center {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      background-color: rgba(255, 255, 255, 0.5);
      padding-left: 24px;
      .ant-form-item {
        margin: 0 !important;
      }
    }
  }
}
.ant-modal-dispense-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #3276e8 !important;
}
.modal-dispense-font {
  font-size: 16px;
  font-weight: 400;
  color: #343b42;
}
.modal-dispense-font-content {
  font-size: 16px;
  font-weight: 500;
  color: #343b42;
}
.total-table-prescription {
  height: 40px;
  text-align: center;
  padding: 12px;
}
.total-chager-chufang {
  font-size: 18px;
  font-weight: 500;
  color: #343b42;
}
.text-parents-style {
  font-size: 15px;
  font-weight: 500;
  color: #343b42;
}
.belt-list-lable-classname {
  flex: 1;
  font-weight: bolder;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #343b42;
  margin-left: 10px;
  margin-right: 10px;
}
.belt-list-lable-row-classname {
  height: 44px;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

@root-entry-name: default;