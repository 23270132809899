.model {
  width: 100%;
  height: 100%;
  .model_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  #medicalCertificate {
    border: 1px solid #cecece;
    margin: 20px 0;
    padding: 10px 20px;
  }
  .model_footer {
    display: flex;
    // font-weight: bold;
    color: #ccc;
    align-items: center;
    justify-content: center;
  }
}

@root-entry-name: default;