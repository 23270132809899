.homepageinfo {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &_top {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .ant-input-number-group-addon,
    .ant-input-group-addon {
      background-color: #eaf3fe;
    }

    .homepageinfo_patient_info,
    .homepageinfo_diagnostic_info {
      width: 100%;
      flex: 1;
    }

    // 患者信息
    .homepageinfo_patient_info {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    // 诊断信息
    .homepageinfo_diagnostic_info,
    .homepageinfo_operation_info {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;

      &_table {
        margin-bottom: 18px;
        height: 0;
        flex: 1;
        overflow: hidden;
      }

      &_form {
        padding-bottom: 16px;
        border-top: 1px solid #5494ff;
        height: 144px;
        padding-top: 14px;
        // overflow-x: hidden;
        // overflow-y: scroll;
      }
    }

    // 手术信息
    .homepageinfo_operation_info {
      &_table {
        .aspirin-dictionary {
          margin: 0;
        }
      }
    }

    // 其他信息
    .homepageinfo_other_info {
      #otherInfoForm {
        .ant-form-item-explain {
          .ant-form-item-explain-error {
            width: 220px !important;
          }
        }

        .other_info_date_view {
          &_text {
            font-size: 18px;
          }
          &_inp {
            width: 50px;
          }
          //   .respiratorUseDay {
          //     width: 45px;
          //   }

          //   .respiratorUseHour_form {
          //     margin-left: 15px;

          //     .respiratorUseHour_form_field {
          //       width: 45px;
          //     }

          //     .respiratorUseHour_form_add {
          //       margin-left: -115px;
          //     }
          //   }

          //   .respiratorUseMinute {
          //     margin-left: -135px;

          //     .respiratorUseMinute_field {
          //       width: 45px;
          //     }

          //     .respiratorUseMinute_add {
          //       margin-left: -236px;
          //     }
          //   }
        }
      }

      .form_item_text {
        // margin-left: -10px;
        margin-left: 2px;
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #85898d;
      }

      .ant-table-tbody {
        border: 1px solid #e7e7e7 !important;

        .ant-table-placeholder {
          border: 1px solid #e7e7e7 !important;

          .ant-table-cell {
            border: 1px solid #e7e7e7 !important;
          }
        }
      }
    }

    // 费用信息
    .homepageinfo_cost_info {
      margin: 0 auto;
      width: 1352px;
      font-size: 16px;

      .total {
        padding: 0 24px 12px;
        // height: 24px;
        border-bottom: 1px solid #3276e8;
        display: flex;

        &_left {
          margin-right: 24px;
          width: 160px;
          font-size: 16px;
          font-weight: 600;
          color: #343b42;
          flex-shrink: 0;
        }

        &_right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          &_item {
            margin-right: 5px;
            flex-shrink: 0;
            width: 31%;
            display: flex;

            &_label {
            }

            &_value {
              font-weight: bolder;
            }
          }
        }
      }

      .list {
        font-size: 16px;

        &_item {
          padding: 16px 24px;
          min-height: 48px;
          border-bottom: 1px solid #e7e7e7;
          display: flex;

          &_left {
            flex-shrink: 0;
            margin-right: 24px;
            width: 160px;
            font-size: 16px;
            font-weight: 600;
            color: #343b42;
          }

          &_right {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            &_item {
              margin-right: 5px;
              flex-shrink: 0;
              width: 31%;
              display: flex;

              &_label {
              }

              &_value {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  &_bottom {
    padding-top: 16px;
    // height: 48px;
    height: 4rem;
    display: flex;
    justify-content: flex-end;
  }
}

@root-entry-name: default;