.aspirin-medical-order-template-defend-box {
  background: var(--background);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 24px;
  .aspirin-medical-order-template-defend-nav-select-box {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    .aspirin-medical-order-template-defend-left-box {
      gap: 10px;
      // width: 40%;
      display: flex;
      align-items: center;
    }
  }
  .aspirin-medical-order-template-defend-left-grid-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    // ::-webkit-scrollbar{
    //   width: 0;
    // }
    // &:hover{
    //   &::-webkit-scrollbar{
    //     width: 4px;
    //   }
    // }
    .ant-ribbon-placement-end {
      z-index: 1 !important;
    }
    @keyframes Card {
      to {
        transform: scale(1);
      }
      from {
        transform: scale(1.1);
      }
    }
    .aspirin-medical-order-template-defend-card-box {
      cursor: pointer;
      border: 2px solid #f0f0f0;
      border-radius: 4px;
      &:first-child {
        margin-top: 8px;
      }
      transition: all ease-out 0.5s;
      .ant-card-actions {
        display: flex;
        justify-content: flex-start !important;
        transition: all ease-out 0.5s;
      }
      .active-item {
        border: 2px solid var(--primary-color);
        background-color: var(--primary-color-3);
        .ant-card-head {
          border-bottom: 1px solid var(--primary-color) !important;
        }
        .ant-card-actions {
          border-top: 1px solid var(--primary-color) !important;
          background-color: var(--primary-color-3);
        }
      }
      &:hover {
        .active-item();
      }
      &.active {
        .active-item();
        animation: Card 0.2s ease-in-out;
      }
    }
  }
  .aspirin-medical-order-template-main-defend-box {
    gap: 10px;
    .extra-operation {
      display: flex;
      justify-content: space-between;
      height: 70px;
      align-items: center;
      > div {
        display: flex;
        &.right {
          gap: 30px;
          align-content: center;
          > div {
            display: flex;
            height: 100%;
            gap: 5px;
            cursor: pointer;
            color: var(--primary-color);
            // transform: scale(1);
            // transition: transform .5s ease-out;
            // &:active{
            //   transform: scale(1.6);
            // }
          }
        }
      }
    }
  }
}

.medical-order-template-tree-row-box {
  margin-right: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  height: 30px;
  width: 30px;
  transition: background 0.3s;

  &:hover {
    background: var(--primary-color-1);
    svg {
      fill: white;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

@root-entry-name: default;