.nurse_cancel_operation {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &_search {
    padding: 0 24px;
    margin-bottom: 16px;
    height: 64px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &_body {
    padding: 16px 24px;
    flex: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  &_drawer_footer {
    display: flex;
    justify-content: flex-end;
    .marginLeft {
      margin-left: 15px;
    }
    .close {
      background: #f0f0f0;
      color: #5d6268;
    }
  }
}
.cancel_out_hospital_modal {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #fff;
  }
}

@root-entry-name: default;