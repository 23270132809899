.nurse_station_transfer {
  display: flex;
  height: 100%;
  width: 100%;
  &_table {
    padding: 20px 24px 24px;
    margin-right: 16px;
    width: 494px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
  }
  &_detail {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    &_search {
      margin-bottom: 16px;
      padding: 0 24px;
      width: 100%;
      height: 60px;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      &_item {
        margin-right: 56px;
        display: flex;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        &_label {
          width: 80px;
          font-size: 16px;
          font-weight: 400;
          color: #343b42;
        }
        &_input {
          flex-wrap: nowrap;
        }
      }
    }
    &_content {
      padding: 24px;
      height: 0;
      flex: 1;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      &_form {
        height: 0;
        flex: 1;
        // height: 100%;
        overflow-y: scroll;
      }
      // overflow: hidden;
      &_form_title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        &_tag {
          margin-top: 2px;
          margin-right: 12px;
          width: 4px;
          height: 20px;
          background-color: #3276e8;
        }
      }
      &_bottom {
        // padding: 0 16px;
        padding-top: 10px;
        height: 45px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        text-align: center;
        justify-content: flex-end;
        &_delete {
          margin: auto 0;
          width: 80px;
          height: 32px;
          // line-height: 32px;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #3276e8;
          cursor: pointer;
          color: #3276e8;
        }
        &_save {
          cursor: pointer;
          margin: auto 0;
          margin-left: 16px;
          width: 80px;
          // height: 32px;
          // line-height: 32px;
          background: #3276e8;
          border-radius: 2px 2px 2px 2px;
          color: #ffffff;
        }
      }
    }
  }
}

@root-entry-name: default;