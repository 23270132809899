.drug_storage_profile_wapper {
  .search_header_check_view {
    margin-left: 9px;

    .ant-checkbox-wrapper {
      > span:nth-of-type(2) {
        text-indent: 0;
      }
    }
  }

  .vs-base-table-container {
    height: calc(100% - 55px);

    .ant-select-selection-overflow {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 24px;

    > button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
  .ant-form-item-control-input-content {
    font-size: 18px !important;
  }
}

@root-entry-name: default;