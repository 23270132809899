@import '~@/style/vars.less';

.@{prefix}-cpoe-center {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .aspirin-block {
    padding: 0 !important;
  }

  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;
    overflow-x: auto;
    // padding-top: 24px;

    .aspirin-tab {
      width: 100%;
      .ant-tabs-nav::before,
      .ant-tabs-top > div > .ant-tabs-nav::before {
        border-bottom-width: 0;
      }

      .ant-tabs-nav-wrap {
        padding: 0 24px;
        height: 38px;
        display: flex;
        align-items: center;
        font-size: 14px;
      }

      .tab-item-block {
        width: 80px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-radius: 2px;
      }

      .ant-tabs-tab-active {
        .tab-item-block {
          background-color: rgba(84, 148, 255, 0.1);
        }
      }

      .anticon {
        margin-right: 4px;
      }

      .ant-tabs-nav-list {
        height: 64px;
      }

      .ant-tabs-tab {
        font-weight: 500;
        font-size: 14px;
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 36px;
      }

      .ant-tabs-ink-bar {
        width: 0 !important;
      }
    }
  }
}

@root-entry-name: default;