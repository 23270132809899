.doctor_station_my_bed_card_list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;

  &_detail_item {
    box-sizing: border-box;
    margin: 0 0px 18px 0;
    width: 299px;
    min-height: 110px;
    height: 198px;
    flex-shrink: 0;
    cursor: pointer;
    &_content {
      height: 100%;
      width: 100%;
      width: 280px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #d6d8d9;
      // border-top: 1px solid transparent;
      &_topbgc {
        width: 100%;
        height: 4px;
        &_teji {
          background-color: #e34a4e;
        }
        &_yiji {
          background-color: #ff7d45;
        }
        &_erji {
          background-color: #ffd900;
        }
        &_sanji {
          background-color: #aeb0b3;
        }
        &_other {
          background-color: #fff;
        }
      }
      &_body {
        height: 0;
        flex: 1;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        &_item1 {
          height: 106px;
          flex: 1;
          &_tag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            &_text {
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #85898d;
              flex-shrink: 0;
              &_num {
                margin-right: 4px;
                font-size: 20px;
                font-weight: 500;
              }
              // 待转科
              &_num1 {
                color: #3276e8;
              }
              // 当天新入科
              &_num2 {
                color: #30a313;
              }
              // 明日出院
              &_num3 {
                color: #cd8aed;
              }
              // 今日出院
              &_num4 {
                color: #e34a4e;
              }
              &_num5 {
                color: #343b42;
              }
            }
            &_icon {
              margin-left: 5px;
              flex-shrink: 0;
            }
          }
          &_info {
            margin: 2px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 28px;
            &_name {
              margin-right: 5px;
              font-size: 18px;
              font-weight: bolder;
              color: #343b42;
            }
            &_age {
              font-size: 14px;
              font-weight: 400;
              color: #343b42;
            }
          }
          &_type {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #85898d;
            &_text {
              // height: 10px;
              padding: 2px 4px;
              background: #f5f7fa;
              border-radius: 2px 2px 2px 2px;
              color: #343b42;
              font-size: 12px;
            }
          }
        }
        &_item2 {
          margin: 14px 0 0;
          padding: 0 0 14px;
          min-height: 66px;
          border-top: 1px dashed #aeb0b3;
          &_content {
            padding: 5px 9px 6px;
            margin-top: 8px;
            display: flex;
            flex-wrap: wrap;
            border-radius: 4px 4px 4px 4px;
            background: #f5f5f5;
            &_item {
              font-size: 12px;
              font-weight: 500;
              color: #5d6268;
              width: 50%;
              cursor: pointer;
              &_value {
                color: #343b42;
              }
            }
            &_type {
              margin-top: 4px;
              display: flex;
              &_item {
                margin-right: 8px;
                width: 28px;
                height: 20px;
                background: #ffffff;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
                border: 1px solid #e7e7e7;
                font-size: 12px;
                font-weight: 400;
                color: #85898d;
                text-align: center;
                line-height: 17px;
                cursor: pointer;
              }
              &_ya {
                border: 1px solid #ffa57e;
                color: #ffa57e;
              }
              &_die {
                border: 1px solid #c395fe;
                color: #c395fe;
              }
              &_teng {
                border: 1px solid #ff7b7b;
                color: #ff7b7b;
              }
              &_hua {
                color: #739fea;
                border: 1px solid #739fea;
              }
              &_vte {
                width: 39px;
                border: 1px solid #9bd78c;
                color: #9bd78c;
              }
            }
          }
        }
      }
      &_detail_card_empty {
        padding: 0 17px;
        &_bed {
          position: absolute;
          width: 292px;
          height: 198px;
          // background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &_number {
            height: 45px;
            line-height: 54px;
            font-size: 20px;
            font-weight: 500;
            color: #343b42;
          }
          &_icon {
            text-align: center;
          }
          &__name {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #5d6268;
          }
        }
      }
    }
    // &_content1 {
    //   width: 292px;
    //   min-height: 110px;
    //   padding-right: 0;
    //   border-radius: 4px;
    //   margin: 10px 0 0 10px;
    // }
  }
}

@root-entry-name: default;