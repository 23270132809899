@import '~@/style/vars.less';
.inpatient-prescription-dispensing-layout {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon
        ) {
        background: transparent !important;
      }
    }
  }
  .ant-table-cell-fix-right-first {
    background: transparent !important;
  }
  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }
  .btn-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hemp-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .hemp-detaill {
      // height: 80px;
      flex: 1;
    }
    .hemp-table {
      display: flex;
      flex: 3;
    }
    .btn-footer {
      height: 80px;
      background-color: pink;
      flex: 1;
    }
  }
  .bed-text-style {
    font-size: 16px;
    font-weight: 500;
    color: #343b42;
  }
  .danger-text-color{
    color: #E34A4E !important;
    font-weight: 400;
    font-size: 14px;
  }
  .enter-font-open-style {
    font-weight: 400;
    color: #3276e8;
    font-size: 14px;
    cursor: pointer;
  }

}
.aspirin-table-row-background-box{
  background: #fff !important;
  color: var(--primary-color);
  font-weight: bold;
  td{
    border: none !important;
  }
}

@root-entry-name: default;