.centre-dispenses-medicine-layout {
  width: 100%;
  display: flex;

  .ant-table-cell::before {
    content: none !important;
  }

  .left {
    // flex: 1;
    width: 494px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-space-align-center {
      width: 100%;
      align-items: flex-start !important;

      .ant-space-item {
        width: 100%;
      }
    }

    .list-lable-font {
      font-size: 18px;
      font-weight: bold;
      color: #343b42;
      box-sizing: border-box;
      padding-left: 12px;
    }

    .ant-list-item {
      cursor: pointer;
    }

    .list-cell {
      flex: 1;
      width: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px;
      margin-right: 10px;

      font-size: 16px;
      font-weight: 400;
      color: #343b42;
      // padding-left: 5px;
      // padding-right: 5px;
    }

    .left-query {
      // padding: 24px;
      display: flex;
      align-items: flex-start;
    }

    .select {
      background-color: #cbe4ff;
    }

    // .ant-form-item-label {
    //   flex-grow: 0;
    //   width: 80px;
    //   text-align: left;
    // }

    .list {
      flex: 1;
      height: 0;
      box-sizing: border-box;

      .activity_row_class {
        background-color: #fff;
      }

      .vs-base-table-container {
        height: calc(100% - 52px);
      }

      .ant-pro-table {
        width: 0;
      }

      .ant-table,
      .ant-table-wrapper,
      .ant-spin-nested-loading {
        background-color: transparent;
      }

      .ant-table {
        thead {
          tr {
            th {
              background-color: #e5f2ff;
              text-align: center;
              font-size: 16px;
              font-weight: bold;
              color: #343b42;
            }
          }
        }

        tbody {
          td {
            box-sizing: border-box;
            border-left: 1px solid #d6d8d9;
            border-top: 1px solid #d6d8d9;
            text-align: center;
            border: none;
            font-size: 16px;
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;

    // align-items: center;
    .info {
      height: 300px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0 !important;

      .vs-form-loading-container {
        padding: 0 12px;
      }

      > .patient_info {
        padding: 12px 6px 0;
        display: flex;
        flex-direction: column;

        > .ant-row {
          margin-bottom: 14px;
        }

        .components_user_info {
          .user-info-container {
            .info-item {
              height: auto !important;
            }
          }
        }

        .font-line {
          display: inline;
          font-size: 14px !important;
          font-weight: bold;
          color: #343b42;
        }
      }

      .split_line {
        width: 100%;
        height: 1px;
        opacity: 1;
        background-color: #3276e8;
        margin-bottom: 12px;
        margin-top: 17px;
      }

      .vs-form-loading-container {
        form {
          .ant-form-item {
            margin-bottom: 0;

            .ant-col {
              font-size: 14px !important;
              // font-weight: 200;
              font-weight: 400 !important;
              color: #343b42;
            }

            .ant-form-item-control-input {
              display: inline;
              font-size: 14px !important;
              font-weight: bold;
              color: #343b42;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
    }

    .list {
      flex: 1;
      height: 0;
      background-color: rgba(255, 255, 255, 0.5);
      margin-top: 16px;
      box-sizing: border-box;
      padding-top: 20px;

      .activity_row_class {
        background-color: #fff;
      }

      .vs-base-table-container {
        height: calc(100% - 52px);
      }

      .ant-pro-table {
        width: 0;
      }

      .ant-table,
      .ant-table-wrapper,
      .ant-spin-nested-loading {
        background-color: transparent;
      }

      .ant-table {
        thead {
          tr {
            th {
              background-color: #e5f2ff;
              text-align: center;
              font-size: 16px;
              font-weight: bold;
              color: #343b42;
            }
          }

          // tr > th:nth-of-type(1),
          // tr > th:nth-of-type(2),
          // tr > th:nth-of-type(3),
          // tr > th:nth-of-type(4),
          // tr > th:nth-last-of-type(1) {
          //   text-align: center;
          // }
        }

        tbody {
          td {
            box-sizing: border-box;
            border-left: 1px solid #d6d8d9;
            border-top: 1px solid #d6d8d9;
            text-align: center;
            border: none;
          }

          tr > td:last-child {
            border-right: 1px solid #d6d8d9;
          }
        }

        tbody > tr:last-child > td {
          border-bottom: 1px solid #d6d8d9;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      background-color: rgba(255, 255, 255, 0.5);
      padding-left: 24px;
      border-top: 1px solid #3276e8;

      form {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ant-modal-dispense-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #3276e8 !important;
}

.modal-dispense-font {
  font-size: 16px;
  font-weight: 400;
  color: #343b42;
}

.modal-dispense-font-content {
  font-size: 16px;
  font-weight: 500;
  color: #343b42;
}

.total-table-prescription {
  height: 40px;
  text-align: right;
  padding: 12px;
}

.row_font_size {
  .ant-table-cell {
    // font-size: 14px !important;
    height: 44px;
  }

  .ant-table-thead {
    font-size: 14px !important;
    height: 48px;
  }
}

@root-entry-name: default;