.drug_manager .ant-form-item-label {
    width: 120px !important;
    white-space: normal;
    text-align: left;
}

.drug_manager {

    .decoctionPieceWaterAbsorption,
    .ddd {
        width: 100%;
    }

    .custom_width120 {
        width: 120px;
    }
}

.drug_manager .drug_manager_custom_label_width .ant-form-item-label {
    width: 90px !important;
}

.drugDicEditor .vs-base-table-container {
    height: auto !important;
}

.drug_dic_editor_block {
    height: calc(100vh - 217px);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0;
}
@root-entry-name: default;