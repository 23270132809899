// 收费模板
#TemplateModalContainer {
  display: flex;
  width: 100%;
  border-bottom: 1px #ccc solid;
  .left,
  .right {
    height: 100%;
  }
  .left {
    width: 30%;
    border-right: 1px #ccc solid;
  }
  .right {
    width: 70%;
  }
  .vs-base-table-container {
    width: 100% !important;
  }
}
#TemplateModalTitle {
  cursor: pointer;
  color: rgba(50, 118, 232, 1);
  display: inline-block;
}
#TemplateConfirmComponent {
  .aspirin-vs-table-row-box td:last-child > div {
    display: inline-block !important;
  }
  background: rgba(255, 255, 255, 0.3);
  .ant-table-thead {
    tr > th {
      text-align: center;
    }
  }
  .ant-table-tbody {
    tr {
      text-align: center;
    }
  }
}

@root-entry-name: default;