.toolbar-layout {
  display: flex;
  align-items: center;
}
.toolbar-content {
  .ant-form-item {
    margin: 0 !important;
  }
  text-indent: 0;
  margin-left: 12px;
}
@import '~@/style/vars.less';
.layout-optpatient {
  // background-color: rgba(255, 255, 255, 0.5);
  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon,
          .ant-switch,
          .ant-switch-checked
        ) {
        background: transparent !important;
      }
    }
  }
  .ant-table-cell-fix-right-first {
    background: transparent !important;
  }
  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }
}

@root-entry-name: default;