.receipt-inquiry-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
//   justify-content: space-between;
  //   background-color: #fff;
  .receipt-inquiry-top {
    // height: 164px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 24px;
    padding-bottom: 0;

    #ReceiptInquiryForm {
      .identificationNumber {
        .identificationNumber-select {
          width: 150px;
        }
      }
      .suffix-cardImg-image {
        width: 20px;
        height: 20px;
      }
    }
  }
  .receipt-inquiry-bottom {
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
  }
}

@root-entry-name: default;