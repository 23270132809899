.ApplicationInpHerbRefundPerform {
  #InpHerbDetailsContainer {
    width: 100%;
    height: 100%;
    .title {
      text-align: center;
      font-size: 28px;
      margin: 0;
    }
    .table_head {
      td {
        background: rgb(255, 255, 255) !important;
      }
    }
  }
  #search_comtainer {
    height: 100%;
  }
}

@root-entry-name: default;