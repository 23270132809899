.drug_specification_storage_wrapper {

  .vs-base-table-container {
    height: calc(100% - 55px);

  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 24px;

    >button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
}
@root-entry-name: default;