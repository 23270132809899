.previous_history {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // height: 100%;
  flex-shrink: 1;
  overflow-y: scroll;

  &_add {
    // margin-right: 34px;
    box-sizing: border-box;
    width: 44px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #3276e8;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    font-weight: 400;
    color: #3276e8;
    cursor: pointer;
  }
  &_option {
    display: flex;
    color: #3276e8;
    cursor: pointer;
    &_item {
      margin-right: 12px;
      box-sizing: border-box;
    }
  }
  &_list {
    // margin-bottom: 30px;
    box-sizing: border-box;
    width: 48%;
    &_tabel {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      .ant-table-thead .ant-table-cell {
        background-color: #dbedff !important;
        background-color: transparent;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid #98b5e3;
        &::before {
          background-color: transparent !important;
        }
      }
      td.ant-table-cell {
        background-color: transparent;
      }

      .ant-pro-card {
        background-color: transparent;
      }

      .ant-table {
        background-color: transparent !important;
        &-cell-fix-right {
          background-color: #f0f8ff !important;
        }
      }
    }
  }
  &_diseas_type {
    // width: 48px;
    padding: 0 8px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    .treatDescription {
      overflow: hidden; // 溢出的内容隐藏
      text-overflow: ellipsis; // 溢出的部分用省略号...显示
      -webkit-line-clamp: 2; // 行数
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
      -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
    }
    &_anxious {
      color: #f54141;
      background-color: rgba(243, 88, 89, 0.1);
    }
    &_slow {
      color: #ff7d45;
      background-color: rgba(255, 125, 69, 0.1);
    }
    &_cure {
      color: #85898d;
      background-color: #dfe7ed;
    }
  }
  &_allergys_type {
    // min-width: 50px;
    // display: flex;
    padding: 0 8px;
    min-height: 24px;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    &_food {
      background-color: #fff7ea;
      color: #ffc188;
    }
    &_medicine {
      color: #a485ff;
      background-color: #f1ecff;
    }
    &_environment {
      color: #30a313;
      background-color: #d3ebe3;
    }
    &_max {
      color: #343b42;
      background-color: #d4e0e7;
    }
    &_other {
      color: #3276e8;
      background-color: #d6e6f7;
    }
    &_cure {
      color: #85898d;
      background-color: #dfe7ed;
    }
  }
  &_tabletoolbar {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    &_div {
      width: 5px;
      height: 20px;
      background-color: #3276e8;
      margin-right: 16px;
    }
    &_addBtn {
      background-color: transparent;
      color: #3276e8;
    }
  }
}
.allergy_type_bgc {
  .ant-radio-button-wrapper-in-form-item {
    font-weight: bold !important;
    color: #85898d !important;
  }
  .ant-radio-button,
  .ant-radio-button-checked {
    background-color: #ebf2ff !important;
  }
  .ant-radio-button-wrapper-checked {
    color: #3779e9 !important;
    font-weight: bold !important;
  }
}
.add_disease_modal {
  .ant-input-number-group-addon {
    padding: 0 !important;
  }
}

@root-entry-name: default;