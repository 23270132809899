.OutpMedicalTechnologyCharge {
  @border:1px #ccc solid;
  width: 100% !important;
  height: 100 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .UserForm,
  .TableDetails {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.3);
  }
  .UserForm {
    width: 100%;
    height: 14%;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .Descriptions {
      .vs-descriptions-item {
        font-size: 16px;
        .vs-descriptions-item-label {
          text-align: left;
          color: rgba(93, 98, 104, 1);
        }
      }
    }
  }
  .TableDetails {
    width: 100%;
    height: 84%;
    .ant-menu {
      background: rgba(255, 255, 255, 0.3);
    }
    .container {
      width: 100%;
      height: calc(100% - 100px);
      border-bottom: @border;
      overflow: hidden;
      padding: 20px 20px 0;
      position: relative;
      .title {
        margin: 0 !important;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        left: 6px;
        padding-left: 6px;
        &::before {
          position: absolute;
          top: 50%;
          left: -6px;
          transform: translateY(-50%);
          content: '';
          width: 6px;
          height: 100%;
          background: rgba(50, 118, 232, 1);
          display: inline-block;
        }
      }
      #NotDeatail,
      #DoDeatail {
        width: 100%;
        height: 100%;
      }

      #DoDeatail {
        #doctor,
        #expense {
          width: 100%;
          height: 50%;
          overflow: hidden;
        }
      }

      #NotDeatail {
        display: flex;
        #left {
          width: 14%;
          height: 100%;
          border-right: @border;
        }
        #right {
          width: 86%;
          height: 100%;
          padding-left: 20px;
        }
      }
    }
    .footer {
      width: 100%;
      padding: 6px 20px 0 0;
    }
  }
}
// 表格样式；
#outpMedicalTechnologyChargeNoOrderChargeBillingDetailList,
#outpMedicalTechnologyChargeNoOrderChargeDateList,
#outpMedicalTechnologyChargeOrderBillingDetailList,
#outpMedicalTechnologyChargePatientListModelList,
#outpMedicalTechnologyChargeOrderList,
#inpMedicalTechnologyChargeTemplateDetailList {
  .aspirin-vs-table-row-box td:last-child > div {
    display: inline-block !important;
  }
  background: rgba(255, 255, 255, 0.3);
  .ant-table-thead {
    tr > th {
      text-align: center;
      background: rgba(229, 242, 255, 1);
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    tr {
      text-align: center;
      td {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

@root-entry-name: default;