.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../assets/login/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  .login-image-2 {
    background-image: url('../../assets/login/bg-2.png');
    // background-repeat: no-repeat;
    background-size: contain;
    // background-position: right;
    height: 617px;
    width: 617px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .login-block {
    user-select: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 660px;
    padding: 0 130px;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 48px;
      font-weight: bold;
      color: #3276e8;
      margin-bottom: 88px;
      position: relative;
      align-self: flex-start;
      &::after {
        content: '';
        width: 192px;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: -12px;
        background-color: #3276e8;
      }
    }
.vs-form-loading-container{
  width: 100% !important;
}
    .login-form {
      width: 100%;
      form{
        width: 100% !important;
      }
      .ant-form-item{
        margin-bottom: 36px !important;
      }

      .ant-input-affix-wrapper{
        border-width: 0;
        background: #eef0f5 !important;
        border-radius: 4px;
        height: 52px;
        line-height: 52px;
        padding: 0 21px;

        input {
          background: #EEF0F5;
          font-size: 20px;
          font-weight: 400;
          text-indent: 12px;
          // color: #aeb0b3;
        }
      }
      .ant-select-selector{
        border-radius: 4px;
        height: 52px;
        line-height: 52px;
        padding: 0 21px;
        input {
          font-size: 20px;
          font-weight: 400;
          text-indent: 12px;
        }
        .ant-select-selection-placeholder{
          font-size: 20px !important;
          display: flex;
          align-items: center;
        }
        .ant-select-selection-item{
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 400;
          text-indent: 12px;
        }
      }


      .extra-oper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        font-size: 20px;

        .ant-checkbox-wrapper {
          font-size: 20px !important;
        }

        .ant-btn {
          font-size: 20px;
        }
      }

      .login-form-button {
        border-radius: 4px;
        background: linear-gradient(90deg, #4aacf5 0%, #2354dd 100%);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 52px;
      }
      .login-form-button-reset{
        border-radius: 4px;
        background: #F0F0F0;
        display: flex;
        color: #85898D;
        justify-content: center;
        align-items: center;
        border-width: 0;
        font-size: 20px;
        height: 52px;
        font-weight: 500;
        line-height: 52px;
      }
    }
  }

  .other-method {
    margin: 24px 0 75px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 16px;
    font-weight: 400;

    > span {
      color: #85898d;
    }

    .ant-btn {
      color: #3276e8;
    }
  }

  .login-divider {
    > span {
      position: relative;
      top: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #aeb0b3;
    }
  }

  .other-way-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anticon {
      font-size: 24px;
      margin: 0 2px;
      cursor: pointer;
    }
  }
}

@root-entry-name: default;