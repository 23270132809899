.aspirin-inputUnitDynamic-select {
  .ant-select-selection-item {
    padding: 0 !important;

    &::after {
      display: none;
    }
  }

  .ant-select-selector {
    &::after {
      display: none;
    }
  }

  .ant-select-arrow {
    display: none;
  }
}

@root-entry-name: default;