.drug_stock_split_wapper {
  .ant-pro-table-search-custom {
    margin-bottom: 16px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;
    .ant-pro-table-search-form {
      width: calc(100% - 100px) !important;
      .ant-form-item {
        width: auto !important;
      }
    }
  }

  .vs-base-table-container {
    height: calc(100% - 60px);
  }

  .ant-pro-table-search {
    flex: 1;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-left: 22px;

    > form {
      height: 100%;
      display: flex;
      align-items: center;

      .ant-form-item {
        margin: 0;
      }

      button {
        margin-left: 34px;
      }
    }
  }

  .footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 24px;
    background: #fff;
  }
}

@root-entry-name: default;