.patient_list_sign {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  opacity: 1;
  border: 1px solid #3276e8;
  font-size: 8px;
  font-weight: 500;
  color: #3276e8;
  text-align: center;
  line-height: 13px;
  margin-right: 10px;
}
.aspirin-table-form-search-hide-box {
  .ant-btn {
    font-size: 12px;
    color: #fff;
    background-color: #69a1ff;
    border-radius: 4px;
    border-width: 0;
    padding: 0 10px;

    + .ant-btn {
      margin-left: 12px;
    }
  }
}

@root-entry-name: default;