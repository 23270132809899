.aspirin-select {
  min-width: 100px;
  width: 100%;
}

.aspirin-tree-select {
  min-width: 100px;
  width: 100%;
}


.aspirin-select-tags-single {
  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      background: transparent;
      border: none;
      padding-right: 0 !important;
      margin-right: 0 !important;

      .ant-select-selection-item-remove {
        display: none;
      }
    }
  }
}

@root-entry-name: default;