.appointment-container-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: relative;

  .ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
    width: 100%;
  }

  .ant-popover-inner-content {
    width: max-content;
    max-width: 100%;
    padding: 24px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .register-cart-top {
    flex: 3;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    display: flex;
    overflow: auto;
    height: 100%;

    .register-cart-top-left {
      width: 276px;
      // border-right: 1px solid #3276E8;
    }

    .register-cart-top-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 0;
      margin-left: 24px;

      .register-cart-serach {
        margin-top: 14px;
        margin-bottom: 6px;
        margin-right: 24px;
        height: 72px;
        // border: 1px solid pink;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: space-between;

        .serach-input {
          display: flex;
          align-items: center;
          flex: 1;

          .serach-input-inp {
            width: 200px;
          }
        }

        .select-cart-show {
          display: flex;
          align-items: center;
          margin-left: 24px;
          flex: 1;

          .select-cart-show-title {
            font-size: 16px;
            font-weight: 400;
            color: #343b42;
          }

          .select-cart-show-content {
            margin-right: 6px;
            cursor: pointer;
          }
        }

        .bill-number {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: flex-end;

          .select-cart-number {
            margin-left: 74px;
          }

          .select-cart-farewell {
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-right: 24px;
            width: 250px;
            height: 32px;
            // background: rgba(50, 118, 232, 0.1);
            border-radius: 2px;

            .ant-picker {
              width: 250px;
            }
          }
        }
      }

      .register-cart-main-label {
        display: flex;
        align-items: center;
        height: 40px;
        background: #e5f2ff;
        font-size: 18px;
        color: #343b42;
        // margin-right: 24px;
        font-weight: 500;

        .week {
          display: inline;
          font-weight: 500;
          color: #85898d;
        }

        .table-title-layout {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          justify-content: space-between;
        }
      }

      .register-cart-main {
        flex: 1;
        display: flex;
        overflow: auto;

        .register-cart-main-container {
          border-radius: 4px;
          flex: 1;
          position: relative;

          .register-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .register-loadding {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .register-cart-main-title {
            display: flex;
            align-items: center;
            height: 40px;
            background: #dbedff;
            font-size: 16px;
            margin-right: 24px;
            margin-top: 12px;

            .table-title-layout {
              width: 100%;
              margin-top: 16px;
              display: grid;
              grid-template-columns: repeat(8, 1fr);
              justify-content: space-between;
            }

            .register-cart-main-title-before {
              position: relative;
              margin-left: 30px;
              font-weight: 500;
              color: #343b42;

              &::before {
                position: absolute;
                width: 5px;
                height: 20px;
                background: #3276e8;
                content: '';
                transform: translate(-50%, -50%);
                top: 50%;
                left: -12px;
              }
            }
          }

          .register-cart-main-content {
            width: 100%;
            margin-top: 16px;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            justify-content: space-between;
            place-items: center;
            gap: 10px;
          }
        }
      }
    }
  }

  .register-serach-bottom {
    background: rgba(255, 255, 255, 0.5);
    overflow: auto;
    padding: 24px;
  }
}


.select-status-after {
  background: transparent !important;
  border: 2px solid red;
}

.select-status-before {
  background: #fff !important;

}
@root-entry-name: default;