.aspirin-empty-container-main-box{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  inset: 0;
  .aspirin-empty-container-box {
    width: 414px;
    height: 300px;
    position: absolute;
    .word {
      color: #85898d;
      font-size: 16px;
      position: absolute;
      bottom: 34px;
      left: 0;
      text-align: center;
      width: 100%;
    }
  }
}

@root-entry-name: default;