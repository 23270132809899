.doctor_station_transfer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  &_content {
    padding: 16px;
    width: 100%;
    // height: 0;
    // flex: 1;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    // height: 460px;
    flex-shrink: 0;
    &_title {
      margin-bottom: 5px;
      display: flex;
      color: #343b42;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      > span {
        align-self: start;
        flex-shrink: 0;
      }
      &_steps {
        width: 720px;
        flex-shrink: 0;
      }
      &_tag {
        align-self: start;
        flex-shrink: 0;
        margin-top: 6px;
        margin-right: 12px;
        width: 4px;
        height: 20px;
        background-color: #3276e8;
      }
    }
    &_form {
      flex: 1;

      width: 1240px;
      margin: 0 auto;
      overflow-y: scroll;
      overflow-x: hidden;
      &_vsform {
        // border: 1px solid black;
        .ant-form-item-row {
          .ant-form-item-label {
            width: 140px !important;
          }
          // }
        }
      }
    }
    // &_table {
    //   width: 0;
    //   flex: 1;
    //   padding: 16px 24px 16px 16px;
    //   display: flex;
    //   flex-direction: column;
    //   &_bottom {
    //     opacity: 1;
    //     font-size: 16px;
    //     font-weight: 400;
    //     text-align: center;
    //     display: flex;
    //     justify-content: flex-end;
    //     &_delete {
    //       width: 80px;
    //       height: 32px;
    //       line-height: 32px;
    //       border-radius: 2px 2px 2px 2px;
    //       opacity: 1;
    //       border: 1px solid #3276e8;
    //       cursor: pointer;
    //       color: #3276e8;
    //     }
    //     &_save {
    //       cursor: pointer;
    //       margin-left: 16px;
    //       width: 80px;
    //       height: 32px;
    //       line-height: 32px;
    //       background: #3276e8;
    //       border-radius: 2px 2px 2px 2px;
    //       color: #ffffff;
    //     }
    //   }
    // }
  }
  &_bottom {
    flex-shrink: 0;
    padding: 0 16px;
    // height: 64px;
    // line-height: 64px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    // display: flex;
    text-align: center;
    // justify-content: center;

    height: 0;
    flex: 1;
    &_delete {
      margin: auto 0;
      width: 80px;
      height: 32px;
      // line-height: 32px;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #3276e8;
      cursor: pointer;
      color: #3276e8;
    }
    &_save {
      cursor: pointer;
      margin: auto 0;
      margin-left: 16px;
      width: 80px;
      // height: 32px;
      // line-height: 32px;
      background: #3276e8;
      border-radius: 2px 2px 2px 2px;
      color: #ffffff;
    }
  }
}

@root-entry-name: default;