// 行卡片头部
.appointment-cart-main {
  width: 164px;
  height: 136px;
  background-color: #fff;

  .cart-containter {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 16px 14px;

    .cart-icon {
      height: 28px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .cart-bg {
        width: 24px;
        height: 24px;
        display: flex;
        border-left: 14px solid transparent;
        border-right: 14px solid #50bc35;
        border-top: 14px solid #50bc35;
        border-bottom: 14px solid transparent;
        position: relative;
        .cart-bg-title {
          position: absolute;
          top: -14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .cart-number {
      display: flex;
      align-items: center;

      .cart-number-type {
        display: flex;
        // width: 36px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #3276e8;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        color: #3276e8;
        margin-top: -16px;
      }

      .cart-doctor {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 12px;
        margin-top: -13px;
        font-weight: 500;
        color: #3276e8;
      }
    }

    .cart-outpatient {
      font-size: 16px;
      font-weight: 500;
      color: #343b42;
      margin-right: 10px;
      padding-bottom: 10px;
      margin-bottom: 5px;
      border-bottom: 1px dotted #e7e7e7;
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .cart-departments {
      display: flex;
      margin-bottom: 5px;
      .cart-departments-title {
        width: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #5d6268;
      }

      .cart-departments-content {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        color: #343b42;
        white-space: nowrap;

        margin-right: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

//行卡片内容
.appointment-cart-content {
  width: 176px;
  height: 136px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 0 12px;
  // margin: auto;
  cursor: pointer;
  .cart-am {
    flex: 1;
    display: flex;
    height: 68px;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 2px dotted #3276e8;
  }
  .un-cart-am {
    flex: 1;
    display: flex;
    height: 68px;
    flex-direction: column;
    justify-content: space-between;
  }
  .cart-am-farewell {
    font-weight: 500;
    color: #343b42;
    font-size: 14px;
    flex: 1;
    padding: 0 12px;
    &:first-child {
      // border-bottom: 2px dotted #3276e8;
    }
    .cart-am-farewell-content {
      margin-top: 12px;

      height: 50%;
      .cart-am-farewell-content-font {
        font-size: 12px;
        font-weight: 400;
        color: #5d6268;
      }
      .cart-am-farewell-content-font-disable {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, rgba(0, 0, 0, 0.2)) !important;
      }
    }
  }
  .total-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    img {
      width: 16px;
      height: 16px;
    }
    .cart-date {
      font-size: 16px;
      font-weight: 500;
      color: #343b42;
    }
    .cart-time {
      font-weight: 400;
      color: #5d6268;
      font-size: 12px;
      margin-left: 5px;
      // flex: 1;
    }
    .snow-sun {
      .cart-icon1 {
        width: 16px;
        // margin-left: 42px;
        height: 16px;
        margin-top: -5px;
      }
    }
  }
  .total-bottom {
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total-bottom-total {
      display: flex;
      align-items: center;
      .total-bottom-total-title {
        font-size: 16px;
        font-weight: 400;
        color: #85898d;
        margin-right: 10px;
      }
      .total-bottom-total-num {
        font-weight: 500;
        color: #3276e8;
        font-size: 16px;
      }
    }
    .total-bottom-appointment {
      display: flex;
    }
  }
}

//号序Modal框
.number-order-container {
  // height: 700px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 十列，每列宽度平均分布 */
  // grid-template-rows: repeat(10, 1fr); /* 十行，每行高度平均分布 */
  gap: 10px; /* 单元格之间的间隔 */
  .grid-item {
    // width: 58px;
    height: 68px;
    background: #f4f5f7;
    border-radius: 4px;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    transition:background 0.3s ease;
  }
  .grid-item-disable {
    background: #f4f5f7;
    cursor: not-allowed;
    height: 68px;
    border-radius: 4px;
    flex-direction: column;
    text-align: center;
    color: rgba(0, 0, 0, 0.5) !important;
    transition:background  0.3s ease;
  }
  .grid-item-first {
    flex: 1;
    font-weight: 500;
    // color: #343b42;
    font-size: 18px;
  }
  .grid-item-last {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    color: #5d6268;
    text-align: center;
  }
}

.register-table-inner-select-cart {
  width: 224px;
  background: #ebf2ff;
  border-radius: 6px;
  border: 1px solid #3276e8;
  padding: 8px;
  .register-table-inner-cart-select-title {
    display: flex;
    align-items: center;
    .register-table-inner-cart-title-select-left {
      width: 32px;
      height: 16px;
      font-size: 12px;
      background: rgba(50, 118, 232, 0.1);
      border-radius: 2px;
      font-weight: 500;
      color: #3276e8;
    }
    .register-table-inner-cart-select-content {
      font-size: 16px;
      font-weight: 500;
      color: #3276e8;
      height: 16px;
      margin-left: 10px;
    }
  }
  .register-table-inner-cart-select-footer {
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #85898d;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    .cart-footer-type {
      margin-right: 6px;
    }
  }
}
.disabled-appointment-style {
  background-color: rgba(0, 0, 0, 0.04);
}
// .ant-picker-cell{
//   color: rgba(0, 0, 0, 0.85) !important;
// }

@root-entry-name: default;