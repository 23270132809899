.aspirin-dictionary-chm {
}

.aspirin-dictionary-chm-dropdown-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  width: 658px;

  // max-height: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px 24px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .first {
      font-size: 12px;
      color: #aeb0b3;
    }

    .second {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0 16px 0;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;

        > div,
        > input {
          margin-right: 10px;
        }
      }
    }
  }
}

.dictionary-table {
  padding: 0;
}

@root-entry-name: default;