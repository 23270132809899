@import '~@/style/vars.less';

.medical-history {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > div {
    width: 100%;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #ebf2fc !important;
    box-shadow: none !important;
    font-weight: bold !important;
  }
  .cpoe-check-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .item {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 14px;
      font-size: @font-size-14;
      color: @grey-1;
      border: 1px solid @grey-1;
      cursor: pointer;
      border-radius: @border-radius;
      background-color: @white;
      user-select: none;

      + .item {
        margin-left: 6px;
      }
    }

    .check-item {
      border-color: @primary-color;
      color: #3675e4;
      background-color: #ebf2fe;
      font-weight: bold;
      position: relative;
      .check-item-checked {
        position: absolute;
        top: -6px;
        right: 0px;
      }
    }
  }

  .history-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: @padding;
    flex: 1;
    overflow: hidden;

    > div {
      height: 100%;
    }

    .medical-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-right: 16px;
      position: relative;
      overflow-y: auto;
      width: 310px;
      max-width: 310px;
      min-width: 310px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 310px;
        max-width: 310px;
        min-width: 310px;
        height: 72px;
        min-height: 72px;
        background-color: #fff;
        border-radius: @border-radius;
        border: 2px solid transparent;
        padding: 0 12px;
        cursor: pointer;
        position: relative;
        flex: 0;

        .type {
          position: relative;
          width: 24px;
          min-width: 24px;
          max-width: 24px;
          flex: 0;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          text-align: center;
          font-size: @font-size;
          margin-right: 12px;
          line-height: 20px;

          .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.1;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1;
          width: 0;
          > div {
            width: 100%;
          }

          .header {
            display: flex;
            justify-content: space-between;

            .time {
              color: @grey-1;
              font-size: @font-size;
              flex-shrink: 0;
              flex: 1;
              margin-right: 10px;
              display: flex;
              align-items: center;
              font-size: 14px;
              white-space: nowrap;
            }

            .department {
              font-size: 12px;
              color: @main-color;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // max-width: 40px;
              // border: 1px solid red;
            }
          }

          .word {
            color: #343b42;
            font-size: @font-size-14;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        + .item {
          margin-top: 14px;
        }
      }

      .active-item {
        border-color: @primary-color;
        background-color: #dbedff;

        &::before {
          background-color: @primary-color;
        }

        .word {
          color: @primary-color !important;
        }
      }
    }

    .medical-detail {
      border-radius: @border-radius;
      // tab背景颜色修改
      // background-color: #fff;
      flex: 1;
      overflow: hidden;
    }
  }
}
.history_time_line {
  height: 1px;
  width: 26px;
  margin: 0 4px;
  background-color: #3276e8;
}

@root-entry-name: default;