.aspirin-tab-check {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  padding: 0 12px 0 0;

  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-width: 0;
  }
}

@root-entry-name: default;