.Integer-layout {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  position: relative;
  .ant-pro-table-list-toolbar-title {
    position: relative;
    text-indent: 12px;
    font-weight: 500;
    font-size: 20px;
  }
  .ant-pro-table-list-toolbar-title::before {
    position: absolute;
    content: '';
    background-color: #3276e8;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
  }

  .btn{
    position: absolute;
    flex: 1;
    bottom: 10px;
    right: 20px;
  }
}

@root-entry-name: default;