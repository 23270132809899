.money-font {
  font-size: 20px;
  font-weight: 500;
  color: #3276e8;
}
.text-before-weight {
  font-size: 20px;
  font-weight: 500;
  color: #343b42;
  height: 30px;
  position: relative;
  &::before {
    position: absolute;
    width: 5px;
    height: 20px;
    background: #3276e8;
    content: '';
    transform: translate(-50%, -50%);
    top: 50%;
    left: -12px;
  }
}

.cancel-hospitalization-refunds-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: #f4f5f7 !important;
  .top {
    // height: 112px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    padding: 24px;
    padding-bottom: 0;
  }
  .bootom {
    flex: 1;
    display: flex;
    .ant-table-wrapper {
      * {
        &:not(
            .ant-checkbox-inner,
            thead,
            .aspirin-table-row-background-box,
            .aspirin-row-selected-box,
            .ant-select-selector,
            .ant-input-affix-wrapper,
            .ant-picker-input,
            tbody .ant-table-cell-fix-right-first,
            thead .ant-table-cell-fix-right-first,
            .aspirin-compare-price-bottom-box,
            .ant-input-number-affix-wrapper,
            .ant-table-row-expand-icon,
            .ant-input-number-group-addon ,
            .ant-table-row-selected
          ) {
          background: transparent !important;
        }
      }
    }
    .ant-table-cell-fix-right-first {
      background: transparent !important;
    }
    table {
      th,
      td,
      tr {
        border-color: var(--border-color) !important;
      }
    }
    .left {
      background-color: rgba(255, 255, 255, 0.5);
      width: 290px;
      margin-right: 16px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      .text-before-weight {
        font-size: 20px;
        font-weight: 500;
        color: #343b42;
        height: 30px;
        position: relative;
        &::before {
          position: absolute;
          width: 5px;
          height: 20px;
          background: #3276e8;
          content: '';
          transform: translate(-50%, -50%);
          top: 50%;
          left: -12px;
        }
      }
      .list-layout {
        flex: 1;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: #343b42;
      }
    }
    .right {
      background-color: rgba(255, 255, 255, 0.5);
      flex: 1;
      display: flex;
      flex-direction: column;
      .refunds-content-title {
        height: 152px;
        padding: 24px;
        overflow: auto;
        .refunds-tags {
          margin-top: 24px;
          // overflow: auto;
        }
      }
      .refunds-content {
        flex: 1;
        padding: 24px;
        .ant-table-thead {
          .ant-table-cell {
            text-align: center; // 将单元格内容水平居中
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            text-align: center; // 将单元格内容水平居中
          }
        }
      }
      .refunds-btn {
        height: 50px;
        padding-right: 24px;
        text-align: right;
      }
    }
  }
}


.modal-div-font-style {
  font-size: 16px;
  font-weight: 400;
  color: #343b42;
}
.aspirin-child-double-table {
  &:only-child {
    .ant-table {
      padding-left: 66px;
    }
  }
}
@root-entry-name: default;