// 滚动条整体宽度
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

// 滚动条滑槽样式
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

// 滚动条样式
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ddd;
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

ul,
ol,
dl {
  list-style: none;
}
// 主诉维护临时选中
.aspirin-table-row-color {
  .ant-table-cell {
    // border-bottom: 1px solid #d6d8d9 !important;
    // border-right: 1px solid #d6d8d9 !important;
    background-color: #cbe4ff !important;
  }
}
// 蓝灰色背景-表头颜色、加粗 #e5f2ff
.aspirin-table-header-background-color {
  .ant-pro-table-search {
    background: transparent !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #e5f2ff;
    font-weight: bolder;

    &::before {
      background-color: transparent !important;
      font-weight: bolder;
    }
  }
}
// 蓝灰色背景-鼠标悬浮 #ebf5ff
.aspirin-table-row-hover-background-color {
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #ebf5ff !important;
  }
}
.aspirin-table-row-hover-background-color1 {
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #cbe4ff !important;
  }
}
// 蓝灰色背景-选中颜色 #cbe4ff 选中悬浮 #b2d7ff
.aspirin-table-row-select-background-color {
  box-sizing: border-box;
  // 选中颜色
  background-color: #cbe4ff;
  // 复选框选中
  .ant-table-cell {
    border-bottom: 1px solid #d6d8d9 !important;
    border-right: 1px solid #d6d8d9 !important;
    background-color: #cbe4ff !important;
  }
  // 选中悬浮
  .ant-table-cell-row-hover {
    background-color: #b2d7ff !important;
  }
}
.aspirin-table-row-select-background-color1 {
  box-sizing: border-box;
  // 选中颜色
  // background-color: #cbe4ff;
  // 复选框选中
  .ant-table-cell {
    border-bottom: 1px solid #d6d8d9 !important;
    border-right: 1px solid #d6d8d9 !important;
    // background-color: #cbe4ff !important;
  }
  // 选中悬浮
  .ant-table-cell-row-hover {
    background-color: #b2d7ff !important;
  }
}

//蓝灰色背景-table空态背景透明/悬浮透明  border:#d6d8d9
.aspirin-table-body-empty-transparent-background-color {
  .ant-pro-card {
    background-color: transparent;
  }

  .ant-table {
    background-color: transparent !important;
  }
  .ant-table-thead .ant-table-cell {
    border: none;
    border-bottom: 1px solid #d6d8d9;
    background-color: #e5f2ff;
    font-size: 14px;
    font-weight: 600;
    &::before {
      background-color: transparent !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell-fix-right {
      background-color: #eff5fb !important;
    }
  }
  td.ant-table-cell {
    border: 1px solid #d6d8d9;
    border-top: none;
    border-right: none;
    // border-bottom: 1px solid #d6d8d9;
    background-color: transparent;
  }
  .ant-table-placeholder {
    td.ant-table-cell:last-child {
      border-right: none !important;
    }
  }
  .ant-table-placeholder:hover {
    background-color: transparent !important;
    .ant-table-cell:hover {
      background-color: transparent !important;
    }
  }
  .ant-table-row {
    td.ant-table-cell:last-child {
      border-right: 1px solid #d6d8d9 !important;
    }
  }
  .ant-table-row {
    td.ant-table-cell:last-child {
      border-right: 1px solid #d6d8d9 !important;
    }
  }
  .ant-table-placeholder {
    .ant-table-cell {
      border: none !important;
    }
  }
}

// 白色背景-表头颜色、加粗 #f5f7fa
.aspirin-table-header-background-color-white {
  .ant-table-thead .ant-table-cell {
    background-color: #f5f7fa;
    font-weight: bolder;
    &::before {
      background-color: transparent !important;
      font-weight: bolder;
    }
  }
}
// 白色背景-鼠标悬浮 #fafafa
.aspirin-table-row-hover-background-color-white {
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #fafafa !important;
  }
}
// 白色背景-选中颜色 #ebf4ff 选中悬浮 #d1e7ff
.aspirin-table-row-select-background-color-white {
  background-color: #ebf4ff;
  .ant-table-cell-row-hover {
    background-color: #d1e7ff !important;
  }
}
//白色背景-table空态背景透明/悬浮透明  border:#e7e7e7(没有)
.aspirin-table-body-empty-transparent-background-color-white {
  .ant-pro-card {
    background-color: transparent;
  }

  .ant-table {
    background-color: transparent !important;
  }
  .ant-table-thead .ant-table-cell {
    border: none;
    border-bottom: 1px solid #d6d8d9;
    background-color: #f5f7fa;
    font-size: 14px;
    font-weight: 600;
    &::before {
      background-color: transparent !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell-fix-right {
      background-color: #eff5fb !important;
    }
  }
  td.ant-table-cell {
    border: 1px solid #e7e7e7;
    border-top: none;
    border-right: none;
    // border-bottom: 1px solid #d6d8d9;
    background-color: transparent;
  }
  .ant-table-placeholder {
    td.ant-table-cell:last-child {
      border-right: none !important;
    }
  }
  .ant-table-placeholder:hover {
    background-color: transparent !important;
    .ant-table-cell:hover {
      background-color: transparent !important;
    }
  }
  .ant-table-row {
    td.ant-table-cell:last-child {
      border-right: 1px solid #e7e7e7 !important;
    }
  }
  .ant-table-row {
    td.ant-table-cell:last-child {
      border-right: 1px solid #e7e7e7 !important;
    }
  }
  .ant-table-placeholder {
    .ant-table-cell {
      border: none !important;
    }
  }
}
// 滚动的table底色空态背景透明
.aspirin-table-empty-background-color {
  .ant-table-placeholder:hover {
    background-color: transparent !important;
    .ant-table-cell:hover {
      background-color: transparent !important;
    }
  }
}

// 多选选择透明
.aspirin-table-row-select-background-color-transparent {
  .ant-table-row-selected {
    td.ant-table-cell {
      border: 1px solid #d6d8d9;
      border-top: none;
      border-right: none;
      background-color: transparent;
    }
  }
}
// 多选鼠标悬浮透明
.aspirin-table-row-hover-background-color-transparent {
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: transparent !important;
  }
}

.text_ellipsis {
  overflow: hidden; // 溢出的内容隐藏
  -webkit-line-clamp: 1; // 行数
  display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
  -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
  text-overflow: ellipsis; // 溢出的部分用省略号...显示
}
.text_ellipsis2 {
  overflow: hidden; // 溢出的内容隐藏
  -webkit-line-clamp: 2; // 行数
  display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
  -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
  text-overflow: ellipsis; // 溢出的部分用省略号...显示
}
.modal_title_className {
  font-size: 20px;
  font-weight: 500;
  color: #3276e8;
}
.ant-modal {
  .ant-modal-header {
    background-color: #f9fafc;
  }
}
.belt-list-lable-classname {
  flex: 1;
  font-weight: bolder;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #343b42;
  margin-left: 10px;
  margin-right: 10px;
}
.belt-list-lable-row-classname {
  height: 44px;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}
.row_font_size {
  .ant-table-cell {
    // font-size: 14px !important;
    height: 44px;
  }
  .ant-table-thead {
    font-size: 14px !important;
    height: 48px;
  }
}

@root-entry-name: default;