@import '~@/style/vars.less';

.@{prefix}-index-center {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  .aspirin-index-block {
    + .aspirin-index-block {
      margin-top: @margin;
    }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: @margin;
    flex: 1;
    overflow: hidden;

    .first {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-right: @margin;
        flex-basis: calc((100% - @margin * 2) / 3);

        .text {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > .ant-input {
            width: 100%;
            padding-bottom: 10px;
            border: 0;
            border-bottom: @border-width solid #ebeef5;

            &:hover {
              border: 0;
              border-bottom: @border-width solid @primary-color;
            }

            &:focus {
              box-shadow: unset;
            }
          }
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;

        .main-chart {
          flex: 4;
        }

        .other {
          flex: 1;
        }

        .aspirin-index-block {
          margin: 0 !important;
          border-radius: 4px 0 0 4px;

          + .aspirin-index-block {
            border-radius: 0 4px 4px 0;
          }
        }

        .day-sign {
          margin-top: 35px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: @main-color;
            position: relative;
            padding-left: 30px;

            > span {
              &:first-of-type {
                display: inline-block;
                width: 35px;
                position: relative;

                &::before {
                  content: '';
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background-color: @green;
                  position: absolute;
                  top: calc(~'50% - 6px');
                  left: -20px;
                }

                &::after {
                  content: ':';
                }
              }
            }

            + .item {
              margin-top: @margin;
            }
          }
        }
      }
    }

    .second {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: @margin;
      flex: 1;
      overflow: hidden;

      .aspirin-index-block {
        overflow-y: auto;

        + .aspirin-index-block {
          margin-top: 0 !important;
          margin-left: @margin;
        }
      }
    }
  }

  .block1 {
    height: 94px !important;
    padding: 0 24px;
  }

  .block2 {
    height: 58px !important;
    padding: 0 24px;
  }

  .block3 {
    height: 152px !important;
  }

  .block4 {
    height: 280px !important;
  }

  .block5 {
    height: 456px !important;
  }

  .block6 {
    min-height: 228px !important;
  }
}

.@{prefix}-index-center-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: auto;

  > .item {
    color: @main-color;
    cursor: pointer;
    font-size: @font-size-18;
    white-space: nowrap;

    + .item {
      margin-left: 65px;
    }
  }

  > .active-item {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: @primary-color;
      position: absolute;
      left: 0;
      bottom: -8px;
    }
  }
}

.@{prefix}-index-center-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;

  .main {
    color: @title-color;
    margin-right: 70px;

    .first,
    .second {
      white-space: nowrap;
    }

    .name {
      font-size: 30px;
      font-weight: @font-weight-bold;
    }

    .gender {
      margin: 0 @margin-2;
    }

    .age {
      font-size: @font-size-14;
    }

    .label {
      font-weight: @font-weight-normal;
      font-size: @font-size;

      &::after {
        content: ':';
      }
    }

    .value {
      font-size: @font-size-20;
      margin-left: @margin-3;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;

    .info-item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .info-bar {
        width: 4px;
        height: 100%;
        background-color: red;
        position: absolute;
      }

      .info-content {
        padding-left: @padding-4;

        .item-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .label {
          display: inline-block;
          padding: 0 12px 0 0;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;

          > span {
            flex: 1;

            &:last-of-type {
              text-align: center;
            }
          }

          &::after {
            content: ':';
          }
        }

        .value {
          white-space: nowrap;
          font-size: @font-size-20;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      + .info-item {
        margin-left: 60px;
      }
    }
  }
}

@root-entry-name: default;