
.window-dictionaty-maintenance-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .footer {
        height: 60px;
        margin: 0 10px;
        box-sizing: border-box;
        border-top: 2px solid var(--primary-color-3);
    
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}   


@root-entry-name: default;