.list_print {
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &_search {
    height: 60px;
  }
  &_table {
    height: 0;
    flex: 1;
    width: 100%;
  }
  &_bottom {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.ant-picker-focused {
  font-size: 18px;
}
.ant-select-dropdown-hidden {
  font-size: 18px;
}
.ant-select-item-option-content {
  font-size: 18px;
}

@root-entry-name: default;