@import '~@/style/vars.less';
.layout-ident {
  height: 100%;
  .ant-table-wrapper {
    * {
      &:not(
          .ant-checkbox-inner,
          thead,
          .aspirin-table-row-background-box,
          .aspirin-row-selected-box,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-picker-input,
          tbody .ant-table-cell-fix-right-first,
          thead .ant-table-cell-fix-right-first,
          .aspirin-compare-price-bottom-box,
          .ant-input-number-affix-wrapper,
          .ant-table-row-expand-icon,
          .ant-input-number-group-addon
        ) {
        background: transparent !important;
      }
      .ant-spin-container {
        height: 100%;
      }
    }
  }
  .ant-table-cell-fix-right-first {
    background: transparent !important;
  }
  table {
    th,
    td,
    tr {
      border-color: var(--border-color) !important;
    }
  }
}

@root-entry-name: default;