@import '~@/style/vars.less';

.@{prefix}-cpoe-center-mainContainer,
.aspirin-drawer-stop-order {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // min-width: 1400px;
  width: 100%;
  padding: 0 24px;
  // background-color: var(--background);

  .aspirin-block {
    .header {
      margin-bottom: 0;
    }
  }

  .aspirin-table {
    .ant-table-cell-row-hover {
      cursor: pointer;
      background-color: #cce5ff !important;
    }
    .aspirin-table-row-active {
      > td {
        background-color: #cce5ff !important;
        color: unset;
      }
    }
    .aspirin-table-row-order-blue {
      > td {
        color: #3276e8;
      }
    }
    .aspirin-table-row-order-red {
      > td {
        color: #e34a4e;
      }
    }
    .aspirin-table-row-order-green {
      > td {
        color: #30a313;
      }
    }
    .aspirin-table-row-order-black {
      > td {
        color: #343b42;
      }
    }
    .aspirin-table-row-order-pink {
      > td {
        color: #cd8aed;
      }
    }
    .ant-table-wrapper {
      background-color: transparent !important;
    }
    .ant-pro-card {
      background-color: transparent !important;
    }
    .ant-spin-container {
      background-color: transparent !important;
    }
    .ant-table {
      background-color: transparent;
    }

    .ant-table-placeholder {
      // &:hover {
      //   > td {
      //     background-color: transparent !important;
      //   }
      // }
    }

    .ant-table-thead .ant-table-cell {
      background-color: #dbedff;
      &::before {
        background-color: transparent !important;
      }
    }
  }

  .extra-operation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 12px 0;

    .left {
      font-weight: 400;
      color: #85898d;
    }

    .left-in {
      color: #343b42;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        + div {
          margin-left: 16px;
        }
      }

      .order-type-select {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .label {
          margin-right: 14px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
      > div {
        // margin-left: 24px;
        color: @primary-color;
        cursor: pointer;
        display: flex;
        align-items: center;

        > span {
          margin-left: 4px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .desc {
        color: #aeb0b3;
        font-size: @font-size;
      }

      .oper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ant-btn {
          margin-left: 6px;
        }
      }
    }

    .second {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding: 8px 0;

      &::before {
        position: absolute;
        content: '';
        width: calc(~'100% + 32px');
        height: 1px;
        background-color: @primary-color;
        top: 0;
        left: -16px;
      }

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-left: 12px;
      }

      .label {
        color: #5d6268;
        font-size: @font-size-12;

        &::after {
          content: ':';
        }
      }

      .value {
        font-weight: bold;
        margin-left: 12px;
        color: @primary-color;
        font-size: @font-size;
      }
    }
  }
}

.@{prefix}-cpoe-center-mainContainer-normal {
  background-color: transparent;
  padding: 0 24px;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  overflow: hidden;
}
.cpoe-test {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  // > div {
  //   height: 100%;
  // }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .label {
        color: #5d6268;
        font-size: 12px;
        margin-right: 12px;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 16px;
        color: @primary-color;
      }
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }

  > .main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    height: 0;

    > .left {
      width: 648px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px;
      background-color: #f9fafc;

      .ant-input {
        flex: 0;
      }

      .list {
        max-height: 100%;
        overflow-y: auto;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 24px;

        .item {
          flex-basis: 50%;
          height: 48px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d6d8d9;
          border-right: 1px solid #d6d8d9;
          padding: 0 14px 0 24px;
          cursor: pointer;

          > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > div {
              + div {
                margin-left: 10px;
              }
            }
          }

          &:first-of-type {
            border-top: 1px solid #d6d8d9;
            border-left: 1px solid #d6d8d9;
          }

          &:nth-of-type(2) {
            border-top: 1px solid #d6d8d9;
          }

          &:nth-child(odd) {
            border-left: 1px solid #d6d8d9;
          }
        }
      }
    }

    > .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      height: 100%;

      > div {
        height: 100%;
      }

      .first {
        width: 300px;
        min-width: 300px;
        flex: 0;
        border-right: 1px solid #d6d8d9;

        .clear-all {
          color: #3276e8;
          font-size: 16px;
          cursor: pointer;

          > span {
            margin-left: 2px;
          }
        }

        .aspirin-block {
          .header {
            padding: 24px 8px 0 24px;
          }
        }

        .check-list {
          .item {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px 0 24px;
            cursor: pointer;
            user-select: none;

            .oper {
              cursor: pointer;
            }

            .label {
              color: #343b42;
              font-size: 16px;
            }
            .value {
              color: #aeb0b3;
              font-size: 14px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
          }
        }
      }

      .second {
        flex: 1;

        .aspirin-block {
          .header {
            padding: 24px 8px 0 24px;
          }
        }

        .info-form {
          padding: 0 24px;
        }

        .textarea {
          // border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.cpoe-chm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  > .header {
    width: 100%;
    padding: 18px 24px 18px 24px;
  }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .label {
        color: #5d6268;
        font-size: 12px;
        margin-right: 12px;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 16px;
        color: @primary-color;
      }
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }

  > .main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 0;

    > .left {
      width: 408px !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f9fafc;
      padding: 24px;

      > .header {
        padding: 18px 12px 18px 24px;
        margin-bottom: 0;
      }

      .ant-input-number {
        width: 100%;
      }

      // .ant-input {
      //   flex: 0;
      // }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        height: 100%;

        .first {
          width: 200px;
          max-width: 200px;
          min-width: 200px;
          height: 100%;
          overflow-y: auto;
          background-color: #fff;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .second {
          width: 260px;
          max-width: 260px;
          min-width: 260px;
          height: 100%;
          border-right: 1px solid #d6d8d9;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .third {
          flex: 1;
          height: 100%;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
              margin-left: 8px;
            }
          }

          .right {
            > span {
              + span {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      flex-direction: column;
      height: 100%;
      padding: 24px !important;

      .header {
        margin-bottom: 12px !important;
      }

      .first {
        flex: 1;

        .clear-all {
          color: #3276e8;
          font-size: 16px;
          cursor: pointer;

          > span {
            margin-left: 2px;
          }
        }

        .check-list {
          padding: 24px;
          background-color: #f9fafc;
          transition: all 0.4s ease-in-out;
          > .ant-tag {
            margin-bottom: 4px;
          }
        }
      }

      // .second {
      //   flex: 1;

      //   .aspirin-block {
      //     .header {
      //       padding: 24px 8px 0 24px;
      //     }
      //   }

      //   .info-form {
      //     padding: 0 24px;
      //   }

      //   .textarea {
      //     border: 1px solid #d9d9d9;
      //   }
      // }
    }
  }
}

.cpoe-chm-extra {
  display: flex;
  justify-content: flex-start;
  align-content: center;

  .ant-btn {
    border-radius: 2px;
    + .ant-btn {
      margin-left: 16px;
    }
  }
}

.cpoe-exam {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  // > div {
  //   height: 100%;
  // }

  > .header {
    width: 100%;
    padding: 18px 24px 18px 24px;
    background: #f4f5f7 !important;
  }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .label {
        color: #5d6268;
        font-size: 12px;
        margin-right: 12px;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 16px;
        color: @primary-color;
      }
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }

  > .main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 0;

    > .left {
      width: 773px !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f9fafc;

      > .header {
        padding: 18px 12px 18px 24px;
        margin-bottom: 0;
      }

      .ant-input {
        flex: 0;
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        height: 100%;

        .first {
          width: 200px;
          max-width: 200px;
          min-width: 200px;
          height: 100%;
          overflow-y: auto;
          background-color: #fff;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;
            transition: all cubic-bezier(1, 0.25, 1, 1) 0.3s;
            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .second {
          width: 260px;
          max-width: 260px;
          min-width: 260px;
          height: 100%;
          border-right: 1px solid #d6d8d9;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .third {
          flex: 1;
          height: 100%;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            gap: 10px;
            cursor: pointer;
            .left {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .label {
              color: #5d6268;
              font-size: 16px;
              margin-left: 8px;
            }
          }

          .right {
            display: flex;
            gap: 10px;
            align-items: center;
            > span {
              + span {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      height: 100%;
      padding: 24px;

      > div {
        height: 100%;
      }

      .first {
        flex: 1;

        .clear-all {
          color: #3276e8;
          font-size: 16px;
          cursor: pointer;

          > span {
            margin-left: 2px;
          }
        }

        .check-list {
          padding: 15px;
          background-color: #f9fafc;
          min-height: 70px;
          border-radius: 4px;
          height: auto;
          > .ant-tag {
            margin-bottom: 4px;
          }
        }
      }

      // .second {
      //   flex: 1;

      //   .aspirin-block {
      //     .header {
      //       padding: 24px 8px 0 24px;
      //     }
      //   }

      //   .info-form {
      //     padding: 0 24px;
      //   }

      //   .textarea {
      //     border: 1px solid #d9d9d9;
      //   }
      // }
    }
  }

  .exam-form-display {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 12px;

    .label {
      width: 120px;
      min-width: 120px;
      display: inline-block;
      white-space: nowrap;
      font-size: 14px;
      color: #85898d;
    }

    .value {
      word-break: break-all;
    }
  }
}

.cpoe-exam-extra {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .collapse-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > .label {
      padding-left: 12px;
    }

    span {
      color: #1677ff;
      cursor: pointer;
    }
  }
}

.aspirin-table-order {
  .ant-table-container > .ant-table-header > table > thead > tr > th {
    border-right: 1px solid transparent !important;
  }
  .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border-right: 1px solid #d6d8d9 !important;
  }
  .ant-table-tbody > tr > td {
    background-color: #f1f6fa !important;
    border-bottom: 1px solid #d6d8d9 !important;
    height: 100%;
  }
}

.aspirin-order-text {
  height: 100%;
  width: 100%;
  padding: 0 18px;
  display: flex !important;
  align-items: center;
}

table thead .aspirin-table-order-cell {
  padding: 0 18px !important;
}

.aspirin-table-order-cell {
  padding: 0 0 !important;
}

.aspirin-table-order-cell-left {
  position: sticky !important;
  left: 0;
  z-index: 999999;
  box-shadow: inset -10px 0px 10px -10px rgba(0, 0, 0, 0.15);
  // box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.aspirin-order-text-group-first {
  position: absolute;
  inset: 0;
  &::before {
    content: '';
    width: 9px;
    height: calc(~'50% + 12px');
    background-color: transparent;
    border: 2px solid #3276e8;
    border-right-width: 0;
    border-bottom-width: 0;
    position: absolute;
    top: 50%;
    left: 7px;
    z-index: 10;
  }

  // &::after {
  //   content: url('./group.svg');
  //   position: absolute;
  //   top: 50%;
  //   left: 7px;
  //   font-size: 20px;
  // }
}

.aspirin-order-text-group-line {
  // position: relative;
  position: absolute;
  inset: 0;
  overflow: hidden; // 溢出的内容隐藏
  -webkit-line-clamp: 2; // 行数
  display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
  -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
  text-overflow: ellipsis; // 溢出的部分用省略号...显示
  &::after {
    content: '';
    width: 2px;
    height: calc(~'100% + 24px');
    background-color: #3276e8;
    position: absolute;
    top: -12px;
    left: 7px;
    z-index: 10;
  }
}

.aspirin-order-text-group-last {
  position: absolute;
  inset: 0;

  &::before {
    content: '';
    width: 9px;
    height: calc(~'50% + 12px');
    background-color: transparent;
    border: 2px solid #3276e8;
    border-right-width: 0;
    border-top-width: 0;
    position: absolute;
    z-index: 10;
    top: -12px;
    left: 7px;
  }
}

.aspirin-disabled-box {
  > * {
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.inspect-item {
  // background-color: var(--red-background) !important;
  * {
    color: var(--red) !important;
  }
  // &:not(:last-child){
  //   border-bottom: 1px var(--red) solid;
  // }
  border-bottom: 1px var(--red) solid;
  &:first-child {
    border-top: 1px var(--red) solid;
  }
}

@root-entry-name: default;