.vpVisibleHidden {
  transform: translate(-9999px);
  position: absolute !important;
}

.vpVisibleCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@root-entry-name: default;