.aspirin-execute-order-container {
  width: 100%;
  display: flex;

  .aspirin-execute-order-main {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
      flex: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 12px 0;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      margin-bottom: 16px;

      > div {
        padding: 8px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > span {
          margin-right: 12px;
        }

        .ant-select {
          min-width: 240px;
        }
        span {
          font-size: 18px;
        }
      }
    }

    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 16px 24px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);

      .vs-base-table-container {
        flex: 1;
        width: 100%;
      }

      .aspirin-table > .ant-table-wrapper {
        background-color: transparent;
      }

      .aspirin-table {
        .ant-table-cell-row-hover {
          cursor: pointer;
          background-color: #cce5ff !important;
        }
        .aspirin-table-row-active {
          > td {
            background-color: #cce5ff !important;
            color: unset;
          }
        }
        .aspirin-table-row-order-blue {
          color: #3276e8;
          > td {
            color: #3276e8;
          }
        }
        .aspirin-table-row-order-red {
          color: #e34a4e;
          > td {
            color: #e34a4e;
          }
        }
        .aspirin-table-row-order-green {
          color: #30a313;
          > td {
            color: #30a313;
          }
        }
        .aspirin-table-row-order-black {
          color: #343b42;
          > td {
            color: #343b42;
          }
        }
        .aspirin-table-row-order-pink {
          color: #cd8aed;
          > td {
            color: #cd8aed;
          }
        }
        .ant-table-wrapper {
          background-color: transparent !important;
        }
        .ant-pro-card {
          background-color: transparent !important;
        }
        .ant-spin-container {
          background-color: transparent !important;
        }
        .ant-table {
          background-color: transparent;
        }

        .ant-table-placeholder {
          // &:hover {
          //   > td {
          //     background-color: transparent !important;
          //   }
          // }
        }

        .ant-table-thead .ant-table-cell {
          background-color: #dbedff;
          &::before {
            background-color: transparent !important;
          }
        }
      }

      .aspirin-execute-order-footer {
        flex: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 40px;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > div {
            + div {
              margin-left: 24px;
            }
          }

          .label {
            color: #343b42;
            font-size: 16px;
            font-weight: 500;
          }

          .value {
            color: #e34a4e;
            font-size: 16px;
            font-weight: 500;
            margin-left: 12px;
          }
        }

        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .footer-btn {
            + .footer-btn {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  .ant-table-expanded-row {
    background-color: #fff;

    > td {
      background-color: #fff;

      > div {
        background-color: #fff;
      }
    }
  }
}

.aspirin-table-order {
  .ant-form-item-margin-offset {
    margin: 0 !important;
  }
  .ant-table-tbody {
    > .aspirin-order-table-patient-render-row {
      > td {
        background-color: #fff !important;
      }
    }
  }
}

.aspirin-order-table-patient-render {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  > span {
    margin-left: 30px;
    + span {
      margin-left: 60px;
    }
  }
}

.aspirin-execute-order-patient-drawer {
  .ant-drawer-body {
    padding: 0;
  }
}

.aspirin-execute-order-patient-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }

  .filter {
    background-color: #f4f5f7;
    border-bottom: 1px solid #d6d8d9;
    padding: 20px 24px;
  }

  .first {
    border-bottom: 1px solid #d6d8d9;
    padding-bottom: 12px;

    .ant-radio-group {
      display: flex;
      flex-direction: column;

      .ant-radio-wrapper {
        + .ant-radio-wrapper {
          margin-top: 6px;
        }
      }
    }
  }

  .second {
    padding: 12px 0;

    .group {
      margin-top: 12px;
      display: flex;
      align-items: center;

      > span {
        margin-right: 12px;
      }

      .ant-select {
        width: 100%;
        flex: 1;
      }
    }
  }

  .content {
    padding: 16px 24px;
  }
}

.aspirin-execute-order-send-modal,
.aspirin-execute-order-fail-modal,
.aspirin-execute-order-execute-modal {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 640px;

    > div {
      width: 100%;
      flex: 1;
    }
  }
}

.aspirin-execute-order-execute-modal {
  .ant-modal-body {
    padding: 0 !important;

    > .content {
      padding: 24px;
      flex: 0;
      min-height: 0;
    }

    > .form {
      flex: 1;
      padding: 24px;
      border-top: 1px solid #e7e7e7;
    }
  }
}

.aspirin-execute-order-rollback-modal {
  .ant-modal-body {
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 640px;

    > div {
      width: 100%;
      flex: 1;
    }

    .first {
      padding: 24px;
    }

    .second {
      flex: 0;
      max-height: 135px;
      min-height: 135px;
      background-color: #f4f5f7;
      padding: 16px 24px;

      > div {
        &:first-of-type {
          color: #3276e8;
          font-size: 16px;
          margin-bottom: 12px;
        }

        &:nth-of-type(2) {
          color: #5d6268;
          font-size: 14px;
          margin-bottom: 16px;
        }

        &:last-of-type {
          color: #343b42;
          font-size: 14px;

          > span {
            margin-right: 14px;
          }

          .ant-select {
            min-width: 240px;
          }
        }
      }
    }
  }
}

.aspirin-execute-order-table-expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0 40px;

  .right {
    display: flex;
    flex-direction: column;

    .ant-btn {
      height: 24px;
      padding: 0 8px;
      line-height: 24px;

      + .ant-btn {
        margin-top: 8px;
      }
    }
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      min-width: 250px;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        margin-left: 12px;

        + .item {
          margin-top: 8px;
        }

        .label {
          color: #85898d;
          font-size: 14px;
          position: relative;

          &::after {
            content: ':';
            position: absolute;
            right: -6px;
          }
        }

        .value {
          color: #343b42;
          font-size: 14px;
          margin-left: 14px;
        }
      }
    }
  }
}

.aspirin-order-execute {
  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 0 !important;
    }

    .aspirin-execute-order-table-expanded {
      padding: 12px 24px;
    }
  }
}

.aspirin-order-execute-oper-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;

  > * {
    margin: 0 6px;
    cursor: pointer;
  }
}

.aspirin-execute-order-patient-drawer-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@root-entry-name: default;