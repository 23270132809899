.sign_selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
  height: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 22px;
  // background-image: url('~@/assets/home_background.png');
  // background-size: cover;
  // border: 1px solid red;
  .size {
    width: 100%;
    height: 100%;
    // background: #fff;
    flex-shrink: 0;
    padding-bottom: 0;
    // border: 1px solid black;
  }
  .left {
    margin-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 32px;
      color: #3276e8;
      margin-top: 40px;
    }
    .tip {
      color: #85898d;
      font-size: 18px;
      margin: 27px 0 42px;
    }
    .img {
      width: 498px;
      height: 480px;
    }
    .data {
      display: flex;
      color: #5d6268;
      font-size: 18px;
      margin-top: 92px;
      .item {
        align-self: flex-end;
        margin-right: 32px;
      }
    }
  }
  .right {
    // padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.3);
    .title {
      margin-bottom: 24px;
      box-sizing: border-box;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      height: 54px;
      line-height: 54px;
      color: #3276e8;
      background: linear-gradient(-45deg, #d0e8fe, #b3d2fa);
    }
    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .list {
      flex: 1;
      overflow-y: scroll;
      // border: 1px solid pink;
      .list_item {
        margin-bottom: 16px;
        padding: 14px 38px 14px 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: 76px;
        background-color: #fff;
        border-radius: 4px;
        border: 2px solid #fff;
        cursor: pointer;
        &_left {
        }
        &_center {
          margin: 0 16px;
          flex: 1;
          &_name {
            font-size: 18px;
            font-weight: 600;
            color: #343b42;
          }
          &_type {
            font-size: 14px;
            color: #5d6268;
          }
        }
        &_right {
          width: 56px;
          height: 28px;
          background: rgba(50, 118, 232, 0.1);
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          font-size: 18px;
          text-align: center;
          line-height: 28px;
          color: #3276e8;
        }
      }
      .list_item_select {
        border: 2px solid #3276e8;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .select {
      margin: 24px 0;
      box-sizing: border-box;
      //   height: 50px;
    }
    .btn {
      height: 54px;
      font-size: 24px;
      color: #fff;
      font-weight: 600;
      border-radius: 4px;
      text-align: center;
      line-height: 54px;
      cursor: pointer;
      background: linear-gradient(90deg, #014d7e 0%, #0071bd 100%);
    }
  }
}

@root-entry-name: default;