.backing-layout {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;

  .backing-container-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
    justify-content: space-between;

    .backing-serach {
      .backing-serach-patientIdIs {
        width: 765px;
        padding-left: 10px;

        .backing-serach-patientIdIs-select {
          width: 200px;
        }

        .suffix-readCard {
          .suffix-readCard-image {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .backing-center {
    flex: 1;
    overflow: auto;
  }

  .backing-cart-container {
    border: 1px solid #d6d8d9;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 20px;

    .backing-title {
      // flex: 1;
      height: 44px;
      background: #cbe4ff;
      border-radius: 2px 2px 0px 0px;
      display: flex;
      align-items: center;
      padding: 24px;
      font-size: 16px;

      .backing-title-layout {
        width: 100%;
        display: flex;

        .child {
          flex: 1;

          .span_label {
            font-size: 16px;
          }
        }
      }

      .backing-title-lable {
        // flex: 3;
        width: 52px;
        height: 24px;
        background: #ebf2ff;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 500;
        color: #3276e8;
        text-align: center;
      }
    }

    .backing-content {
      height: 128px;
      display: flex;
      justify-content: space-between;
      flex: 6;

      p {
        display: inline;
        font-size: 14px;
        font-weight: 500;
        color: #343b42;
      }

      .ant-row {
        display: flex;
        flex-flow: row wrap;
        min-width: 0;
        padding-bottom: 10px;
      }

      .backing-content-main {
        flex: 1;
        padding: 24px;
        // padding-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 0;
        color: #343b42;
      }

      .backing-content-total-cost {
        position: relative;
        height: 100%;
        width: 154px;
        border-radius: 0px 0px 2px 0px;
        border: 1px solid #d6d8d9;
        font-size: 20px;
        font-weight: 500;
        color: #3276e8;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cost-content {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .backing-cart-container-unselect {
    border: 2px solid #3276e8;
    background: #ebf2ff;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 20px;

    .backing-title {
      // flex: 1;
      height: 44px;
      background: #cbe4ff;
      border-radius: 2px 2px 0px 0px;
      display: flex;
      align-items: center;
      padding: 24px;
      font-size: 16px;

      .backing-title-layout {
        width: 100%;
        display: flex;

        .child {
          flex: 1;
        }
      }

      .backing-title-lable {
        // flex: 3;
        width: 52px;
        height: 24px;
        background: #ebf2ff;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 500;
        color: #3276e8;
        text-align: center;
      }
    }

    .backing-content {
      height: 128px;
      display: flex;
      justify-content: space-between;
      flex: 6;

      p {
        display: inline;
        font-size: 14px;
        font-weight: 500;
        color: #343b42;
      }

      .ant-row {
        display: flex;
        flex-flow: row wrap;
        min-width: 0;
        padding-bottom: 10px;
      }

      .backing-content-main {
        flex: 1;
        padding: 24px;
        // padding-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 0;
        color: #343b42;
      }

      .backing-content-total-cost {
        position: relative;
        height: 100%;
        width: 154px;
        border-radius: 0px 0px 2px 0px;
        border: 1px solid #d6d8d9;
        font-size: 20px;
        font-weight: 500;
        color: #3276e8;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cost-content {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .backing-cart-disable {
    border: 1px solid #d6d8d9;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    background-color: rgba(0, 0, 0, 0.04);
    cursor: not-allowed;
    // padding-bottom: 20px;
    margin-bottom: 20px;

    .backing-title {
      // flex: 1;
      height: 44px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 2px 2px 0px 0px;
      display: flex;
      align-items: center;
      padding: 24px;
      font-size: 16px;

      .backing-title-layout {
        width: 100%;
        display: flex;

        .child {
          flex: 1;
        }
      }

      .backing-title-lable {
        // flex: 3;
        width: 52px;
        height: 24px;
        background: #ebf2ff;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 500;
        color: #3276e8;
        text-align: center;
      }
    }

    .backing-content {
      height: 128px;
      display: flex;
      justify-content: space-between;
      flex: 6;

      p {
        display: inline;
        font-size: 14px;
        font-weight: 500;
        color: #343b42;
      }

      .ant-row {
        display: flex;
        flex-flow: row wrap;
        min-width: 0;
        padding-bottom: 10px;
      }

      .backing-content-main {
        flex: 1;
        padding: 24px;
        // padding-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 0;
        color: #343b42;
      }

      .backing-content-total-cost {
        position: relative;
        height: 100%;
        width: 154px;
        border-radius: 0px 0px 2px 0px;
        border: 1px solid #d6d8d9;
        font-size: 20px;
        font-weight: 500;
        color: #3276e8;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cost-content {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .backing-btn {
    text-align: right;
    // background: ;
    padding-top: 24px;
  }
}

.madal-layout-return {
  display: flex;
  align-items: center;

  .madal-layout-return-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff7e7e;
    text-align: center;
    color: #fff;
    margin-right: 10px;
  }

  .font {
    // width: 20px;
    // height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #343b42;
    margin-right: 10px;
  }

  .money {
    font-size: 32px;
    font-weight: 500;
    color: #3276e8;
  }
}
@root-entry-name: default;