#InpDrugRefundPerform {
  width: 100% !important;
  height: 100% !important;
  .MenuDetails {
    width: 100% !important;
    .ant-menu {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .TableDetails {
    margin-top: 2px;
    width: 100%;
    height: calc(100% - 46px);
    padding: 24px;
    background: rgba(255, 255, 255, 0.3);
    #Mail,
    #App {
      width: 100%;
      height: calc(100% - 20px);
    }
    #Mail {
      .InpRefundApplyOrderForm {
        width: 100%;
        .ant-form {
          display: flex !important;
          align-items: baseline;
        }
      }
    }
  }
}

@root-entry-name: default;