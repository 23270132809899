.backing-layout {
  .ant-table-tbody > tr > td {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  width: 100%;
  display: flex;
  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    color: #343b42;
  }
  .left {
    flex: 1;
    width: 494px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 16px;
    padding: 24px;
    .ant-space-align-center {
      align-items: flex-start !important;
    }
    .list-cell {
      flex: 1;
      width: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px;
      margin-right: 10px;

      font-size: 16px;
      font-weight: 400;
      color: #343b42;
      // padding-left: 5px;
      // padding-right: 5px;
    }
    .left-query {
      // padding: 24px;
      display: flex;
      align-items: flex-start;
    }
    .select {
      background-color: #cbe4ff;
    }
  }
  .right {
    padding: 24px;
    flex: 4;
    background-color: rgba(255, 255, 255, 0.5);
    .ant-table-cell {
      font-size: 16px;
      font-weight: 400;
      color: #343b42;
    }
    .ant-table-thead > tr > th {
      background-color: #e5f2ff; /* 用你想要的颜色替代 #yourColor */
    }

    .ant-table-row-level-0 {
      background-color: rgba(
        247,
        251,
        253,
        0.5
      ) !important; /* 用你想要的颜色替代 #yourColor */
      // opacity: 0.5;
    }
  }
  .ant-table-thead {
    font-size: 18px;
    font-weight: 500;
    color: #343b42;
  }
  .ant-pro-table-list-toolbar-title {
    position: relative;
    text-indent: 12px;
    font-weight: 500;
    font-size: 20px;
  }
  .ant-pro-table-list-toolbar-title::before {
    position: absolute;
    content: '';
    background-color: #3276e8;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.belt-list-lable-font {
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  color: #343b42;
  margin-left: 10px;
  margin-right: 10px;
}
.list-cell {
  flex: 1;
  width: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 16px;
  font-weight: 400;
  color: #343b42;
  // padding-left: 5px;
  // padding-right: 5px;
}
@root-entry-name: default;