.patient_overview_info {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_tabletoolbar {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;

    &_div {
      margin-left: 3px;
      width: 4px;
      height: 22px;
      background-color: #3276e8;
      margin-right: 16px;
    }

    &_addBtn {
      background-color: transparent;
      color: #3276e8;
    }
  }

  &__block {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    margin-bottom: 15px;

    >div:nth-of-type(1) {
      width: 400px;
      flex: none !important;
      margin-right: 48px !important;
    }

    &__tizheng {
      >div:nth-of-type(2) {
        width: 200px !important;
        flex: none !important;
      }
    }
  }
}
@root-entry-name: default;