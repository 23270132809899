.cpoe-schedule {
  width: 940px;
  height: 537px;

  .row {
    display: flex;
  }

  .th {
    width: 128px;
    height: 58px;
    border-right: 1px solid #d6d8d9;
    border-bottom: 1px solid #d6d8d9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .td {
    width: 128px;
    height: 140px;
    border-right: 1px solid #d6d8d9;
    border-bottom: 1px solid #d6d8d9;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f6ff;
  }

  .index {
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #273142;
    background-color: transparent;
  }

  .no-right {
    border-right-width: 0;
  }

  .no-bottom {
    border-bottom-width: 0;
  }

  .word {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #273142;
  }

  .info {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    // 滚动条整体宽度
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .item {
      margin-top: 10px;
      height: 38px;
      border-width: 0;
      border-top-width: 3px;
      border-style: solid;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      .label {
        color: #343b42;
        font-size: 16px;
      }

      .time {
        font-size: 14px;
        color: #85898d;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .date {
      display: flex;
      align-items: center;

      .left,
      .right,
      .extra {
        width: 30px;
        height: 18px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #d6d8d9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .left {
        margin-right: 24px;
      }

      .right {
        margin-left: 24px;
      }

      .main {
        font-size: 18px;
        color: #343b42;
        display: flex;
        align-items: center;

        .sign {
          background-color: #3276e8;
          width: 26px;
          height: 1px;
          margin: 0 8px;
          display: inline-block;
        }
      }

      .extra {
        color: #3276e8;
        margin-left: 24px;
        font-size: 12px;
      }
    }

    .legend {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;

        + .item {
          margin-left: 18px;
        }

        .sign {
          width: 14px;
          height: 14px;
          border: 0;
          border-style: solid;
          border-top-width: 3px;
        }

        .label {
          color: #273142;
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
  }
}

@root-entry-name: default;