.NurseStationBedsideCardPrinting {
  display: flex;
  width: 100%;
  height: 100%;
  &_left {
    padding: 24px;
    margin-right: 16px;
    width: 425px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    &_tab {
      margin-bottom: 16px;
    }
    &_content {
      flex: 1;
      height: 0;
    }
    &_bottom {
      height: 60px;
      font-size: 14px;
      color: #5d6268;
    }
  }
  &_right {
    // padding: 24px;
    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    &_tab {
      .ant-tabs-nav-wrap {
        border-bottom: 1px solid #d6d8d9;
        padding: 0 24px;
      }
      .ant-tabs-content .ant-tabs-tabpane {
        padding: 18px 24px 0;
      }
    }
    &_tabcontent {
      display: flex;
      height: 100%;
      flex-direction: column;
      &_change {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
      &_content {
        height: 0;
        flex-shrink: 0;
        flex: 1;
        overflow-y: scroll;
        // 姓名小卡
        &_renderNameCard {
          // height: 100%;
          display: flex;
          flex-wrap: wrap;
          &_item {
            // box-sizing: border-box;
            // border: 1px solid red;
            padding: 20px 20px 6px;
            margin-right: 16px;
            margin-bottom: 16px;
            width: 290px;
            min-height: 284px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #fff;
            font-size: 14px;
            overflow: hidden;
            &_row {
              display: flex;
              // min-height: 30px;
              // border: 1px solid red;
              &_label {
                flex-shrink: 0;
                width: 72px;
                color: #5d6268;
              }
              &_value {
                flex: 1;
                white-space: pre-wrap;
                min-height: 30px;
                flex-shrink: 0;
                font-weight: bolder;
                color: '#000000';
              }
            }
          }
        }
        // 床头卡
        &_renderBedsideCard {
          display: flex;
          flex-wrap: wrap;
          &_item {
            margin-bottom: 16px;
            margin-right: 16px;
            padding: 20px;
            // box-sizing: border-box;
            width: 384px;
            min-height: 216px;
            background-color: #fff;
            font-size: 16px;
            &_top {
              height: 80px;
              border-bottom: 1px solid #d6d8d9;
              display: flex;

              &_qrcode {
                width: 60px;
                height: 60px;
                flex-shrink: 0;
                // margin-right: 24px;
              }
              &_info {
                padding-left: 24px;
                flex-shrink: 0;
                flex: 1;
                font-weight: bolder;
                &_name {
                  font-size: 24px;
                  margin-bottom: -7px;
                }
                &_id {
                  font-size: 14px;
                }
              }
            }
            &_bottom {
              display: flex;
              flex-wrap: wrap;
              // flex: 1;
              padding-top: 9px;
              &_item {
                flex-shrink: 0;
                display: flex;
                // align-items: center;
                width: 50%;
                min-height: 27px;
                overflow: hidden;
                margin-top: 2px;
                &_label {
                  padding-top: 2px;
                  flex-shrink: 0;
                  font-size: 14px;
                  width: 70px;
                  color: #5d6268;
                }
                &_value {
                  flex-shrink: 0;
                  font-weight: bolder;
                  color: #000000;
                }
              }
            }
          }
        }
        // 陪护腕带
        &_renderAccompanyingWristband {
          font-size: 14px;
          margin-bottom: 16px;
          &_header {
            height: 60px;
            display: flex;
            align-items: center;
            color: #343b42;
            background-color: #e5f2ff;
            border-bottom: 1px solid #d6d8d9;
            &_check {
              width: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &_content {
              padding: 0 16px;
              flex: 1;
              display: flex;
              font-weight: bolder;
              align-items: center;
              &_item {
                margin-right: 24px;
                min-width: 200px;
              }
            }
            &_operation {
              display: flex;
              justify-content: center;
              width: 146px;
              &_btn {
                width: 100px;
                height: 32px;
                display: flex;
                justify-content: center;
                text-align: center;
                line-height: 24px;
                color: #fff;
                background: #3276e8;
                border-radius: 2px 2px 2px 2px;
                cursor: pointer;
              }
            }
          }
          &_content {
            // border: 1px solid #d6d8d9;
            color: #343b42;
            &_item {
              height: 128px;
              display: flex;

              background-color: #ffffff;
              border: 1px solid #d6d8d9;
              border-top: none;
              &_check {
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &_content {
                padding: 16px 16px;
                flex: 1;
                border-left: 1px solid #d6d8d9;
                border-right: 1px solid #d6d8d9;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                &_info {
                  margin-right: 60px;
                  display: flex;
                  align-items: center;
                  &_label {
                    width: 100px;
                    color: #343b42;
                  }
                  &_value {
                  }
                }
              }
              &_operation {
                width: 132px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 14px;
                color: #3276e8;
                &_edit,
                &_delete,
                &_save {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 80px;
                  height: 36px;
                  background: #ffffff;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                  border: 1px solid #3276e8;
                  cursor: pointer;
                  div {
                    margin-right: 2px;
                  }
                }
                &_edit {
                  margin-bottom: 10px;
                }
                &_save {
                  margin-bottom: 10px;
                }
                &_delete {
                }
              }
            }
            &_item_edit {
              background-color: #f7faff;
            }
          }
        }
      }
      &_empty {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &_bottom {
        flex-shrink: 0;
        height: 64px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

@root-entry-name: default;