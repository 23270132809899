.patient_merge_wapper {
  > div:nth-of-type(1) {
    width: 100% !important;
    border-radius: 4px 4px 4px 4px;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .main {
        flex: 1;
        display: flex;
        overflow: hidden;
        thead {
          .ant-table-cell {
            padding: 12px 8px !important;
          }
        }

        .ant-pro-table {
          flex: 1;
          width: 0;
        }

        .ant-table-cell {
          padding: 0;

          > div {
            width: 100%;
            height: 100%;

            > div {
              width: 100%;
              height: 100%;
              padding: 12px 8px;
            }
          }
        }

        .center {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          box-sizing: border-box;

          border-left: 1px solid #d6d8d9;
          border-right: 1px solid #d6d8d9;

          margin-top: 24px;

          .right_icon {
            width: 56px;
            height: 36px;
            background: #3276e8;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            margin-bottom: 18px;
            cursor: pointer;
          }

          .left_icon {
            width: 56px;
            height: 36px;
            background: #ffffff;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #3276e8;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3276e8;
            margin-top: 18px;
            cursor: pointer;
            box-sizing: border-box;
          }
        }

        .patient_merge_detail_block {
          .vs-base-table-container {
            height: calc(100% - 98px);
          }
        }

        .detail_remark {
          height: 98px;
          box-sizing: border-box;
          padding: 12px 0 12px 24px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          border-top: 1px solid #d6d8d9;

          .label {
            font-size: 16px;
            font-weight: 400;
            color: #343b42;
            margin-right: 16px;
          }

          .textareaComp {
            flex: 1;
            height: 74px;
            background: #f4f5f7;
            box-shadow: 0px 0px 0px 2px rgba(64, 158, 255, 0);
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #e7e7e7;
            text-indent: 12px;
            width: 100%;
          }
        }
      }
    }
  }

  .footer {
    height: 64px;
    box-sizing: border-box;
    border-top: 1px solid #d6d8d9;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .normal {
      height: 32px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #3276e8;
      padding: 0 24px;
      font-size: 16px;
      color: #3276e8;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      cursor: pointer;
    }

    .primary {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      background: #3276e8;
    }
  }

  .ant-pro-table-search {
    box-sizing: border-box;
    padding: 24px 0 0 0 !important;

    border-radius: 4px 4px 4px 4px;

    > form {
      > .ant-row-start {
        position: relative;
        display: flex;
        justify-content: flex-start;

        .ant-form-item-no-colon {
          width: 0 !important;
        }

        > .ant-col:nth-of-type(1) {
          max-width: none !important;
        }

        > .ant-col {
          .ant-form-item {
            margin-bottom: 6px !important;
          }
        }

        > .ant-col:last-child {
          margin: 0;
        }
      }
    }
  }

  .patient_merge_main_left {
    height: 100%;
    overflow: hidden;
    .ant-pro-table {
      padding-right: 24px !important;
    }
  }

  .patient_merge_detail_block {
    box-sizing: border-box;
    padding-top: 24px !important;

    .ant-pro-table {
      padding-left: 24px !important;
    }
  }
}

@root-entry-name: default;