.aspirin-compare-price-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100% !important;

  .aspirin-table {
    height: auto;
  }

  .vs-base-table-container {
    flex: 1;
    width: 100%;
  }

  // .aspirin-compare-price-top-box {
  //   height: 50%;
  // }

  // .aspirin-compare-price-bottom-box {
  //   height: 50%;
  // }
}

@root-entry-name: default;