.aspirin-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2;
}

.aspirin-container-vertical {
  flex-direction: column;

  > div {
    width: 100%;
  }
}

.aspirin-container-horizontal {
  flex-direction: row;

  > div {
    height: 100%;
  }
}

@root-entry-name: default;