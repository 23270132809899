@import '~@/style/vars.less';
.main-zhuyuanshenqingdan {
  padding: 0 !important;
}
.admission_application_form {
  height: 100%;
  display: flex;
  flex-direction: column;
  &_main {
    padding: 24px;
    flex: 1;
    height: 0;
    display: flex;
    &_left {
      width: 390px;
      //   padding: 0 24px;
      padding-right: 24px;
      border-right: 1px solid #3276e8;
    }
    &_right {
      padding-left: 24px;
      flex: 1;
      //   padding: 0 24px;
    }
  }
  &_bottom {
    padding: 0 24px;
    height: 48px;
    border-top: 1px solid #3276e8;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &_btn_item {
      margin-left: 16px;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: #3276e8;
      color: #fff;
      text-align: center;
      height: 28px;
      line-height: 28px;
      overflow: hidden;
      cursor: pointer;
      &_clear {
        color: #3276e8;
        border: 1px solid #3276e8;
        background-color: transparent;
      }
    }
  }
}

@root-entry-name: default;