.drugProductExtensionManage .ant-form-item-label {
  text-align: left;
}

.drug_dictionary_wrapper {
  .ant-pro-table {
    width: 0;

    .ant-table-cell {
      .anticon {
        font-size: 24px;
      }
    }
  }


  .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
}

.product_default_usage_root_wrapper {
  .ant-modal-body {
    .ant-pro-table-list-toolbar {
      // width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      transform: translateY(-60px);
    }
  }
}


.ProductDepartmentUsageEditorTable,
.ProductDefaultUsageEditorTable,
.durg_alias_manage_table {
  .ant-table-cell {
    .anticon {
      font-size: 24px;
    }
  }
}

.ToolBarContainer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid rgba(133,137,141,0.3);
  box-sizing: border-box;
  padding-bottom: 8px;
}

.custom_tabs_item {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #3276E8;
  margin-right: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ActivityTabsLine {
  width: 40px;
  height: 2px;
  background: #3276E8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 8px;
}
@root-entry-name: default;