.pre-paymen-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    // height: 160px;
    background-color: #fff;
    margin-bottom: 16px;
    // padding: 24px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;

    .pre-paymen-top-left {
      flex: 1.5;

      .patientIdIs {
        padding-left: 10px;

        .patientIdIs-select {
          width: 150px;
        }
      }

      .suffix-readCard-image {
        width: 20px;
        height: 20px;
      }
    }

    .pre-paymen-top-center {
      flex: 1;
      // width: 300px;
      padding-bottom: 24px;
      margin-left: 12px;
      .ant-row {
        .ant-col {
          font-size: 18px;
        }
      }
    }

    .pre-paymen-top-right {
      flex: 1;
      padding-bottom: 24px;
    }
  }

  .bottom {
    flex: 1;
    overflow: auto;
    background-color: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.selected-row {
  td {
    background-color: rgba(255, 0, 0, 0.3) !important;
  }
  /* 设置选中行的背景颜色 */
  /* 可以根据需要添加其他样式 */
  // background: red !important;
}

@root-entry-name: default;