.patient_main {
  // border: 1px solid red;

  .ant-form-item-label {
    text-align: left;
  }

  > div {
    width: 100%;
  }

  > div:nth-of-type(1) {
    height: 56px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    // padding: 0 24px;
  }

  > div:nth-of-type(2) {
    flex: 1;
    display: flex;
    height: 0;
  }

  .patient_main_search {
    width: 530px;
    height: 36px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px 4px 4px 4px;
    margin-left: 40px;
    box-sizing: border-box;
    padding-left: 12px;

    display: flex;
    align-items: center;
    overflow: hidden;

    > div:nth-of-type(1) {
      width: 125px;

      .select {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #343b42;
        background-color: transparent !important;

        .ant-select-open {
          border: none;
        }

        .ant-select-selector {
          background-color: transparent !important;
          border: none;
        }
      }
    }

    > div:nth-of-type(2) {
      width: 0;
      height: 26px;
      border: 1px solid #3276e8;
      margin: 0 5px 0 16px;
    }

    > div:nth-of-type(3) {
      flex: 1;
      position: relative;

      .input {
        border: none;
        outline: none;
        background-color: transparent;
      }

      .search {
        position: absolute;
        right: 5px;
        top: 0;
        color: #85898d;
        padding: 4px 8px;
        cursor: pointer;
        z-index: 2;

        span {
          font-size: 20px;
        }
      }
    }

    > div:nth-of-type(4) {
      width: 112px;
      height: 36px;
      display: flex;
      justify-content: center;
      background: #fff;
      border-radius: 0px 4px 4px 0px;
      overflow: hidden;
      border: 1px solid #3276e8;
    }
  }

  .patient_main_check {
    margin-left: 40px;
    height: 37px;

    display: flex;
    align-items: center;

    .ant-checkbox-wrapper {
      margin-right: 16px;
    }
  }

  .patient_main_menu {
    width: 200px;
    border-right: 1px solid #d6d8d9;

    .info {
      width: 100%;
      height: 216px;
      box-sizing: border-box;
      padding: 24px 24px 0 24px;

      > div:nth-of-type(1) {
        width: 152px;
        height: 152px;
        background: #cddfff;
      }

      > div:nth-of-type(2) {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          font-size: 14px;
          font-weight: 400;
          color: #5d6268;
          flex: none;
        }

        > p {
          display: inline-block;
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          color: #343b42;
          margin: 0 !important;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .people {
          color: #3276e8;

          span {
            font-size: 14px;
          }
        }
      }
    }

    .menu {
      width: 100%;

      .item {
        height: 48px;
        font-size: 16px;
        font-weight: bold;
        padding: 0 24px;
        transition: all 0.2s linear;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        .action {
          span {
            font-size: 26px;
          }
        }
      }

      .item-normal {
        background: #fff;
        color: #5d6268;
      }

      .item-active {
        background: #ebf2ff;
        color: #3276e8;
      }
    }
  }

  .scan,
  .list {
    width: 56px;
    min-width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    span {
      font-size: 24px;
    }
  }

  .scan {
    color: #3276e8;
  }

  .list {
    background-color: #3276e8;
    color: #fff;
  }
}

.patient_full_info_wapper {
  flex: 1;
  width: 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    height: 0;
    overflow-y: scroll;
    overflow-x: hidden;

    .ant-pro-table {
      width: 0;
    }

    .ant-input-affix-wrapper {
      width: 240px;
    }

    #vSFormPatientFullInfo {
      .ant-form-item {
        .ant-picker,
        .ant-select {
          width: 240px;
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    border-top: 1px solid #d6d8d9;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .normal {
      cursor: pointer;
      height: 32px;
      border-radius: 0px 0px 0px 0px;
      padding: 0 24px;
      font-size: 16px;
      color: #3276e8;
      margin-right: 16px;
      border: 1px solid #3276e8;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .primary {
      background: #3276e8;
      color: #fff;
    }
  }
}

.patient_main_form_header {
  height: 58px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #d6d8d9;
  margin-bottom: 16px;

  .icon {
    width: 24px;
    height: 24px;
    background: #f0f0f0;
    border-radius: 2px 2px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tags {
    display: flex;
    align-items: center;
    margin-left: 30px;

    > div {
      height: 24px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #3276e8;
      padding: 0 12px;
      margin-right: 16px;

      font-size: 14px;
      font-weight: 400;
      color: #3276e8;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }
  }

  .age_check_message {
    font-size: 14px;
    color: #ff4d4f;
    margin-left: 16px;
  }
}

@root-entry-name: default;