.check-check-rollback {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  #ExportRecord {
    .active {
      border: 1px solid #296fff;
      color: #296fff;
      background-color: #e9f0ff;
    }
    .patientIdIs {
      padding-left: 10px;

      .patientIdIs-select {
        width: 200px;
      }

      .suffix-cardImg-image {
        width: 20px;
        height: 20px;
      }
    }
  }
  .vspromiseTable {
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    .footerInput {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 70%;
      // justify-content: flex-start;
      p {
        flex-shrink: 1;
        margin: 0;
      }
      .width560 {
        font-size: 18px;
      }
      .tip_icon {
        color: red;
      }
      .tip_title {
        font-weight: bold;
        flex-shrink: 1;
      }
      .tip_text {
        margin: 0 10px;
        flex-shrink: 1;
      }
    }
    .footerBtn {
      .btn {
        margin: 0 5px;
      }
    }
  }
  .width70 {
    text-align: center;
  }
}

@root-entry-name: default;