.info_height {
  height: 15px;
}
.doctor_info_content {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  flex: 1;
  height: 0;
}
.doctorInfo_bottom {
  margin-top: 15px;
  // border-top: 1px solid #d6d8d9;
  // background-color: #fff;
  height: 60px;
  padding-right: 24px;
  // background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 16px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  Button {
    margin: auto 0 auto 15px;
  }
}
.doctorInfo_detail {
  width: 612px;
  padding: 0px 16px 16px 0;
  box-sizing: border-box;
  // background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .basic_information {
    // display: flex;
    // justify-content: space-between;
    // margin-bottom: 16px;
    .img {
      width: 152px;
      height: 152px;
      margin-right: 36px;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    .info {
      // visibility: visible;
      flex: 1;
      height: 100%;
    }
  }
}
.ant-upload.ant-upload-avator {
  width: 152px;
  height: 152px;
}
.ant-upload-list-item {
  width: 152px;
  height: 152px;
}
.ant-upload-avator-upload-btn {
  width: 152px;
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
// .reactEasyCrop_CropArea {
//   width: 50px !important;
//   height: 50px !important;
// }

@root-entry-name: default;