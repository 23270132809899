.checked_detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  // overflow-y: scroll;
  &_title {
    font-size: 20px;
    font-weight: 600;
    color: #343b42;
  }
  &_doctor_name {
    font-size: 16px;
    font-weight: 400;
    color: #5d6268;
    margin: 24px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 33%;
      display: flex;
      margin-bottom: 16px;
      color: #5d6268;
      .label {
        color: #85898d;
      }
    }
  }
  &_content {
    display: flex;
    margin-bottom: 24px;
    box-sizing: border-box;
    &_label {
      flex-shrink: 0;
      width: 140px;
      font-size: 16px;
      font-weight: 600;
      color: #343b42;
    }
    &_content {
      font-size: 14px;
      font-weight: 400;
      color: #5d6268;
    }
  }
  &_view_img {
    width: 80px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #3276e8;
    font-size: 14px;
    font-weight: 400;
    color: #3276e8;
    text-align: center;
    line-height: 18px;
    margin-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
  &_double_up_outlined {
    transform: rotate(-90deg);
  }
  &_double_down_outlined {
    transform: rotate(90deg);
  }
}

@root-entry-name: default;