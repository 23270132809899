@import '~@/style/vars.less';

.@{prefix}-index-block {
  border-radius: @border-radius;
  background-color: #fff;
  padding: @padding-5 @padding;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0;

    .left-block {
      font-size: @font-size-20;
      font-weight: @font-weight-bold;
      color: @title-color;
      position: relative;
      text-indent: 17px;

      &::before {
        content: '';
        width: 5px;
        height: 60%;
        background-color: @primary-color;
        position: absolute;
        left: 0;
        top: 20%;
      }
    }
  }

  .content {
    flex: 1;
    width: 100%;
  }
}

@root-entry-name: default;