@import '~@/style/vars.less';

.ant-dropdown,
.ant-dropdown-placement-bottomLeft {
  z-index: 1000 !important;
}

.@{prefix}-cpoe-index-center-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  // height: 100%;
  height: 6.125rem;

  .oper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    // padding-right: 60px;
    // border-right: 1px solid #aeb0b3;
    .first {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-btn {
        height: 36px;
        border: 1px solid #3276e8;
        color: #3276e8;
        font-size: 18px;
        border-radius: 4px;

        + .ant-btn {
          margin-left: 24px;
        }
      }
    }

    .second {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #3276e8;
      margin-top: 10px;
      border-radius: 4px;
      height: 36px;
      background-color: #fff;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #343b42;
        padding-left: 2px;
        padding-right: 2px;
        flex: 1;
        border-right: 1px solid #3276e8;
        max-width: 130px;
        cursor: pointer;

        .vp-icon {
          margin-left: 4px;
        }

        .label {
          font-size: 16px;
          margin-left: 4px;

          &::after {
            content: ':';
            margin: 0 6px;
            position: relative;
            top: -1px;
          }
        }

        .value {
          font-size: 22px;
        }
      }

      .input {
        flex: 1;

        .ant-input {
          border-width: 0;
          background-color: transparent;

          &:hover {
            border-color: transparent;
          }

          &:focus {
            box-shadow: unset;
          }
        }
      }

      .scan,
      .list {
        width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-left: 1px solid #3276e8;
        flex: 0;
        width: 72px;
        min-width: 72px;
        cursor: pointer;
      }

      .scan {
        color: #3276e8;
      }

      .list {
        background-color: #3276e8;
        color: #fff;
      }
    }
  }

  .oper-in {
    width: 216px;
    height: 82px;
    padding-right: 24px !important;
    border-right: 1px solid #aeb0b3;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .first {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        height: 36px;

        &:first-of-type {
          width: 116px;
        }

        &:last-of-type {
          width: 88px;
        }
      }
    }

    .second-in {
      display: flex;

      .ant-btn {
        height: 36px;
        width: 216px;
      }
    }
  }

  .aspirin-header-user-info {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    // height: 94px;
    // max-height: 94px;
    // min-height: 94px;
    height: 82px;
    max-height: 82px;
    min-height: 82px;
    width: 100%;
    margin-left: 24px;
    justify-content: space-between;
    overflow-x: scroll;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.5;
      z-index: 1;
    }

    .user-info-container {
      display: flex;
      z-index: 2;
      align-items: center;
      // overflow-x: scroll;
    }

    .header-right-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 16px;
      z-index: 2;
      flex: none;

      .item,
      .in_item {
        flex: none;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        + .item {
          margin-left: 28px;
        }

        .label {
          font-size: 14px;
          color: #5d6268;
        }
      }

      .in_item {
        + .in_item {
          margin-left: 12px;
        }
      }
    }

    // 住院
  }

  // 门诊头像
  .number {
    display: flex;
    margin: 0 16px 0 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    min-width: 64px;
    background-color: #fff;
    border-radius: 50%;
    color: #e4e4e4;

    // > .label {
    //   white-space: nowrap;
    //   color: #343b42;
    //   font-size: 20px;
    //   font-weight: 500;
    //   margin-bottom: -4px;
    // }

    // > .value {
    //   font-size: 20px;
    //   color: #3276e8;
    // }
  }

  // 门诊
  .main {
    width: 300px;
    color: @title-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;

    .first,
    .second {
      width: 100%;
      white-space: nowrap;
      align-items: center;
      display: flex;
    }

    .name {
      font-size: 26px;
      font-weight: @font-weight-bold;
      cursor: pointer;
    }

    .gender {
      width: 25px;
      // margin: 0 @margin-2;
      cursor: pointer;
    }

    .age {
      width: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 18px;
      cursor: pointer;
    }

    .label {
      font-weight: @font-weight-normal;
      font-size: @font-size;

      &::after {
        content: ':';
      }
    }

    .displayId {
      max-width: 140px;
      margin-left: 6px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      background-color: rgba(50, 118, 232, 0.1);
      font-size: 16px;
      padding: 0 10px;
    }

    .phoneNumber {
      font-size: 16px;
      color: #85898d;
      cursor: pointer;
      max-width: 50%;
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .currentAddress {
      font-size: 16px;
      color: #85898d;
      flex: 1;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: @font-size-20;
      margin-left: @margin-3;
      cursor: pointer;
    }
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
    margin-right: 30px;
  }

  // 门诊
  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    height: 100%;
    padding: 10px 0;

    .info-item {
      width: 155px;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      // margin-right: 60px;

      .info-bar {
        width: 4px;
        height: 100%;
        position: absolute;
      }

      .info-content {
        flex-shrink: 0;
        padding-left: @padding-4;
        width: 100%;
        display: flex;
        flex-direction: column;

        // justify-content: center;
        .item-row {
          height: 50%;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .label {
          display: inline-block;
          padding: 0 12px 0 0;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          > span {
            flex: 1;

            &:last-of-type {
              text-align: center;
            }
          }

          &::after {
            content: ':';
          }
        }

        .value {
          font-size: @font-size-20;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 18px;
        }
      }

      + .info-item {
        // margin-left: 60px;
        margin-left: 15px;
      }
    }
  }

  // 住院头像
  .hospital-number {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      min-width: 48px;
      background-color: #cddfff;
      border-radius: 50%;
      color: #e4e4e4;
    }

    &_tag {
      padding: 0 4px;
      position: absolute;
      text-align: center;
      bottom: 15px;
      height: 14px;
      font-size: 10px;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(90deg, #155cd2 0%, #5291fa 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  // 住院
  .hospital-main {
    margin-right: 16px;
    flex-shrink: 0;
    width: 184px;

    .second {
      margin-top: -8px;

      .value {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .first {
      .name {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  // 住院
  .hospital-info {
    .info-item {
      // justify-content: flex-start;
      margin-right: 24px;
      width: 160px;
      height: 60px;
      display: flex;

      + .info-item {
        margin-left: 0;
      }

      .info-content {
        height: 55px;
        padding-left: 0;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .label {
          display: flex;
          justify-content: flex-start;
          min-width: 70px;
          max-width: 100px;
          font-weight: 500;
          font-size: 18px;
          // border: 1px solid red;
          > span {
            flex: 1;

            &:last-of-type {
              text-align: center;
              text-align: left;
            }

            &::after {
              content: '：';
            }
          }

          &::after {
            content: '';
          }

          // flex-shrink: 0;
          // padding: 0;
        }

        .value {
          flex-shrink: 0;
          flex: 1;
          font-size: 18px;
          font-weight: 500;
          color: #343b42;
        }

        .icons {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

.cpoe-patient-list {
  background-color: #fff;
  // width: 500px;
  height: 680px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .ant-tabs-nav-wrap {
    padding-left: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    .ant-table-cell-row-hover {
      background-color: transparent !important;
    }

    > td {
      background-color: transparent !important;
    }

    .header {
      padding: 0 24px;
      margin-top: 16px;
    }

    .main {
      margin-top: 16px;
      height: 0;
      flex: 1;
      padding: 0 24px;
      overflow-y: scroll;
    }

    .footer {
      height: 47px;
      min-height: 47px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #d9d9d9;
      width: 100%;
      padding-right: 24px;

      .ant-btn {
        + .ant-btn {
          margin-left: 16px;
        }
      }
    }
  }
}

.ant-table-row.aspirin-collect-table-bgc,
.ant-table-row-level-0.aspirin-collect-table-bgc {
  background-color: #e4eeff !important;
}

.in_hospitalType_dropdown {
  background-color: #f0f3f5;
  border-radius: 8px;
  // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  //   0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

// 设置弹窗
.patient_seting {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_title {
    height: 50px;
    font-size: 18px;
    color: #343b42;
    font-weight: bolder;
    line-height: 50px;
  }

  &_mark {
    font-size: 14px;
    color: #85898d;
    font-weight: normal;
  }

  &_body {
    padding: 16px 0 16px 16px;
    height: 116px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #d6d8d9;
    display: flex;
    flex-wrap: wrap;

    &_item {
      width: 215px;
      // border: 1px solid red;
      margin-bottom: 16px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .content {
        padding: 0 12px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        height: 32px;
        font-size: 16px;
        color: #343b42;
      }
    }

    &_dragging {
      box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.1);
      border-left: 2px solid #3276e8;
    }

    &_item1 {
      width: 120px;
      height: 32px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      // padding: 0 12px;
      // .content {
      //   width: 100%;
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }

  &_select {
    // flex: 1;
    height: 186px;
    flex-shrink: 0;

    .title {
      height: 60px;
      line-height: 60px;
    }

    // border: 1px solid red;
  }

  &_all {
    // border: 1px solid red;
    // flex: 1;
    height: 244px;
    flex-shrink: 0;

    .title {
      height: 50px;
    }
  }
}

@root-entry-name: default;