.emergency_treatment {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &_search {
    padding: 0 24px;
    margin-bottom: 16px;
    width: 100%;
    height: 68px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 4px;
    .ant-col,
    .ant-form-item-control {
      height: 32px;
    }
  }
  &_body {
    flex: 1;
    width: 100%;
    border-radius: 4px;
    display: flex;
    height: 100%;
    overflow: hidden;
    &_list {
      margin-right: 16px;
      width: 448px;
      height: 100%;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;

      .my_dot {
        margin-left: 15px;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;

        animation: pulse 1s infinite alternate;
      }
      &_content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .ant-tabs-content-holder {
          height: 100%;
          .ant-tabs-content,
          .ant-tabs-content-top {
            height: 100% !important;

            .ant-tabs-tabpane,
            .ant-tabs-tabpane-active {
              height: 100% !important;
            }
          }
        }
        .custom_table {
          height: 100%;
          padding: 0 24px 16px;
          display: flex;
          flex-direction: column;
          min-height: 100px;
          &_height {
            height: 40px;
          }
          &_flex {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &_header {
            text-align: center;
            font-weight: bolder;
            background-color: #e5f2ff;
            border-bottom: 1px solid #d6d8d9;
          }
          &_body {
            width: 100%;
            height: 0;
            flex: 1;
            overflow-y: scroll;
            overflow-x: hidden;
            &_item {
              width: 100%;
              border: 1px solid #d6d8d9;
              border-top: none;
              .title_row {
                width: 100%;
                display: flex;
                align-items: center;
                height: 40px;
                background-color: #fff;
                border-bottom: 1px solid #d6d8d9;
                &_item {
                  padding: 0 24px;
                  flex: 1;
                }
              }
              .body_row {
                display: flex;
                align-items: center;
                text-align: center;
                background-color: transparent;
                cursor: pointer;
                &_item {
                  padding: 0 15px;
                }
              }
              .body_row:last-child > div {
                border-bottom: none !important;
              }
              // 鼠标悬浮
              .body_row:hover {
                background-color: #ebf5ff;
              }
              // 鼠标悬浮且选中
              .body_row:hover.body_row_select {
                background-color: #b2d7ff;
              }
              // 选中背景色
              .body_row_select {
                background-color: #cbe4ff;
                // .body_row:hover {
                //   background-color: #b2d7ff;
                // }
              }
            }
          }
        }
        &_top {
          width: 100%;
          flex: 1;
          height: 0;
        }
        &_bottom {
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 60px;
        }
      }
    }
    &_detail {
      padding: 24px 24px 0;
      flex: 1;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .patientAssessmentTable {
        // border: 1px solid red;
        overflow: hidden;
      }
      .detail_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        &_body {
          // margin-bottom: 24px;
          height: 0;
          flex: 1;
          overflow: hidden;
          overflow-y: scroll;
          // .patient_score {
          //   background-color: #dce1e5;
          //   margin-top: 20px;
          //   padding: 4px 6px;
          //   .ant-col,
          //   .ant-form-item-control {
          //     height: 32px;
          //   }
          // }
        }
        &_bottom {
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &_button {
            margin-left: 16px;
            min-width: 80px;
          }
        }
      }
    }
  }
}
.disabled_select_color {
  .ant-select-selector {
    // color: red !important; /* 你可以设置为你想要的颜色 */
  }
}
.patient_score {
  background-color: #dce1e5;
  margin-top: 20px;
  padding: 4px 6px;
  .ant-col,
  .ant-form-item-control {
    height: 32px;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: translate(0, 0) scale(1);
    box-shadow: 0 0 1px #ff0000; /* 小红点阴影 */
  }

  50% {
    transform: translate(0, 0) scale(1.5);
    box-shadow: 0 0 3px #ff0000; /* 小红点阴影 */
  }
  100% {
    transform: translate(0, 0) scale(1);
    box-shadow: 0 0 8px #ff0000; /* 小红点阴影 */
  }
}

@root-entry-name: default;