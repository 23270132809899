@import '~@/style/vars.less';

.aspirin-application-privilege {
  height: 100%;
  // background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .aspirin-list-title {
    margin-bottom: 16px;
  }

  .left {
    width: 300px;
    // background-color: @white;
    height: 100%;
    padding: 16px 0;
    border-right: 1px solid rgb(214, 214, 214);
    flex: 0;
    flex-basis: 300px;
    // padding-left: 24px;
  }

  .right {
    flex: 1;
    height: 100%;
    // background: #fff;

    .aspirin-footer-button {
      position: relative;
    }

    .right-main {
      padding: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .aspirin-footer-box {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }

      .ant-table-thead {
        background-color: #e4eeff;

        th {
          font-weight: @font-weight-bold;
        }

        .ant-table-cell {
          background-color: #e4eeff;
        }
      }

      td:first-child {
        font-size: @font-size;
      }
    }
  }
}

@root-entry-name: default;