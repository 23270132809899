.aspirin-drag-line-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // overflow: auto;

  >* {
    flex: 1;

  }

  .aspirin-compare-price-top-box {
    height: 50%;
  }

  .aspirin-compare-price-bottom-box {
    height: 50%;
    // background: var(--tint-background);
  }


  .aspirin-drag-line {
    background-color: transparent;

    flex: 0;
  }

  .aspirin-drag-line-vertical {
    width: 100% !important;
    height: 5px;
    max-height: 5px;
    min-height: 5px;
    position: relative;
    cursor: row-resize;

    .show-line-background {
      padding: 10px;
      box-sizing: content-box;
      display: flex;
      align-items: center;

    }

    .show-line {
      position: absolute;
      width: 100%;
      background-color: var(--blue);
      height: 1px;
      left: 0;
      top: 2px;

    }
  }

  .aspirin-drag-line-horizontal {
    height: 100%;
    width: 5px;
    max-width: 5px;
    min-width: 5px;
    position: relative;
    cursor: col-resize;

    .show-line {
      position: absolute;
      height: 100%;
      background-color: green;
      width: 1px;
      left: 2px;
      top: 0;
    }
  }
}
@root-entry-name: default;