.clinic-register-schedule-main {
  .ant-pro-table-search {
    margin-bottom: 0 !important;
  }
  overflow: hidden;
  .ant-table-tbody > tr > td {
    background-color: #fff !important;
  }
  .ant-table-tbody > tr > td:first-child {
    background-color: #f5f7fa !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      text-align: center; // 将单元格内容水平居中
    }
  }
}
.ant-table-row-hover {
  background-color: pink !important; /* 将悬停时的背景颜色设置为透明 */
}
.cart-content-active {
  border: 4px var(--blue) solid !important;
}
.cart-main {
  width: 240px;
  height: 170px;
  margin: auto;
  background-color: #fff;
  .cart-containter {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 16px 14px;

    .cart-icon {
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .cart-bg {
        width: 24px;
        height: 24px;
        display: flex;
        border-left: 14px solid transparent;
        border-right: 14px solid #50bc35;
        border-top: 14px solid #50bc35;
        border-bottom: 14px solid transparent;
        position: relative;
        .cart-bg-title {
          position: absolute;
          top: -14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .cart-number {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      .cart-number-type {
        display: flex;
        width: 46px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #3276e8;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        color: #3276e8;
        margin-top: -16px;
      }

      .cart-doctor {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 12px;
        margin-top: -13px;
        font-weight: 500;
        color: #3276e8;
      }
    }

    .cart-outpatient {
      font-size: 18px;
      font-weight: 500;
      color: #343b42;
      margin-right: 10px;
      padding-bottom: 16px;
      margin-bottom: 18px;
      border-bottom: 1px dotted #e7e7e7;
      margin-top: 12px;
    }

    .cart-departments {
      display: flex;
      margin-bottom: 10px;
      .cart-departments-title {
        font-size: 14px;
        font-weight: 400;
        color: #5d6268;
      }

      .cart-departments-content {
        font-size: 14px;
        font-weight: 400;
        color: #343b42;
      }
    }
  }
}

.cart-content {
  width: 186px;
  height: 170px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 4px solid #3276e8;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  padding: 0 4px;
  margin: auto;
  cursor: pointer;
  box-sizing: border-box;
  border-left: transparent 4px solid;
  border-right: transparent 4px solid;
  border-bottom: transparent 4px solid;
  .cart-am {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    .img-icon {
      padding-bottom: 16px;
    }
    &:first-child {
      border-bottom: 2px dotted #3276e8;
      // padding-top: 16px;
      // padding-bottom: 16px;
      // background-color: pink;
    }

    .total-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      img {
        width: 16px;
        height: 16px;
      }
      .cart-date {
        font-size: 16px;
        font-weight: 500;
        color: #343b42;
      }
      .cart-time {
        font-weight: 400;
        color: #5d6268;
        font-size: 12px;
        margin-left: 5px;
        // flex: 1;
      }
      .snow-sun {
        .cart-icon1 {
          width: 16px;
          // margin-left: 42px;
          height: 16px;
          margin-top: -5px;
        }
      }
    }
    .total-bottom {
      height: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total-bottom-total {
        display: flex;
        align-items: center;
        .total-bottom-total-title {
          font-size: 16px;
          font-weight: 400;
          color: #85898d;
          margin-right: 10px;
        }
        .total-bottom-total-num {
          font-weight: 500;
          color: #3276e8;
          font-size: 16px;
        }
      }
      .total-bottom-appointment {
        display: flex;
      }
    }
  }
}
//

//-------------------------
.table-title-switch {
  display: flex;
  .ant-switch-checked .ant-switch-handle::before {
    background-image: url('../../../../../../assets/icon/sun.png'); /* 替换为你的背景图片 URL */
    background-size: cover;
    background-position: center;
    border-color: transparent; /* 移除滑动按钮的边框 */
  }
  .ant-switch-handle::before {
    background-image: url('../../../../../../assets/icon/snowflake.png'); /* 替换为你的背景图片 URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.deleteFlex {
  display: flex;
}
.ant-picker-range {
  width: 100%;
  position: relative;
  display: inline-flex;
}

@root-entry-name: default;